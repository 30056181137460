import DemoPrimaryButton from './components/DemoPrimaryButton';
import DemoSecondaryButton from './components/DemoSecondaryButton';

export default function DemoFooter(props: {
  primaryButton: {
    title: string;
    onClick: () => void;
    disabled?: boolean;
    loading?: boolean;
  };
  secondaryButton?: {
    title: string;
    onClick: () => void;
    disabled?: boolean;
    loading?: boolean;
  };
  onBack?: () => void;
}) {
  const { primaryButton, secondaryButton, onBack } = props;
  return (
    <div className="sticky bottom-0 z-50 flex items-center justify-between w-full py-6 border border-b-0 border-l-0 border-r-0 border-solid px-14 border-slate-200 bg-slate-50">
      {!onBack ? null : (
        <div
          className="text-lg font-semibold cursor-pointer hover:text-black/70"
          onClick={onBack}
        >
          Go back
        </div>
      )}
      <div className="flex last:ml-auto gap-3">
        {secondaryButton ? (
          <DemoSecondaryButton
            title={secondaryButton.title}
            onClick={secondaryButton.onClick}
            disabled={secondaryButton.disabled}
            loading={secondaryButton.loading}
          />
        ) : null}
        <DemoPrimaryButton
          title={primaryButton.title}
          onClick={primaryButton.onClick}
          disabled={primaryButton.disabled}
          loading={primaryButton.loading}
        />
      </div>
    </div>
  );
}
