import { Button, Separator } from '@/cove-ui';
import {
  useGQLGetModelVersionsWithDetailsQuery,
  useGQLPoliciesQuery,
  type GQLGetModelVersionsWithDetailsQuery,
} from '@/graphql/generated';
import { TrashCanFilled } from '@/icons';
import { decimalToPercentage } from '@/utils/number';
import { parseDatetimeToReadableStringInCurrentTimeZone } from '@/utils/time';
import { treeFromList } from '@/utils/tree';
import { gql } from '@apollo/client';
import { ExternalLink } from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';

import CoveBadge from '../../components/CoveBadge';
import CopyTextComponent from '@/components/common/CopyTextComponent';
import FullScreenLoading from '@/components/common/FullScreenLoading';

import { tripadvisorDemoModelConfigs } from '../customer_utils/tripadvisor';
import ModelModalityIcons from './ModelModalityIcons';

gql`
  query GetModelVersionsWithDetails($modelId: ID!) {
    getModelVerionsWithDetails(modelId: $modelId) {
      id
      version
      family
      status
      policy {
        id
        name
        parentId
      }
      createdAt
      itemRequirement
      alias
    }
  }
`;

function ModelCard(props: {
  model: GQLGetModelVersionsWithDetailsQuery['getModelVerionsWithDetails'][0];
}) {
  const { model } = props;
  const { policy } = model;
  const navigate = useNavigate();

  return (
    <div className="flex flex-col bg-white border rounded-lg border-border">
      <div className="flex flex-col gap-2 p-4 py-6">
        <div className="flex flex-row justify-between">
          <div className="flex items-center gap-3">
            <div className="font-semibold">{`${policy.name} Model`}</div>
            {model.alias && (
              <CoveBadge
                colorVariant={
                  model.alias === 'LIVE' ? 'soft-green' : 'soft-yellow'
                }
                shapeVariant="rounded"
                label={model.alias}
              />
            )}
          </div>
          <div className="flex flex-row items-center gap-8">
            <ModelModalityIcons itemRequirement={model.itemRequirement} />
            <Button
              variant="outline"
              onClick={() =>
                navigate(
                  `/dashboard/models_and_policies/models/performance/${policy.id}/${model.id}/${model.version}`,
                )
              }
            >
              Model Performance
            </Button>
          </div>
        </div>
        <div className="text-sm">{`Trained on: ${parseDatetimeToReadableStringInCurrentTimeZone(
          model.createdAt,
        )}`}</div>
        {tripadvisorDemoModelConfigs[policy.id] != null ? (
          <div className="text-sm">{`Precision: ${decimalToPercentage(
            tripadvisorDemoModelConfigs[policy.id].precision,
          )} • Recall: ${decimalToPercentage(
            tripadvisorDemoModelConfigs[policy.id].recall,
          )}`}</div>
        ) : undefined}
      </div>
      <Separator />
      <div className="flex flex-row justify-between py-3 pr-4">
        <div>
          <Button variant="link" disabled>
            Manage Rules
          </Button>
          {model.alias === 'BETA' && (
            <Button
              variant="link"
              onClick={() =>
                navigate(
                  `/dashboard/models_and_policies/models/comparison/${policy.id}/${model.id}/${model.version}`,
                )
              }
            >
              Compare to Live Model
            </Button>
          )}
        </div>
        <Button
          className="text-red-500 hover:text-red-500/70"
          variant="link"
          startIcon={TrashCanFilled}
          disabled
        >
          Delete Model
        </Button>
      </div>
    </div>
  );
}

export default function ModelDetailsPage() {
  const { modelId } = useParams();

  const navigate = useNavigate();

  if (!modelId) {
    throw new Error('No model ID provided');
  }

  const { data, loading, error } = useGQLGetModelVersionsWithDetailsQuery({
    variables: { modelId },
  });

  const { data: policiesData, loading: policiesLoading } =
    useGQLPoliciesQuery();

  if (loading || policiesLoading) {
    return <FullScreenLoading />;
  }

  if (error) {
    return <div className="flex flex-col">Error: {error.message}</div>;
  }

  const models = data?.getModelVerionsWithDetails;
  if (models == null || models.length === 0) {
    throw new Error('No model found');
  }
  const policy = models[0].policy;

  const policies = policiesData?.myOrg?.policies;
  if (policies == null) {
    throw new Error('No policies found');
  }
  const policyTree = treeFromList<{ id: string; name: string }>(
    policies,
    {
      id: '-1',
      name: 'root',
    },
    (policy) => ({
      id: policy.id,
      name: policy.name,
    }),
  );

  const isSubPolicy = policy.parentId != null;
  const parentNode = isSubPolicy ? policyTree.find(policy.parentId) : undefined;
  const pathToParentNode = parentNode
    ? policyTree
        .getPathToNode(parentNode)
        // filter out root
        .filter((it) => it.value.id !== '-1')
    : undefined;

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row gap-2">
        <div>Custom AI Models</div>
        <div>/</div>
        <span className="font-semibold">{policy.name}</span>
      </div>
      <div className="flex flex-row justify-between">
        <div className="text-2xl font-bold">{policy.name}</div>
        <div className="flex flex-row items-center gap-2">
          <CopyTextComponent
            displayValue={`Policy ID: ${policy.id}`}
            value={policy.id}
          />
          <Button
            className="!fill-none"
            variant="link"
            endIcon={ExternalLink}
            onClick={() =>
              navigate(
                `/dashboard/models_and_policies/policies/form/${policy.id}`,
              )
            }
          >
            Manage Policy
          </Button>
        </div>
      </div>
      {pathToParentNode && (
        <div>{`Sub Policy for ${pathToParentNode.join(' > ')}`}</div>
      )}
      <div className="flex flex-col w-full gap-8">
        {[...models]
          .sort((model) => (model.alias === 'BETA' ? 1 : -1))
          .map((model, i) => (
            <ModelCard key={i} model={model} />
          ))}
      </div>
    </div>
  );
}
