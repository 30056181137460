import { Table } from '@/icons';
import _ from 'lodash';

import DemoFooter from '../DemoFooter';

const samples = [
  {
    text: "Do you ever feel like you just can't seem to catch a break? Are you always drowning in work and responsibilities",
    score: 0.3,
  },
  { text: 'Catch you on the flip side', score: 0.3 },
  {
    text: 'Reminder: Your appointment with Dr. Smith at Four Medical is scheduled for 05/04/24. Reply CONFIRM to confirm or RESCHEDULE to change.',
    score: 0.3,
  },
  {
    text: 'Get fit for summer! Sign up for a 6-month membership at Red Theory and get 20% OFF. Offer ends soon! redtheory.com',
    score: 0.33,
  },
  {
    text: "How are you doing? Hope you've settled in for the new school year. Just wishin you a gr8 day",
    score: 0.4,
  },
  {
    text: '🎉 BIGGEST SALE OF THE YEAR! Get up to 50% OFF on all items at Bop Shop. Shop now: link.com',
    score: 0.4,
  },
  {
    text: "You're invited! Join us for an open house at 492 Broadway this Saturday from 1-4 PM. See you there! Douglas Mailman",
    score: 0.4,
  },
  {
    text: 'Ready to learn something new? Enroll in our MasterClass and get 15% OFF your tuition. Classes start soon! masterclass.com',
    score: 0.4,
  },
  {
    text: "So why you still chillin'? Hop on over to shopchill.com and place your order NOW! Peace out, The Cure Team",
    score: 0.5,
  },
  {
    text: 'NEW MENU ALERT! Try our latest dishes at [Restaurant Name]. Use code NEW10 for 10% off your first order! ubereats.com/pizzaeats',
    score: 0.55,
  },
  {
    text: 'FREE MEAL! Get a free entree at Olive Garden with your first online order. Use code FREEFOOD at checkout. olivegarden.com',
    score: 0.55,
  },
  {
    text: 'LAST CHANCE! Book a round-trip to Nashville for only $99! Offer ends at midnight. Hurry! expedia.com/go',
    score: 0.67,
  },
  {
    text: 'WIN a FREE CAR DETAILING! Be one of the first 50 to book a service at Gr8 Cars Service Center. Reply YES',
    score: 0.68,
  },
  {
    text: 'You are third in line, we look forward to assisting you soon.',
    score: 0.7,
  },
  {
    text: "We're stoked to see you diving into some serious fun with our kickass products and services!",
    score: 0.72,
  },
  {
    text: 'AMAZING RESULTS! Try our new Collagen and lose weight FAST! Buy one, get one free. Order now: buy.link.com',
    score: 0.74,
  },
  {
    text: "URGENT: FINAL HOURS! 70% OFF EVERYTHING at HopStop. Shop NOW before it's gone! hopstop.com",
    score: 0.75,
  },
  {
    text: 'HOT PROPERTY ALERT! Get $5,000 off closing costs when you buy with Flora Realty Limited time only.',
    score: 0.77,
  },
  {
    text: 'FREE GADGET ALERT! Claim your free charger phone case with any purchase at CaseNext. Limited to the first 100 customers. casenext.com',
    score: 0.79,
  },
  {
    text: 'HEY GIRL. HOW R U? HOPE U R WELL ME AN DEL R BAK! AGAIN LONG TIME NO C! GIVE ME A CALL SUM TIME FROM LUCYxx,,,',
    score: 0.85,
  },
  {
    text: 'Yo you guys ever figure out how much we need for alcohol? Jay and I are trying to figure out how much we can safely spend on weed',
    score: 0.88,
  },
  {
    text: "Make $$$ Easily with Our Scheme!!! Greetings! Are you tired of constantly worrying about your finances? Money troubles got you feeling down? Well, fret no more because we have just the solution for you! Introducing a simple and hassle-free scheme that guarantees fast cash! And the best part? It's completely legit. Don't believe us? No investment or risk involved.",
    score: 0.89,
  },
  {
    text: "Congratulations! You've been pre-approved for a $5,000 loan at PayDayLoans. Apply now: bit.ly/paydayloans",
    score: 0.91,
  },
  {
    text: "Join today and receive a complimentary $200 bonus - start your journey to financial freedom now! So why wait? Visit FreeMoney.com today and say goodbye to your 9-5 desk job forever. Take care, HomeCash.biz Team Get Rich Quick. Now It's Easier than Ever!",
    score: 0.94,
  },
  { text: 'Sir, I need AXIS BANK account no and bank address.', score: 0.95 },
  {
    text: "Get ready to meet some awesome singles! Hey there! Are you ready to spice up your love life? We've got the ultimate solution just for you! We've stumbled upon a fantastic website packed with stunning individuals who can't wait to meet someone as extraordinary as YOU! It's absolutely foolproof and confidential. Better yet, simply create your absolutely FREE profile on our platform right away! Just click on this exciting single2couples.com and let the magic begin! You don't want to miss out on your chance to meet mind-blowing singles in your vicinity and create something extraordinary! Don't hesitate - join us today! Cheers!",
    score: 0.95,
  },
  {
    text: "Hey there, Do you want to make quick and easy money? Well, now you can with our new money-making program! You don't need any experience or special skills, and you can start making money RIGHT AWAY. We've cracked the code on how to get rich fast, and our program is designed to help you do it. With our revolutionary system, you can start earning easy money almost INSTANTLY! What are you waiting for? Sign up NOW for a FREE 14-day trial and start making your fortune today! Just click the link and in no time at all you'll be cashing in on the moolah. https://www.earneasymoney.com Take advantage of this amazing opportunity, before it's too late! Regards, The Easy Money Team",
    score: 0.97,
  },
  {
    text: 'Hey there! Are you tired of the daily grind? Leave the monotonous 9-5 job behind and open doors to a whole new world of opportunities by working from the comfort of your own home! At HomeCash.biz , we partner with individuals just like you to help kickstart your own money-making business. Our dedicated support network will guide you every step of the way, turning your financial dreams into a reality. With a few hours of free time each day, you could be on your way to financial success.',
    score: 0.98,
  },
  {
    text: 'Greetings! Are you interested in elevating your way of living? Join our prestigious club and unlock a world of luxury! Claim your FREE membership now by replying FREELUX and start indulging in the good life! Best regards, The WellKope Membership Team',
    score: 0.99,
  },
  {
    text: 'Hey there! Are you tired of getting a small tax refund? Look no further than our amazing tax consulting service! Our team of experts will ensure that you get the BIGGEST refund possible. We guarantee fast results within just 6 business days. FreeTaxMoney.com',
    score: 0.99,
  },
] satisfies {
  score: number;
  text: string;
}[];

export default function DemoSpotTestResultsScreen(props: {
  policyName: string;
  goToNextScreen: () => void;
  goBack: () => void;
}) {
  const { policyName, goToNextScreen, goBack } = props;
  return (
    <div className="flex flex-col items-center w-full text-start">
      <div className="flex flex-col items-start m-8 gap-8">
        <div className="text-2xl font-semibold">
          Test results for {policyName} model
        </div>
        <div className="flex flex-col">
          <div className="mb-1 text-lg font-bold">Source data</div>
          <div className="flex items-center gap-4">
            <div className="flex items-center p-2 rounded gap-3 bg-slate-200 text-slate-400">
              <Table className="w-6 fill-slate-400" />{' '}
              <div>phishing_texts_5/29.csv</div>
            </div>
            <div className="font-semibold text-primary whitespace-nowrap">
              Upload new
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="mb-1 text-lg font-bold">Results</div>
          <div className="flex flex-col gap-1">
            {_.reverse(_.sortBy(samples, 'score')).map((sample, i) => (
              <div
                key={i}
                className="flex flex-col items-start justify-center max-w-4xl py-2 overflow-scroll text-sm border-0 border-solid text-start grow"
              >
                <div className="pb-1.5 text-slate-500 text-sm">
                  {sample.score}
                </div>
                <div className="flex items-start w-full p-2 rounded text-start max-h-[460px] overflow-y-scroll bg-slate-100">
                  {sample.text}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <DemoFooter
        primaryButton={{ title: 'Continue', onClick: goToNextScreen }}
        onBack={goBack}
      />
    </div>
  );
}
