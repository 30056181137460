import { Button } from '@/cove-ui';
import { gql } from '@apollo/client';
import { Input, Slider, Switch } from 'antd';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import FullScreenLoading from '../../components/common/FullScreenLoading';
import CoveModal from '../dashboard/components/CoveModal';
import { CoveModalFooterButtonProps } from '../dashboard/components/CoveModalFooter';
import DashboardHeader from '../dashboard/components/DashboardHeader';
import FormSectionHeader from '../dashboard/components/FormSectionHeader';

import {
  namedOperations,
  useGQLAccountSettingsQuery,
  useGQLDeleteUserMutation,
  useGQLPersonalSafetySettingsQuery,
  useGQLSetModeratorSafetySettingsMutation,
  useGQLUpdateAccountInfoMutation,
} from '../../graphql/generated';
import GoldenRetrieverPuppies from '../../images/GoldenRetrieverPuppies.png';
import {
  BLUR_LEVELS,
  safetySetting,
  type BlurStrength,
} from '../dashboard/mrt/manual_review_job/v2/ncmec/NCMECMediaViewer';

type ModalInfo = {
  visible: boolean;
  title: string;
  body: string;
  okText: string;
  onOk: () => void;
  okIsDangerButton: boolean;
  cancelVisible: boolean;
};

gql`
  query AccountSettings {
    me {
      id
      email
      firstName
      lastName
    }
  }

  query PersonalSafetySettings {
    me {
      interfacePreferences {
        moderatorSafetyMuteVideo
        moderatorSafetyGrayscale
        moderatorSafetyBlurLevel
      }
    }
  }

  mutation UpdateAccountInfo($firstName: String, $lastName: String) {
    updateAccountInfo(firstName: $firstName, lastName: $lastName)
  }

  mutation SetModeratorSafetySettings(
    $moderatorSafetySettings: ModeratorSafetySettingsInput!
  ) {
    setModeratorSafetySettings(
      moderatorSafetySettings: $moderatorSafetySettings
    ) {
      _
    }
  }
`;

export default function AccountSettings() {
  const navigate = useNavigate();
  const [settings, setSettings] = useState<{
    moderatorSafetyBlurLevel: BlurStrength;
    moderatorSafetyGrayscale: boolean;
    moderatorSafetyMuteVideo: boolean;
  }>({
    moderatorSafetyBlurLevel: 2,
    moderatorSafetyGrayscale: true,
    moderatorSafetyMuteVideo: true,
  });

  const {
    loading: safetySettingsLoading,
    error: safetySettingsError,
    data: safetySettingsData,
  } = useGQLPersonalSafetySettingsQuery();

  const [saveSafetySettings, { loading: safetySettingsMutationLoading }] =
    useGQLSetModeratorSafetySettingsMutation({
      onCompleted: () =>
        setModalInfo({
          visible: true,
          title: 'Changes Saved',
          body: 'Your account information was successfully updated!',
          okText: 'Done',
          onOk: hideModal,
          okIsDangerButton: false,
          cancelVisible: false,
        }),
      onError: () =>
        setModalInfo({
          visible: true,
          title: 'Error Saving Changes',
          body: 'We encountered an error trying to update your account information. Please try again.',
          okText: 'OK',
          onOk: hideModal,
          okIsDangerButton: false,
          cancelVisible: false,
        }),
    });

  useEffect(() => {
    if (!safetySettingsData?.me?.interfacePreferences) {
      return;
    }
    const {
      moderatorSafetyMuteVideo,
      moderatorSafetyGrayscale,
      moderatorSafetyBlurLevel,
    } = safetySettingsData.me.interfacePreferences;
    setSettings({
      moderatorSafetyMuteVideo,
      moderatorSafetyGrayscale,
      moderatorSafetyBlurLevel: moderatorSafetyBlurLevel as BlurStrength,
    });
  }, [safetySettingsData?.me?.interfacePreferences]);

  const {
    data: accountSettingsData,
    loading: accountSettingsLoading,
    error: accountSettingsError,
  } = useGQLAccountSettingsQuery();

  const [modalInfo, setModalInfo] = useState<ModalInfo>({
    visible: false,
    title: '',
    body: '',
    okText: '',
    onOk: () => {},
    okIsDangerButton: false,
    cancelVisible: true,
  });
  const [firstName, setFirstName] = useState(
    accountSettingsData?.me?.firstName,
  );
  const [lastName, setLastName] = useState(accountSettingsData?.me?.lastName);

  useEffect(() => {
    setFirstName(accountSettingsData?.me?.firstName);
    setLastName(accountSettingsData?.me?.lastName);
  }, [accountSettingsData]);

  const hideModal = () => {
    setModalInfo({
      ...modalInfo,
      visible: false,
    });
  };

  const [updateAccountInfo, { loading: updateAccountInfoLoading }] =
    useGQLUpdateAccountInfoMutation({
      onError: () => {
        setModalInfo({
          visible: true,
          title: 'Error Saving Changes',
          body: 'We encountered an error trying to update your account information. Please try again.',
          okText: 'OK',
          onOk: hideModal,
          okIsDangerButton: false,
          cancelVisible: false,
        });
      },
      onCompleted: () =>
        setModalInfo({
          visible: true,
          title: 'Changes Saved',
          body: 'Your account information was successfully updated!',
          okText: 'Done',
          onOk: hideModal,
          okIsDangerButton: false,
          cancelVisible: false,
        }),
    });

  const [deleteAccount] = useGQLDeleteUserMutation({
    onError: () => {
      setModalInfo({
        visible: true,
        title: 'Error Deleting Account',
        body: 'We encountered an error trying to delete your account. Please try again.',
        okText: 'OK',
        onOk: hideModal,
        okIsDangerButton: false,
        cancelVisible: false,
      });
    },
    onCompleted: () => navigate('/'),
  });

  if (accountSettingsLoading || safetySettingsLoading) {
    return <FullScreenLoading />;
  }
  if (accountSettingsError || safetySettingsError) {
    throw accountSettingsError ?? safetySettingsError!;
  }

  const onSave = () => {
    updateAccountInfo({
      variables: {
        firstName,
        lastName,
      },
      refetchQueries: [namedOperations.Query.AccountSettings],
    });
    saveSafetySettings({
      variables: {
        moderatorSafetySettings: settings,
      },
    });
  };

  const onDeleteAccount = () => {
    deleteAccount({
      variables: {
        // This is safe because if we're deleting a user, we're
        // sure we already have the user ID
        id: accountSettingsData!.me!.id,
      },
    });
  };

  const saveButton = (
    <Button
      onClick={onSave}
      disabled={
        !firstName ||
        !lastName ||
        firstName.length === 0 ||
        lastName.length === 0
      }
      loading={safetySettingsMutationLoading || updateAccountInfoLoading}
    >
      Save
    </Button>
  );

  const deleteButton = (
    <Button
      variant="destructive"
      onClick={() =>
        setModalInfo({
          visible: true,
          title: 'Are you sure you want to delete your account?',
          body:
            "Deleting your account is a permanent action - you can't " +
            "undo it. If you're the last remaining user in your organization's " +
            'Cove account, no one in your organization will be able to log ' +
            'back in. Are you sure you want to proceed with deleting your Cove ' +
            'account?',
          okText: 'Delete My Account',
          onOk: onDeleteAccount,
          okIsDangerButton: true,
          cancelVisible: true,
        })
      }
    >
      Delete Account
    </Button>
  );

  const modalFooter: CoveModalFooterButtonProps[] = [
    {
      title: modalInfo.okText,
      type: modalInfo.okIsDangerButton ? 'danger' : 'primary',
      onClick: modalInfo.onOk,
    },
  ];
  if (modalInfo.cancelVisible) {
    modalFooter.unshift({
      title: 'Cancel',
      onClick: hideModal,
      type: modalInfo.okIsDangerButton ? 'primary' : 'secondary',
    });
  }

  const modal = (
    <CoveModal
      title={modalInfo.title}
      visible={modalInfo.visible}
      onClose={hideModal}
      footer={modalFooter}
    >
      {modalInfo.body}
    </CoveModal>
  );

  const safetySettings = (
    <div className="flex flex-col mt-6 text-start">
      <div className="flex gap-8">
        <div className="flex flex-col justify-center">
          <div className="mb-4 text-lg font-medium text-slate-700">
            My Safety Settings
          </div>
          <div className="flex items-center h-10">
            {safetySetting(
              'Blur',
              <Slider
                className="w-32"
                min={0}
                max={Object.keys(BLUR_LEVELS).length - 1}
                onChange={(strength) =>
                  setSettings({
                    ...settings,
                    moderatorSafetyBlurLevel: strength as BlurStrength,
                  })
                }
                value={settings.moderatorSafetyBlurLevel}
                step={1}
                tooltip={{ formatter: null }}
              />,
            )}
          </div>
          <div className="flex items-center h-10">
            {safetySetting(
              'Grayscale',
              <Switch
                size="small"
                defaultChecked
                onChange={(value) =>
                  setSettings({
                    ...settings,
                    moderatorSafetyGrayscale: value,
                  })
                }
                checked={settings.moderatorSafetyGrayscale}
              />,
            )}
          </div>
          <div className="flex items-center h-10">
            {safetySetting(
              'Mute Videos',
              <Switch
                size="small"
                defaultChecked
                onChange={(value) =>
                  setSettings({
                    ...settings,
                    moderatorSafetyMuteVideo: value,
                  })
                }
                checked={settings.moderatorSafetyMuteVideo}
              />,
            )}
          </div>
        </div>
        <img
          className={`rounded object-scale-down w-72 h-44 ${
            settings.moderatorSafetyBlurLevel != null
              ? BLUR_LEVELS[settings.moderatorSafetyBlurLevel]
              : 'blur-sm'
          } ${settings.moderatorSafetyGrayscale ? 'grayscale' : ''}`}
          alt="puppies"
          src={GoldenRetrieverPuppies}
        />
      </div>
    </div>
  );

  return (
    <div className="flex flex-col">
      <Helmet>
        <title>Account Settings</title>
      </Helmet>
      <DashboardHeader
        title="Account"
        subtitle="View and update your personal account information here."
      />
      <div className="flex flex-col gap-12">
        <div className="flex items-center justify-start gap-4">
          <div className="flex flex-col">
            <div className="mb-2 font-semibold">First Name</div>
            <Input
              className="!w-full"
              required
              placeholder="Your first name is required."
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div className="flex flex-col">
            <div className="mb-2 font-semibold">Last Name</div>
            <Input
              className="!w-full"
              required
              placeholder="Your last name is required."
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <div className="flex flex-col">
            <div className="mb-2 font-semibold">Email</div>
            <Input
              className="!w-full"
              required
              placeholder="Your email is required."
              value={accountSettingsData?.me?.email}
              disabled={true}
            />
          </div>
        </div>
        <div className="flex flex-col mt-6">
          <FormSectionHeader
            title="Safety Settings"
            subtitle="These are your personal default settings. Every time you view a reported image or video in Cove, these settings will be automatically applied."
          />
          {safetySettings}
          <div className="flex justify-start w-full gap-4 mt-12">
            {saveButton}
            {deleteButton}
          </div>
        </div>
      </div>
      {modal}
    </div>
  );
}
