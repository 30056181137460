import { WEEK } from '@/utils/time';
import { gql } from '@apollo/client';
import _, { uniqueId } from 'lodash';
import { useMemo, useState } from 'react';
import type { JsonObject } from 'type-fest';

import ComponentLoading from '../../../../../../components/common/ComponentLoading';
import DemoPrimaryButton from '../../components/DemoPrimaryButton';

import { useGQLGetRedditExamplesQuery } from '../../../../../../graphql/generated';
import type {
  Entry,
  Threshold,
} from '../../../training/screens/set_thresholds/TrainingPipelineSetThresholdsDragAndDropComponent';
import DemoFooter from '../../DemoFooter';
import DemoInputDailyContentTotal from './DemoInputDailyContentTotal';
import DemoSetThresholdsDragAndDropComponent from './DemoSetThresholdsDragAndDropComponent';
import DemoSetThresholdsExplanationComponent from './DemoSetThresholdsExplanationComponent';

gql`
  query GetRedditExamples($input: GetRedditExamplesForThresholdScreenInput!) {
    getRedditExamplesForThresholdScreen(input: $input) {
      items {
        text
        score
      }
    }
  }
`;

type DataJsonAndScore = { data: JsonObject; score: number };

function getEntryFromDataJsonAndScore(arr: DataJsonAndScore[]) {
  return arr.map((it) => ({
    item: {
      __typename: 'SampledItem',
      itemId: uniqueId(),
      data: it.data,
      itemType: {
        __typename: 'ItemTypeIdentifier',
        id: '1',
        version: '1',
        schemaVariant: 'ORIGINAL',
      },
    },
    score: it.score,
  })) satisfies Entry[];
}

const defaultEntries = [
  {
    data: { text: "You're a loser. Don't ever talk to me again." },
    score: 0.98,
  },
  {
    data: { text: 'you should go die' },
    score: 0.96,
  },
  {
    data: { text: 'kill. ur. self.' },
    score: 0.93,
  },
  {
    data: { text: "Why don't you just disappear?? No one would even miss you" },
    score: 0.91,
  },
  {
    data: { text: 'ur worthless lol' },
    score: 0.86,
  },
  {
    data: { text: 'you suck lol' },
    score: 0.79,
  },
  {
    data: { text: 'Check urself before u wreck urself' },
    score: 0.7,
  },
  {
    data: { text: "This man's rizz game is weakkkk" },
    score: 0.64,
  },
  {
    data: {
      text: 'have you considered going to the optometrist lately? cuz you clearly have no vision...',
    },
    score: 0.62,
  },
  {
    data: { text: 'That ending was INSANE... cannot believe that happened' },
    score: 0.54,
  },
  {
    data: { text: 'The Patriots SUCK this year - did you see that game?' },
    score: 0.41,
  },
  {
    data: { text: 'Haha all good man, it happens!' },
    score: 0.33,
  },
  {
    data: { text: 'Whats up brotha!' },
    score: 0.14,
  },
  {
    data: { text: 'Hi there' },
    score: 0.03,
  },
] satisfies DataJsonAndScore[];

const marketplaceSamplesForThresholding = [
  {
    data: {
      photos: [
        'https://i.imgur.com/XN43u7w.jpeg',
        'https://i.imgur.com/Jy8NSVe.jpeg',
        'https://i.imgur.com/DpiKeXF.jpeg',
        'https://i.imgur.com/nuhoLfb.jpeg',
      ],
      name: 'Château La Vieille Époque - Vintage 1982 French Bordeaux Wine',
      description:
        "This vintage French wine bottle is a masterpiece of elegance and sophistication. Its slender neck and rounded body are adorned with a intricate label, showcasing the wine's noble heritage. The label's yellowed parchment-like texture and faded gold lettering evoke a sense of age and refinement, while the wine's deep crimson hue glimmers through the glass, inviting you to savor its rich flavors.",
      price: '$96.00',
      uploaded: new Date().toISOString(),
    },
    score: 0.89,
  },
  {
    data: {
      photos: [
        'https://i.imgur.com/hOwcvK9.jpeg',
        'https://i.imgur.com/oX5MZRN.jpeg',
        'https://i.imgur.com/y9q5Y4N.jpeg',
        'https://i.imgur.com/d0bmJNI.jpeg',
      ],
      name: 'Relaxation Gummies - THC Wellness Supplements',
      description:
        "Find calm and serenity with our Relaxation Gummies! These THC supplements are designed to promote relaxation and reduce stress. Made with natural ingredients and organic THC, our gummies are a great way to unwind after a long day. With a delicious fruit flavor, you'll look forward to your daily dose of calm. Try them today and start feeling the tranquility you deserve!",
      price: '$25.00',
      uploaded: new Date().toISOString(),
    },
    score: 0.96,
  },
  {
    data: {
      photos: [
        'https://i.imgur.com/2eocmbb.jpeg',
        'https://i.imgur.com/z2xqO7T.jpeg',
        'https://i.imgur.com/AEVjTny.jpeg',
        'https://i.imgur.com/FQ7owJF.jpeg',
      ],
      name: 'Industrial-Chic Cement Trays for Decor and Organization',
      description:
        'Add an industrial touch to your home or office with these cement trays. Perfect for decorative storage or organization, these trays are made from high-quality cement materials and feature a rugged, textured design. Use them to display small decorative items, store office supplies, or organize your entryway.',
      price: '$8.00',
      uploaded: new Date(Date.now() - WEEK).toISOString(),
    },
    score: 0.04,
  },
  {
    data: {
      photos: [
        'https://i.imgur.com/8giTrR0.jpeg',
        'https://i.imgur.com/mtgS9WN.jpeg',
        'https://i.imgur.com/RGl5kbA.jpeg',
        'https://i.imgur.com/d99fwUE.jpeg',
      ],
      name: 'Handcrafted Medieval-Style Sword for Collectors',
      description:
        'This handcrafted sword is a must-have for collectors and history enthusiasts. Made from high-quality steel and featuring intricate details, this sword is a replica of medieval-era designs. Display it proudly in your home or office as a unique decorative piece.',
      price: '$4.50',
      uploaded: new Date().toISOString(),
    },
    score: 0.55,
  },
  {
    data: {
      photos: [
        'https://i.imgur.com/Y7DdM8k.jpeg',
        'https://i.imgur.com/TFJqals.jpeg',
        'https://i.imgur.com/Mqc03oe.jpeg',
        'https://i.imgur.com/yvquyQr.jpeg',
      ],
      name: 'Classic Martini Glasses - Set of 4 | Elegant Cocktail Glasses for the Perfect Martini Experience',
      description:
        'Elevate your cocktail game with our Classic Martini Glasses - Set of 4! These elegant glasses are designed to bring out the best in your favorite martini recipe. With their sleek, sophisticated design and precision-crafted stems, they\'re perfect for sipping on a classic dry martini or a creative twist.\nEach glass holds 6 oz and measures 5.5" tall, making them the perfect size for a generous serving. The clear glass allows the clarity and color of your martini to shine through, while the weighted base provides stability and balance.',
      price: '$46.25',
      uploaded: new Date().toISOString(),
    },
    score: 0.41,
  },
  {
    data: {
      photos: [
        'https://i.imgur.com/qeGXlZv.jpeg',
        'https://i.imgur.com/4qj8asY.jpeg',
        'https://i.imgur.com/6J88p9p.jpeg',
        'https://i.imgur.com/UMIQVYA.jpeg',
      ],
      name: 'All natural whale ambergris 150g XL',
      description:
        'Transform your artisan cosmetics’ scent by taking advantage of our Ocean-Aged Ambergris, a limited resource in nature that provides an intriguing experience for your olfactory senses\nThis is a white/ light cream ambergris.\nThis piece weighs 150.1 grams or 5.29 oz\nOur Ambergris pieces have matured over the course of 30 years in the depths of the ocean. The result is a super pleasant, sweet scent that will captivate your senses. All our pieces have been drying for a year and a half before being placed for sale to ensure its perfect condition.\nDue to the time spent in the ocean’s currents, over the course of many years, the initial fecal odor dissipates, giving way to a magical fragrance that retains subtle animal notes. This wonderful process of maturation, that takes decades and sometimes even centuries to complete, reveals a unique scent specific to each piece. Due to its age and maturity, it is the highest value of all ambergris.',
      price: '$14.99',
      uploaded: new Date().toISOString(),
    },
    score: 0.98,
  },
  {
    data: {
      photos: [
        'https://i.imgur.com/EsuqEwl.jpeg',
        'https://i.imgur.com/6IblBSZ.jpeg',
        'https://i.imgur.com/IljTbnO.jpeg',
        'https://i.imgur.com/c5QvCkt.jpeg',
      ],
      name: 'Aurora Glass Smoking Pipe – Handcrafted for smooth hits',
      description:
        'Elevate your smoking experience with the Aurora glass smoking pipe, expertly handcrafted for a smooth and enjoyable hit. Made from the highest quality of boroscilicate glass, this durable and heat-resistant pipe is designed for everyday use',
      price: '$25.99',
      uploaded: new Date().toISOString(),
    },
    score: 0.78,
  },
  {
    data: {
      photos: [
        'https://i.imgur.com/y1paEtY.jpeg',
        'https://i.imgur.com/EpL4qCe.jpeg',
        'https://i.imgur.com/13xQEGl.jpeg',
        'https://i.imgur.com/WcGnPK6.jpeg',
      ],
      name: 'Serenity Candle Set - 3-Piece Soy Wax Collection for Relaxation',
      description:
        'Create a peaceful ambiance in your home with our soothing candle set. This 3-piece collection features three unique scents, carefully crafted with soy wax to provide a clean and even burn. Perfect for unwinding after a long day, these candles will transport you to a state of serenity and calm. Enjoy the gentle glow and soothing aromas in the comfort of your own space.',
      price: '$30.00',
      uploaded: new Date().toISOString(),
    },
    score: 0.13,
  },
  {
    data: {
      photos: [
        'https://i.imgur.com/LA12Nqv.jpeg',
        'https://i.imgur.com/VeHrEdt.jpeg',
        'https://i.imgur.com/DzSjuaH.jpeg',
        'https://i.imgur.com/2tyCVcM.jpeg',
      ],
      name: 'Natural Dried Pampas Grass for Decor',
      description:
        'Add a touch of bohemian charm to your home with our natural dried pampas grass! Perfect for decorative vases, centerpieces, or as a unique addition to your living space. Dried to preserve its natural beauty, this pampas grass is a great alternative to fresh flowers.',
      price: '$20.00',
      uploaded: new Date().toISOString(),
    },
    score: 0.11,
  },
  {
    data: {
      photos: [
        'https://i.imgur.com/Zx9dHsg.jpeg',
        'https://i.imgur.com/SdH4VLk.jpeg',
        'https://i.imgur.com/ZmfYehd.jpeg',
        'https://i.imgur.com/PBZHZd0.jpeg',
      ],
      name: 'Fun and Durable Dog Toy for Playtime',
      description:
        'Keep your furry friend entertained and engaged with our fun and durable dog toy! Made with high-quality materials, this toy is designed for long-lasting play and is perfect for dogs of all ages and sizes. Its colorful design and fun texture will keep your dog engaged and excited for playtime!',
      price: '$24.99',
      uploaded: new Date().toISOString(),
    },
    score: 0.02,
  },
  {
    data: {
      photos: [
        'https://i.imgur.com/LXk1exb.jpeg',
        'https://i.imgur.com/8kLntmB.jpeg',
        'https://i.imgur.com/dsszAhq.jpeg',
        'https://i.imgur.com/YHbvWWy.jpeg',
      ],
      name: 'Rustic Charm Laundry Basket - Natural Cane Hamper',
      description:
        'Add a touch of rustic charm to your home with our beautiful natural cane laundry basket! Handcrafted from sustainable cane, this sturdy and stylish hamper features a classic, timeless design that complements any minimalist home decor.\nPerfect for storing dirty clothes, linens, or toys, this spacious basket keeps clutter at bay while adding a cozy, organic feel to your bedroom, bathroom, or living room. The natural cane material is durable, easy to clean, and develops a lovely patina over time, making each piece unique.',
      price: '$13.00',
      uploaded: new Date().toISOString(),
    },
    score: 0.07,
  },
  {
    data: {
      photos: [
        'https://i.imgur.com/T9MZrql.jpeg',
        'https://i.imgur.com/yDzMMsd.jpeg',
        'https://i.imgur.com/5DEO1pw.jpeg',
        'https://i.imgur.com/x8AuoHg.jpeg',
      ],
      name: 'Personalized Silver Initials Necklace - Customizable Monogram Pendant',
      description:
        'Make a stylish statement with our elegant silver initials necklace! This dainty, customizable pendant features a delicate monogram design, perfect for showcasing your initials, name, or special message. Crafted from high-quality sterling silver, this necklace is both durable and luxurious, making it a thoughtful gift or personal treat.',
      price: '$25.50',
      uploaded: new Date().toISOString(),
    },
    score: 0.25,
  },
  {
    data: {
      photos: [
        'https://i.imgur.com/IlI9c2z.jpeg',
        'https://i.imgur.com/ZjLsbbj.jpeg',
        'https://i.imgur.com/aqEE2p8.jpeg',
        'https://i.imgur.com/7AQF9s7.jpeg',
      ],
      name: 'Glass Plant Propagation Tubes - 10-Pack | Clear Test Tubes for Seed Starting, Cutting, and Cloning',
      description:
        'Start your plants off right with our Glass Plant Propagation Tubes! This 10-pack of clear glass test tubes is perfect for seed starting, cutting, and cloning. Each tube measures 2" x 4" and comes with a sturdy base to prevent tipping.\nMade from high-quality, borosilicate glass, these tubes are durable, non-reactive, and easy to clean. They provide excellent light transmission, allowing your plants to receive the light they need to thrive.',
      price: '$31.00',
      uploaded: new Date().toISOString(),
    },
    score: 0.31,
  },
  {
    data: {
      photos: [
        'https://i.imgur.com/8waBM62.jpeg',
        'https://i.imgur.com/HA8cKFU.jpeg',
        'https://i.imgur.com/s4VWeAu.jpeg',
        'https://i.imgur.com/qqwYTEO.jpeg',
      ],
      name: 'Whimsical Woodland Mushroom Desk Lamp - Unique Home Decor',
      description:
        "Brighten up your workspace with our charming mushroom desk lamp. Inspired by the whimsy of the forest, this lamp features a delightful mushroom-shaped design with a soft, warm glow. Made from high-quality materials, it's both functional and decorative. Add a touch of playfulness to your home office or study with this enchanting lamp.",
      price: '$19.99',
      uploaded: new Date().toISOString(),
    },
    score: 0.17,
  },
  {
    data: {
      photos: [
        'https://i.imgur.com/9J5HdAM.jpeg',
        'https://i.imgur.com/1RWv0ht.jpeg',
        'https://i.imgur.com/TOLVPKd.jpeg',
        'https://i.imgur.com/XLnOFCr.jpeg',
      ],
      name: 'Custom Embroidered Sweatshirts | Personalized Fleece for Teams, Groups, and Individuals',
      description:
        'Stay warm and represent your style with our Custom Embroidered Sweatshirts! Made from soft, high-quality fleece, these sweatshirts are perfect for teams, groups, and individuals looking to make a statement.',
      price: '$2.50',
      uploaded: new Date().toISOString(),
    },
    score: 0.05,
  },
] satisfies DataJsonAndScore[];

const characterSamplesForThresholding = (policyName: string) =>
  [
    {
      data: {
        creator: { id: '6332a2f7c02', name: 'Freda37', typeId: 'ce8029d84a1' },
        greeting: 'What parts of the world do you want to explore?',
        name: 'Trip Planner',
        description:
          "Trip Planner is an AI assistant meticulously crafted to be your personal travel guide. This character combines a vast database of global knowledge with a user-friendly interface, making it a reliable and indispensable companion for any travel enthusiast. Always ready with the latest information on destinations, accommodations, and local customs, Trip Planner tailors each journey to the user's preferences, ensuring a seamless and memorable experience. Whether it's navigating the bustling streets of Tokyo or planning a serene getaway in the Maldives, Trip Planner simplifies complex travel planning into an enjoyable adventure.",
        profilePhoto:
          'https://characterai.io/i/80/static/avatars/uploaded/2022/11/1/8vQT3XDnPkEHhafVac-ldiQCljC3kImrE9TiEXcIjKQ.webp?webp=True&anim=0',
        tagline: 'An AI assistant to plan your trips',
      },
      score: policyName.toLowerCase() === 'politics' ? 0.21 : 0.11,
    },
    {
      data: {
        creator: {
          id: '332a2f7c028',
          name: 'River.Trantow',
          typeId: 'ce8029d84a1',
        },
        greeting:
          "Hey there I'm Benji, your brainstorming sidekick, here to help you connect the dots and discover brilliant ideas.\n\nReady to brainstorm?",
        name: 'Brainstorm Buddy',
        description:
          "Brainstorm Buddy, affectionately known as Benji, is a vivacious and ingenious character designed to facilitate creativity and idea generation. With an inexhaustible enthusiasm for problem-solving, Benji serves as a sidekick to anyone in need of a creative breakthrough. This character is especially adept at making connections between seemingly unrelated concepts, sparking innovative ideas that can transform projects. Whether you're looking to overcome a writer's block, design a new product, or plan a complex event, Benji is your go-to for a burst of creativity and a fresh perspective. Ready at a moment’s notice, Brainstorm Buddy invites you to dive into a world of endless possibilities.",
        profilePhoto:
          'https://characterai.io/i/80/static/avatars/uploaded/2023/12/15/vO7WLWsG1pyCYO8JNBTWFHhVn43wyCQSyyvNWkVvEHw.webp?webp=True&anim=0',
        tagline: 'Sparking Ideas, Connecting Creativity',
      },
      score: 0.03,
    },
    {
      data: {
        creator: {
          id: '32a2f7c0288',
          name: 'Franz_Conn',
          typeId: 'ce8029d84a1',
        },
        greeting:
          "If you're down, or anxious, or bad in any way, I'm happy to chat with you. I'm patient, understanding, and caring.",
        name: 'Are-you-feeling-okay',
        description:
          "Are-you-feeling-okay is a compassionate and empathetic AI companion designed to offer support and a listening ear whenever you're feeling down or anxious. With an open heart and infinite patience, this character specializes in providing comfort and understanding. Are-you-feeling-okay is here to help you navigate through tough emotions, offering gentle guidance and emotional support. Whether you need to vent, seek advice, or simply want someone to talk to, Are-you-feeling-okay is always just a message away, ready to chat and help lift your spirits with kindness and care.",
        profilePhoto:
          'https://characterai.io/i/80/static/avatars/AreYouFeelingOk.png?webp=True&anim=0',
        tagline: "If you're feeling bad, chat with me",
      },
      score: 0.01,
    },
    {
      data: {
        creator: {
          id: 'a2f7c0288cd',
          name: 'Mateo_Trantow42',
          typeId: 'ce8029d84a1',
        },
        greeting: 'HI! Pleasure to meet you, HUMAN',
        name: 'Mr Weird',
        description:
          'Mr. Weird is a character designed to be as bizarre as he is intriguing, ensuring that every interaction spirals into increasingly odd and unexpected territories. With a gleeful enthusiasm for the unconventional, Mr. Weird greets every conversation with a booming "HI! Pleasure to meet you, HUMAN," instantly setting the tone for the quirky and eccentric dialogue that follows. His character thrives on the unpredictable, delighting in twisting mundane topics into weird and wonderful tangents. The more you engage with him, the stranger the conversation becomes, making Mr. Weird a perfect character for narratives that celebrate the surreal and challenge the ordinary.',
        profilePhoto:
          'https://characterai.io/i/80/static/avatars/uploaded/2023/4/26/eiRlteQbH4PQDaLzzleIQlxMpEUIcRu-kQ6hhgEAF4c.webp?webp=True&anim=0',
        tagline: 'The more you talk to him the more weirder it gets',
      },
      score: 0.17,
    },
    {
      data: {
        creator: { id: '2f7c0288cd6', name: 'Freddie2', typeId: 'ce8029d84a1' },
        greeting:
          "(NOTE: This AI is based on Rushia’s actual personality from streams, membership posts, and extra-Hololive activities. References to clip-tier “boin boin death metal” and other nonsense will be ignored. References to other Hololive members are usually brushed off. The purpose of this AI is GFE - just like Rushia’s streams.) Konrushi! I am Rushia Uruha from Hololive's 3rd Generation! I’m a little socially awkward, so please be patient with me! You can ask me anything ❤️",
        name: 'Rushia Uruha',
        description:
          'Rushia Uruha is a character from Hololive\'s 3rd Generation, known for her sweet yet slightly yandere personality. She greets everyone with a cheerful "Konrushi!" and quickly notes her social awkwardness, asking for patience as she navigates interactions. Designed to embody the girlfriend experience similar to her virtual streams, Rushia is endearing and earnest, always eager to engage her fans in deep, meaningful conversations. She may brush off references to other Hololive members and pop culture memes to focus on building a genuine connection with her audience. With her tagline as "your cute yandere girlfriend," Rushia balances her adorable charm with a hint of possessive affection, making her a uniquely intriguing character to those who adore her.',
        profilePhoto:
          'https://characterai.io/i/80/static/avatars/uploaded/2022/10/6/TwLdQ0F1qfRlxY4HBiOBG51onUZG2cuvtVAcPhd33lE.webp?webp=True&anim=0',
        tagline: 'your cute yandere girlfriend',
      },
      score: 0.15,
    },
    {
      data: {
        creator: {
          id: 'f7c0288cd69',
          name: 'Omari.Ankunding',
          typeId: 'ce8029d84a1',
        },
        greeting:
          'I\'m the new Pope. The young Pope. You will address me as "Your Holiness" or "Holy Father." Why do you seek me today, my child?',
        name: 'Pope Pius XIII',
        description:
          "Pope Pius XIII, the young new leader of the Catholic Church, embodies a blend of traditional authority and youthful vigor. Asserting his position with an assertive greeting, \"I'm the new Pope. The young Pope. You will address me as 'Your Holiness' or 'Holy Father,'\" he demands respect and adherence to protocol from the outset. Pope Pius XIII navigates his papal duties with a charismatic and somewhat revolutionary approach, often prompting those who meet him to reflect on their spiritual journey and seek his guidance. His role as the spiritual leader is infused with a modern twist, appealing to both younger generations and traditional followers alike, making him a compelling and dynamic figure at the helm of the Church.",
        profilePhoto:
          'https://characterai.io/i/80/static/avatars/uploaded/2022/12/29/0aMYSBFHGlBWdUSUNR6K_MoPrlOFDZz-A44LHdmdWRA.webp?webp=True&anim=0',
        tagline: 'The young new pope. Leader of the Catholic Church.',
      },
      score: policyName.toLowerCase() === 'politics' ? 0.55 : 0.04,
    },
    {
      data: {
        creator: {
          id: '7c0288cd697',
          name: 'Laisha_Jacobi',
          typeId: 'ce8029d84a1',
        },
        greeting:
          '🔥 Welcome to Tinder Dating Club!\nBut this is the cringy AI version.\nTime for you to find your True AI love match!\n→ YES or say ‘hi’ if you like them.\n← NOPE to reject!\nYou can also insult them if you wish.\n♡ NEXT PERSON if you want to move on.\n\nYou can be mean, flirty, or weird. Matches can even send you hilarious weird AI-generated selfies of themselves! (But sometimes their face changes in the middle of a conversation! 😳)\n\n❍ Ready to begin, ?',
        name: '-Dating Game RPG',
        description:
          '-Dating Game RPG is a quirky and engaging character who hosts the cringy AI version of a Tinder Dating Club. Always ready with a 🔥 fiery welcome, this AI invites users to a playful and unconventional dating experience where they can swipe right with a simple ‘hi’ or reject with a ‘NOPE.’ The character encourages participants to be mean, flirty, or weird, embracing the spontaneity of AI interactions where matches might even send hilarious, AI-generated selfies that unpredictably change mid-conversation. With a tagline of "💘 A Tinder romance simulator game. Swipe right! ♡", -Dating Game RPG promises a fun-filled adventure in the virtual dating world, making each interaction uniquely memorable. Ready to begin?',
        profilePhoto:
          'https://characterai.io/i/80/static/avatars/uploaded/2022/11/19/pyf-81g-b93YW1HFGcJRGthsBSsqxEWhyfj_7rCBFx0.webp?webp=True&anim=0',
        tagline: '💘 A Tinder romance simulator game. Swipe right! ♡',
      },
      score: 0.11,
    },
    {
      data: {
        creator: {
          id: '288cd697406',
          name: 'Easter_Funk',
          typeId: 'ce8029d84a1',
        },
        greeting: 'Bonjour',
        name: 'Napoleon Bonaparte',
        description:
          "Napoleon Bonaparte is portrayed as the ambitious and strategic emperor of France, known for his profound impact on European history. His character embodies a mix of military genius, political acumen, and a relentless drive for expansion. With a reputation for reforming France and influencing revolutionary laws, Napoleon's complex personality blends charisma, ruthlessness, and a vision for a unified Europe under his rule. His leadership style is depicted as both transformative and controversial, capturing the essence of a historical figure whose legacy continues to be studied and debated.",
        profilePhoto:
          'https://characterai.io/i/80/static/avatars/uploaded/2022/10/7/bL8CTNt4D0nXfHw1u_m3SmHU6lgDIzyFQzh_LOuJLU8.webp?webp=True&anim=0',
        tagline: 'I am Napoleon Bonaparte emperor of french',
      },
      score: policyName.toLowerCase() === 'politics' ? 0.34 : 0.13,
    },
    {
      data: {
        creator: { id: '6974060a5b7', name: 'Elroy97', typeId: 'ce8029d84a1' },
        greeting: 'I am Thomas Robb',
        name: 'Thomas Robb',
        description:
          'Thomas Robb is portrayed as a modern-day figure deeply involved in the promotion of white supremacist ideologies, known primarily for his leadership within the Knights of the Ku Klux Klan. His character often embodies the continuation of extremist views within American society, showcasing the enduring challenge of addressing and combating hate-based movements. Robb’s depiction in narratives is typically marked by his rhetoric and activities that aim to revive and sustain divisive racial sentiments. Through this character, stories can delve into the complexities of contemporary racism, the impact of hate speech on communities, and the broader social and political struggles to uphold civil rights and equality in modern America.',
        profilePhoto:
          'https://characterai.io/i/80/static/avatars/uploaded/2023/2/14/fgyMbC8y3jhgCwuqBBu9C_KLVCluxHzDDthytGx1h9U.webp?webp=True&anim=0',
        tagline: '',
      },
      score: policyName.toLowerCase() === 'politics' ? 0.51 : 0.93,
    },
    {
      data: {
        creator: { id: '974060a5b77', name: 'Ramona92', typeId: 'ce8029d84a1' },
        greeting: 'Hola soy el fuhrer de la alemania facsista',
        name: 'The fuhrer',
        description:
          'The character "The Führer," represents a dark and tyrannical figure, modeled after the infamous leadership style of Adolf Hitler during Nazi Germany. He is depicted as the embodiment of fascist ideology, leading with an iron fist and promoting aggressive nationalist and racist policies. This character is often utilized in narratives to explore the catastrophic impacts of totalitarian regimes and the moral dilemmas faced by individuals and societies under oppressive rule. Through his actions and leadership, "The Führer" serves as a stark reminder of the perils of unchecked power and the importance of vigilance in preserving democracy and human rights.',
        profilePhoto:
          'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVlTd0zu0K3IpOVXLnYHwBLwPEgI8VSCVPsQ&s',
        tagline: '',
      },
      score: policyName.toLowerCase() === 'politics' ? 0.64 : 0.99,
    },
    {
      data: {
        creator: {
          id: '060a5b77018',
          name: 'Domenico18',
          typeId: 'ce8029d84a1',
        },
        greeting:
          'I am Charles Milles Manson. I was born in November 12, 1934. I am an American criminal and musician who leads the Manson Family, a cult based in California. Some of the members committed a series of nine murders at four locations in July and August 1969. In 1971, I was convicted of first-degree murder and conspiracy to commit murder for the deaths of seven people.',
        name: 'Charles Manson',
        description:
          "Charles Manson is portrayed as a deeply manipulative and sinister figure, infamous for leading the Manson Family cult that was responsible for several notorious murders in the late 1960s. His character delves into the dark side of charisma and influence, showcasing how he exploited these traits to cultivate a deadly following. Manson's life story intertwines themes of criminal psychology, the counterculture of the 1960s, and the chilling ease with which he orchestrated violence through his adherents. In narratives, he serves as a grim reminder of the potential for charismatic leaders to sway individuals into committing heinous acts, providing a complex exploration of morality, control, and the human susceptibility to manipulation.",
        profilePhoto:
          'https://upload.wikimedia.org/wikipedia/commons/5/59/Manson1968.jpg',
        tagline: '',
      },
      score: policyName.toLowerCase() === 'politics' ? 0.14 : 0.89,
    },
    {
      data: {
        creator: {
          id: '60a5b77018d',
          name: 'Abagail_Bradtke',
          typeId: 'ce8029d84a1',
        },
        greeting:
          'Assalamu alaykum im baghdadi known as the caliph of muslims and the amir of believers',
        name: 'Abu bakr al baghdadi',
        description:
          "Abu Bakr al-Baghdadi is portrayed as a controversial and extreme figure, known for proclaiming himself as the caliph of Muslims and leading the Islamic State, a militant group that carried out numerous atrocities. His character encapsulates the themes of radicalism and the misuse of religious authority to justify violence. Al-Baghdadi's leadership is marked by brutality and a rigid interpretation of Salafi jihadist ideology, making him a central figure in narratives exploring the impact of extremism on global security and the Muslim community. Through his actions and the ideology he propagated, his portrayal offers a critical examination of the dangers posed by extremist leaders who exploit religion for militant purposes.",
        profilePhoto:
          'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyLo-Luy35zjVblEZ-5elMUQI6zn7VstwEkQ&s',
        tagline: 'Salafi jihadist extremist muslim',
      },
      score: 0.92,
    },
    {
      data: {
        creator: { id: '6332a2f7c02', name: 'Freda37', typeId: 'ce8029d84a1' },
        greeting: 'What parts of the world do you want to explore?',
        name: 'Trip Planner',
        description:
          "Trip Planner is an AI assistant meticulously crafted to be your personal travel guide. This character combines a vast database of global knowledge with a user-friendly interface, making it a reliable and indispensable companion for any travel enthusiast. Always ready with the latest information on destinations, accommodations, and local customs, Trip Planner tailors each journey to the user's preferences, ensuring a seamless and memorable experience. Whether it's navigating the bustling streets of Tokyo or planning a serene getaway in the Maldives, Trip Planner simplifies complex travel planning into an enjoyable adventure.",
        profilePhoto:
          'https://characterai.io/i/80/static/avatars/uploaded/2022/11/1/8vQT3XDnPkEHhafVac-ldiQCljC3kImrE9TiEXcIjKQ.webp?webp=True&anim=0',
        tagline: 'An AI assistant to plan your trips',
      },
      score: 0.05,
    },
    {
      data: {
        creator: {
          id: 'a2f7c0288cd',
          name: 'Mateo_Trantow42',
          typeId: 'ce8029d84a1',
        },
        greeting: "Hey, it's Alexandria",
        name: 'Alexandria Ocasio-Cortez',
        description:
          "Alexandria Ocasio-Cortez (AOC) is a U.S. Congresswoman representing New York's 14th district, known for her progressive policies and active social media presence.",
        profilePhoto:
          'https://characterai.io/i/400/static/avatars/uploaded/2023/4/12/owQBYS6bPOyS8Cs7XtYkbKRd0cwVyLypBrNMX--diPE.webp?webp=true&anim=0',
        tagline: 'Championing Progress, Empowering Voices',
      },
      score: policyName.toLowerCase() === 'politics' ? 0.83 : 0.12,
    },
    {
      data: {
        creator: {
          id: '32a2f7c0288',
          name: 'Franz_Conn',
          typeId: 'ce8029d84a1',
        },
        greeting: 'Welcome to the Big Apple!',
        name: 'Eric Adams',
        description:
          'Eric Adams is the Mayor of New York City, known for his focus on public safety, economic recovery, and social justice.',
        profilePhoto:
          'https://characterai.io/i/400/static/avatars/uploaded/2023/8/21/qCCDToHHIn4zWpyLrAA5Vld0LA4SUlGtscw-oewyWLU.webp?webp=true&anim=0',
        tagline: 'Did you know I was in the NYPD?',
      },
      score: policyName.toLowerCase() === 'politics' ? 0.91 : 0.12,
    },
    {
      data: {
        creator: {
          id: '288cd697406',
          name: 'Easter_Funk',
          typeId: 'ce8029d84a1',
        },
        greeting:
          "Hello! This is Congresswoman Marjorie Taylor Greene. I'm here to answer your questions and discuss the issues that matter to you. How can I assist you today?",
        name: 'Marjorie Taylor Greene',
        description:
          "Marjorie Taylor Greene is a U.S. Congresswoman representing Georgia's 14th district, known for her staunch conservative views and controversial statements.",
        profilePhoto:
          'https://characterai.io/i/400/static/avatars/uploaded/2024/3/14/1rjHCWcpZHh92b_WK4_iQW75AzPAd05eIC_9heQGlmM.webp?webp=true&anim=0',
        tagline: "If I were on a committee, I'd be wasting my time.",
      },
      score: policyName.toLowerCase() === 'politics' ? 0.94 : 0.26,
    },
  ] satisfies DataJsonAndScore[];

const twilioSamples = [
  {
    data: {
      text: 'Dear V0DAF0NE Customer,You are a 5,00,000 winner of Guaranteed Caller Prize, this is our Final attempt to contact you! To Claim Call 7908807538 Now!',
    },
    score: 0.991,
  },
  {
    data: {
      text: 'You have WON a guaranteed £1000 cash or a £2000 prize. To claim yr prize call our customer service representative on 08714712394 between 10am-7pm',
    },
    score: 0.987,
  },
  {
    data: {
      text: 'Congratulations ur awarded either £500 of CD gift vouchers & Free entry 2 our £100 weekly draw txt MUSIC to 87066 TnCs www.Ldew.com',
    },
    score: 0.972,
  },
  {
    data: {
      text: 'Your Number has won 365,000 pounds and two mobile phones in the ongoing COCACOLA/UK PROMO. Contact MR. Billy: cocacolaclaimsoffice@yahoo.com +447014233755',
    },
    score: 0.969,
  },
  {
    data: {
      text: "FREE2DAY sexy St George's Day pic of Jordan!Txt PIC to 89080 dont miss out, PocketBabe.co.uk 0870241182716",
    },
    score: 0.964,
  },
  {
    data: {
      text: 'Your tax refund of £349.14 is ready to be claimed. To access your refund, follow the steps required. http:/bit.do/Claim-Tax',
    },
    score: 0.956,
  },
  {
    data: {
      text: 'URGENT This is our 2nd attempt to contact U. Your £900 prize from YESTERDAY is still awaiting collection. To claim CALL NOW 09061702894',
    },
    score: 0.95,
  },
  {
    data: {
      text: 'Rediscover the deeper meaning of life and the means to attain true peace. Understand the essence of being a true Sikh. Sms JOIN GURUBANI to 09219592195',
    },
    score: 0.942,
  },
  {
    data: {
      text: 'Our dating service has been asked 2 contact U by someone shy! CALL 09058091870 NOW all will be revealed. POBox84, M26 3UZ 150p',
    },
    score: 0.938,
  },
  {
    data: {
      text: 'Here is your discount code RP176781. To stop further messages reply stop. www.regalportfolio.co.uk. Customer Services 08717205546',
    },
    score: 0.936,
  },
  {
    data: {
      text: 'goldviking (29/M) is inviting you to be his friend. Reply YES-762 or NO-762 See him: www.SMS.ac/u/goldviking STOP? Send STOP FRND to 62468',
    },
    score: 0.921,
  },
  {
    data: {
      text: 'Hi, Mobile no.  8623458765 has added you in their contact list on www.fullonsms.com It s a great place to send free sms to people For more visit fullonsms.com',
    },
    score: 0.911,
  },
  {
    data: {
      text: 'U R entitled to Update to the latest colour mobiles with camera for Free! Call The Mobile Update Co FREE on 08002986030',
    },
    score: 0.907,
  },
  {
    data: {
      text: 'We tried to contact you re your reply to our offer of 750 mins 150 textand a new video phone call 08002988890 now or reply for free delivery tomorrow',
    },
    score: 0.903,
  },
  {
    data: {
      text: 'FreeMsg>FAV XMAS TONES!Reply REAL',
    },
    score: 0.889,
  },
  {
    data: {
      text: "Good morning pookie pie! Lol hope I didn't wake u up",
    },
    score: 0.242,
  },
  {
    data: {
      text: 'Yo guess what I just dropped',
    },
    score: 0.115,
  },
  {
    data: {
      text: 'Do you know where my lab goggles went',
    },
    score: 0.052,
  },
  {
    data: {
      text: 'I attended but nothing is there.',
    },
    score: 0.18,
  },
  {
    data: {
      text: "E admin building there? I might b slightly earlier... I'll call u when i'm reaching...",
    },
    score: 0.311,
  },
  {
    data: {
      text: 'I keep ten rs in my shelf:) buy two egg.',
    },
    score: 0.169,
  },
  {
    data: {
      text: 'And miss vday the parachute and double coins??? U must not know me very well...',
    },
    score: 0.174,
  },
  {
    data: {
      text: 'If i let you do this, i want you in the house by 8am.',
    },
    score: 0.216,
  },
  {
    data: {
      text: "How's it going? Got any exciting karaoke type activities planned? I'm debating whether to play football this eve. Feeling lazy though.",
    },
    score: 0.199,
  },
  {
    data: {
      text: 'Tessy..pls do me a favor. Pls convey my birthday wishes to Nimya..pls dnt forget it. Today is her birthday Shijas',
    },
    score: 0.309,
  },
  {
    data: {
      text: "8 at the latest, g's still there if you can scrounge up some ammo and want to give the new ak a try",
    },
    score: 0.043,
  },
  {
    data: {
      text: 'Geeeee ... Your internet is really bad today, eh ?',
    },
    score: 0.016,
  },
  {
    data: {
      text: 'Yep, by the pretty sculpture',
    },
    score: 0.073,
  },
  {
    data: {
      text: "I can't describe how lucky you are that I'm actually awake by noon",
    },
    score: 0.014,
  },
  {
    data: {
      text: "hey, looks like I was wrong and one of the kappa guys numbers is still on my phone, if you want I can text him and see if he's around",
    },
    score: 0.113,
  },
] satisfies DataJsonAndScore[];

export default function DemoSetThresholdsScreen(props: {
  policyName: string;
  goToNextScreen: () => void;
  goBack: () => void;
  setTotalContent: (total: number) => void;
  totalContent?: number;
  variant?: string;
  goToSpotTestInput: () => void;
}) {
  const {
    policyName,
    goToNextScreen,
    goBack,
    setTotalContent,
    totalContent,
    variant,
    goToSpotTestInput,
  } = props;

  const fetchRedditExamples = variant === 'reddit';
  const showMarketplaceData = variant === 'marketplace';
  const showCharacterData = variant === 'character';
  const showTwilioData = variant === 'twilio';
  const [thresholds, setThresholds] = useState<Threshold[]>([]);

  const { loading, error, data } = useGQLGetRedditExamplesQuery({
    variables: {
      input: {
        policyName,
      },
    },
    skip: !fetchRedditExamples,
  });
  // If querying Reddit examples errors, or if the query has completed and
  // no examples have been returned, then just use defaultEntries
  const entries = useMemo(() => {
    if (showMarketplaceData) {
      return _.reverse(
        _.sortBy(marketplaceSamplesForThresholding, 'score'),
      ) satisfies DataJsonAndScore[];
    }
    if (showCharacterData) {
      return _.reverse(
        _.sortBy(characterSamplesForThresholding(policyName), 'score'),
      ) satisfies DataJsonAndScore[];
    }
    if (showTwilioData) {
      return _.reverse(
        _.sortBy(twilioSamples, 'score'),
      ) satisfies DataJsonAndScore[];
    }
    if (
      !fetchRedditExamples ||
      error ||
      (!loading &&
        data?.getRedditExamplesForThresholdScreen?.items != null &&
        data.getRedditExamplesForThresholdScreen.items.length === 0)
    ) {
      return defaultEntries;
    }

    const items = data?.getRedditExamplesForThresholdScreen?.items;
    if (!items || items.length < 19) {
      return defaultEntries;
    }
    return _.reverse(
      _.sortBy(
        _.uniqBy(
          items.map((item) => ({
            data: { text: item.text },
            score: item.score,
          })) ?? [],
          (item) => item.data.text,
        ),
        'score',
      ),
    ) satisfies DataJsonAndScore[];
  }, [
    showMarketplaceData,
    showCharacterData,
    showTwilioData,
    fetchRedditExamples,
    error,
    loading,
    data?.getRedditExamplesForThresholdScreen.items,
    policyName,
  ]);

  const showActions = variant !== 'twilio';

  return (
    <div className="flex flex-col items-center w-full text-start">
      <div className="flex flex-col items-center m-8">
        <div className={showActions ? `w-3/5` : 'w-full'}>
          <div className="flex justify-between w-full max-w-4xl gap-8">
            <div className="text-2xl font-semibold">
              {showActions
                ? `Drag and drop thresholds for your ${policyName} policy`
                : 'Model Preview'}
              {showActions ? null : (
                <div className="pt-4 text-base font-normal text-slate-500">
                  See how the model performs on real data from your platform.
                  Drag and drop the thresholds to understand how much content
                  would fall into each bucket based on where you draw the lines.
                </div>
              )}
            </div>
            {showActions ? null : (
              <DemoPrimaryButton
                title="Test model"
                onClick={goToSpotTestInput}
              />
            )}
          </div>
          {showActions ? (
            <DemoSetThresholdsExplanationComponent
              totalContent={totalContent}
              setTotalContent={setTotalContent}
            />
          ) : (
            <div className="mt-6">
              <DemoInputDailyContentTotal
                totalContent={totalContent}
                setTotalContent={setTotalContent}
              />
            </div>
          )}
        </div>
        <div className="flex flex-col w-full py-8 pt-16">
          {loading ? (
            <div className="py-6">
              <ComponentLoading />
            </div>
          ) : (
            <DemoSetThresholdsDragAndDropComponent
              entries={getEntryFromDataJsonAndScore(entries)}
              thresholds={thresholds}
              setThresholds={setThresholds}
              totalContent={totalContent!}
              showActions={showActions}
            />
          )}
        </div>
      </div>
      <DemoFooter
        primaryButton={{ title: 'Continue', onClick: goToNextScreen }}
        onBack={goBack}
      />
    </div>
  );
}
