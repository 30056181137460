import { Label, Switch } from '@/cove-ui';
import { useGQLPersonalSafetySettingsQuery } from '@/graphql/generated';
import { useCallback, useEffect, useState } from 'react';

import FullScreenLoading from '@/components/common/FullScreenLoading';

import { type BlurStrength } from './v2/ncmec/NCMECMediaViewer';

export default function NotionPageDisplayComponent(props: {
  notionUrl: string;
}) {
  const { notionUrl } = props;

  const notionProxyUrl =
    process.env.NODE_ENV === 'production'
      ? 'https://notion.getcove.com/'
      : 'http://localhost:4000';

  const [state, setState] = useState<{
    blur: boolean;
    grayscale: boolean;
    shouldTranslate: boolean;
  }>({
    blur: true,
    grayscale: false,
    shouldTranslate: false,
  });

  const { blur, grayscale, shouldTranslate } = state;

  const { loading, data } = useGQLPersonalSafetySettingsQuery();
  const {
    moderatorSafetyBlurLevel = 2 as BlurStrength,
    moderatorSafetyGrayscale = true,
  } = data?.me?.interfacePreferences ?? {};

  useEffect(() => {
    setState({
      blur: moderatorSafetyBlurLevel !== 0,
      grayscale: moderatorSafetyGrayscale,
      shouldTranslate: false,
    });
  }, [moderatorSafetyBlurLevel, moderatorSafetyGrayscale]);

  const handleIframeLoad = useCallback(
    function (elm: HTMLIFrameElement | null) {
      function sendMessage() {
        elm!.contentWindow?.postMessage(
          {
            blur: blur ? moderatorSafetyBlurLevel : 0,
            grayscale,
            shouldTranslate,
          },
          { targetOrigin: notionProxyUrl },
        );
      }

      if (elm) {
        try {
          sendMessage();
        } catch (e) {
          console.log('error sending message', e);
          setTimeout(sendMessage, 1000);
          // the iframe is redirecting, which changes its origin, so we retry
        }
      }
    },
    [
      blur,
      moderatorSafetyBlurLevel,
      grayscale,
      shouldTranslate,
      notionProxyUrl,
    ],
  );

  if (loading) {
    return <FullScreenLoading />;
  }

  const url = `${notionProxyUrl}?notionUrl=${notionUrl}`;

  return (
    <div className="flex flex-col p-2">
      <div className="flex flex-row items-center self-end gap-4 mb-2">
        <div className="flex items-center space-x-2">
          <Switch
            id="translate-to-english"
            defaultChecked={shouldTranslate}
            onCheckedChange={(value) => {
              setState({ ...state, shouldTranslate: value });
            }}
            checked={shouldTranslate}
          />
          <Label htmlFor="translate-to-english">Translate to English</Label>
        </div>
      </div>
      <iframe
        ref={handleIframeLoad}
        title="doc"
        src={url}
        className="w-full h-full min-h-[800px] p-0 border border-solid border-slate-200 rounded"
      />
    </div>
  );
}
