import { type ScalarTypeRuntimeType } from '@protego-api/types';
import { isPlainObject } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import ReactAudioPlayer from 'react-audio-player';

import ComponentLoading from '../../../../../components/common/ComponentLoading';

import { useGQLItemTypeHiddenFieldsQuery } from '../../../../../graphql/generated';
import { __throw, assertUnreachable } from '../../../../../utils/misc';
import { parseDatetimeToReadableStringInCurrentTimeZone } from '../../../../../utils/time';
import {
  type ItemTypeFieldFieldData,
  type ItemTypeScalarFieldData,
} from '../../../item_types/itemTypeUtils';
import ManualReviewJobContentBlurableImage from '../../../mrt/manual_review_job/ManualReviewJobContentBlurableImage';
import ManualReviewJobContentBlurableVideo from '../../../mrt/manual_review_job/ManualReviewJobContentBlurableVideo';
import { stringPathToJsonPointer } from '../screens/labeling/trainingPipelineLabelingUtils';

type SelectableFieldComponentOptions = {
  hideLabels?: boolean;
  unblurAllMedia?: boolean;
  grayscale?: boolean;
  expandAll?: boolean;
  dangerouslySetInnerHTML?: boolean;
  twoColumns?: boolean;
};

function TrainingPipelineFieldValueComponent(props: {
  data: ItemTypeScalarFieldData;
  label?: string;
  itemTypes: readonly { id: string; __typename: string }[];
  selected: boolean;
  onClick: () => void;
  contentRef: React.RefObject<HTMLDivElement>;
  unblurAllMedia: boolean;
  grayscale?: boolean;
  expanded?: boolean;
  dangerouslySetInnerHTML?: boolean;
}) {
  const {
    data,
    itemTypes,
    label,
    selected,
    onClick,
    contentRef,
    unblurAllMedia,
    grayscale = false,
    expanded = false,
  } = props;
  const [error, setError] = useState<boolean>(false);

  if (data.value == null) {
    return <div className="italic">Value not provided</div>;
  }

  const fieldValueComponent = (() => {
    switch (data.type) {
      case 'AUDIO': {
        return (
          <div className="flex flex-col px-2 align-top text-start">
            {label ? <div className="pr-3 font-bold">{label}</div> : null}
            <ReactAudioPlayer src={data.value.url} autoPlay controls />
          </div>
        );
      }
      case 'BOOLEAN':
        return (
          <div className="font-light">{data.value ? 'True' : 'False'}</div>
        );
      case 'DATETIME':
        return (
          <div className="font-light">
            {parseDatetimeToReadableStringInCurrentTimeZone(data.value)}
          </div>
        );
      case 'GEOHASH':
      case 'ID':
      case 'NUMBER':
      case 'POLICY_ID':
      // TODO: We should fetch the policy name instead of showing the ID since
      // users won't know what policy a given ID refers to
      case 'STRING':
        return props.dangerouslySetInnerHTML ? (
          <div
            className="font-light"
            dangerouslySetInnerHTML={{
              __html: (data.value as string).replace(/\\n/g, '<br>'),
            }}
          />
        ) : (
          <div className="font-light">{String(data.value)}</div>
        );
      case 'USER_ID':
        return (
          <a
            className="cursor-pointer shrink-0"
            href={`/dashboard/investigation?id=${data.value.id}&typeId=${data.value.typeId}`}
            target="_blank"
            rel="noreferrer"
            onClick={(event) => event.stopPropagation()}
          >
            {data.value.id}
          </a>
        );
      case 'IMAGE':
        return (
          <ManualReviewJobContentBlurableImage
            url={data.value.url}
            options={{
              shouldBlur: !unblurAllMedia,
              blurStrength: unblurAllMedia ? (0 as const) : (2 as const),
              grayscale,
              disableZoom: true,
            }}
            onError={() => setError(true)}
          />
        );
      case 'RELATED_ITEM': {
        const { value } = data;
        const relatedItemKind = itemTypes.find(
          (itemType) => itemType.id === value.typeId,
        )?.__typename;
        if (!relatedItemKind) {
          __throw(new Error(`Could not find item type for ID ${value.typeId}`));
        }
        return (
          <a
            href={`/dashboard/investigation?id=${value.id}&typeId=${value.typeId}`}
            className="cursor-pointer text-start shrink-0"
            target="_blank"
            rel="noreferrer"
            onClick={(event) => event.stopPropagation()}
          >
            {value.name ?? value.id}
          </a>
        );
      }
      case 'URL':
        return (
          <a
            className="cursor-pointer shrink-0"
            href={data.value}
            target="_blank"
            rel="noreferrer"
            onClick={(event) => event.stopPropagation()}
          >
            {data.value}
          </a>
        );
      case 'VIDEO':
        return (
          <ManualReviewJobContentBlurableVideo
            url={data.value.url}
            options={{
              shouldBlur: !unblurAllMedia,
              blurStrength: unblurAllMedia ? (0 as const) : (2 as const),
              muted: true,
            }}
          />
        );
      default:
        assertUnreachable(data);
    }
  })();

  const getClassName = (error: boolean, selected: boolean) =>
    `text-sm ${
      error
        ? '!whitespace-wrap'
        : `border border-solid rounded-md cursor-pointer ${
            selected
              ? 'border-red-500 active:border-red-700'
              : 'border-transparent hover:border-gray-200 active:border-red-700'
          }`
    }`;

  return (
    <div className={getClassName(error, selected)} onClick={onClick}>
      <div className={`m-2 ${expanded ? '' : 'line-clamp-3'}`}>
        {label && label}
        <div ref={contentRef}>{fieldValueComponent}</div>
      </div>
    </div>
  );
}

function TrainingPipelineCollapsableFieldComponent(props: {
  data: ItemTypeScalarFieldData;
  label?: string;
  itemTypes: readonly { id: string; __typename: string }[];
  selected: boolean;
  onClick: () => void;
  unblurAllMedia: boolean;
  grayscale?: boolean;
  expandAll?: boolean;
  dangerouslySetInnerHTML?: boolean;
}) {
  const {
    data,
    itemTypes,
    label,
    selected,
    onClick,
    unblurAllMedia,
    grayscale,
    expandAll,
  } = props;

  const [expanded, setExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);

  if (!isTruncated && expanded) {
    throw Error(
      'Invalid state: expanded should be false when content is not truncated',
    );
  }

  useEffect(() => {
    const element = textRef.current;
    if (element) {
      setIsTruncated(element.scrollHeight > element.clientHeight);
    }
  }, [data.value]);

  return (
    <div className="flex flex-col">
      <TrainingPipelineFieldValueComponent
        data={data}
        label={label}
        itemTypes={itemTypes}
        selected={selected}
        onClick={onClick}
        contentRef={textRef}
        unblurAllMedia={unblurAllMedia}
        expanded={expanded || expandAll}
        grayscale={grayscale}
        dangerouslySetInnerHTML={props.dangerouslySetInnerHTML}
      />
      {isTruncated && (
        <div
          className="self-end text-sm font-bold cursor-pointer text-slate-500"
          onMouseDown={(event) => event.stopPropagation()}
          onClick={(event) => {
            setExpanded(!expanded);
            event.stopPropagation();
          }}
        >
          {expanded ? 'Show Less' : 'Show More'}
        </div>
      )}
    </div>
  );
}

function TrainingPipelineFieldComponent(props: {
  data: ItemTypeFieldFieldData;
  itemTypes: readonly { id: string; __typename: string }[];
  selectedFieldJsonPointers: string[];
  onSelectField: (opts: { fieldJsonPointer: string }) => void;
  options?: SelectableFieldComponentOptions;
}) {
  const { data, itemTypes, selectedFieldJsonPointers, onSelectField, options } =
    props;

  switch (data.type) {
    case 'ARRAY':
    case 'MAP':
      return (
        <TrainingPipelineContainerComponent
          field={data}
          itemTypes={itemTypes}
          selectedFieldJsonPointers={selectedFieldJsonPointers}
          onSelectField={onSelectField}
          options={options}
        />
      );
    case 'AUDIO':
    case 'BOOLEAN':
    case 'DATETIME':
    case 'GEOHASH':
    case 'ID':
    case 'IMAGE':
    case 'NUMBER':
    case 'POLICY_ID':
    case 'RELATED_ITEM':
    case 'STRING':
    case 'URL':
    case 'USER_ID':
    case 'VIDEO': {
      const fieldJsonPointer = stringPathToJsonPointer([data.name]);
      return (
        <div className="flex flex-col">
          {!options?.hideLabels && (
            <div className="mb-2 text-sm font-semibold text-slate-500">
              {data.name}
            </div>
          )}
          <TrainingPipelineCollapsableFieldComponent
            data={data}
            itemTypes={itemTypes}
            selected={selectedFieldJsonPointers.includes(fieldJsonPointer)}
            onClick={() => onSelectField({ fieldJsonPointer })}
            unblurAllMedia={options?.unblurAllMedia ?? false}
            grayscale={options?.grayscale ?? false}
            expandAll={options?.expandAll ?? false}
          />
        </div>
      );
    }
    default:
      assertUnreachable(data);
  }
}

function TrainingPipelineContainerComponent(props: {
  field: ItemTypeFieldFieldData;
  selectedFieldJsonPointers: string[];
  onSelectField: (opts: { fieldJsonPointer: string }) => void;
  itemTypes: readonly { id: string; __typename: string }[];
  options?: SelectableFieldComponentOptions;
}) {
  const {
    field,
    selectedFieldJsonPointers,
    onSelectField,
    itemTypes,
    options,
  } = props;

  const items = (() => {
    if (field.value == null) {
      return [];
    }

    switch (field.type) {
      case 'ARRAY': {
        if (!Array.isArray(field.value)) {
          __throw(new Error('Data.value incorrectly assumed to be an array'));
        }

        return field.value.map((it, idx) => ({
          fieldData: {
            type: field.container.valueScalarType,
            value: it,
          } as ItemTypeScalarFieldData,
          label: (idx + 1).toString(),
          key: idx.toString(),
        }));
      }
      case 'MAP': {
        const mapValue = field.value as {
          [key: string]: ScalarTypeRuntimeType;
        };
        return isPlainObject(mapValue)
          ? Object.keys(mapValue).map((key) => ({
              fieldData: {
                value: mapValue[key],
                type: field.container.valueScalarType,
              } as ItemTypeScalarFieldData,
              label: key,
              key,
            }))
          : __throw(new Error('Data.value incorrectly assumed to be a map'));
      }
      case 'AUDIO':
      case 'BOOLEAN':
      case 'DATETIME':
      case 'GEOHASH':
      case 'ID':
      case 'IMAGE':
      case 'NUMBER':
      case 'POLICY_ID':
      case 'RELATED_ITEM':
      case 'STRING':
      case 'URL':
      case 'USER_ID':
      case 'VIDEO':
        throw Error('Cannot call container component with scalar field');
      default:
        assertUnreachable(field);
    }
  })();

  return (
    <div className="flex flex-col gap-2">
      {!options?.hideLabels && (
        <div className="mb-2 text-sm font-semibold text-slate-500">
          {field.name}
        </div>
      )}
      <div className="grid grid-cols-3 gap-2">
        {items.map((it, i) => {
          const fieldJsonPointer = stringPathToJsonPointer([
            field.name,
            it.key,
          ]);
          return (
            <TrainingPipelineCollapsableFieldComponent
              key={i}
              label={it.label}
              data={it.fieldData}
              itemTypes={itemTypes}
              selected={selectedFieldJsonPointers.includes(fieldJsonPointer)}
              onClick={() => onSelectField({ fieldJsonPointer })}
              unblurAllMedia={options?.unblurAllMedia ?? false}
              grayscale={options?.grayscale ?? false}
              expandAll={options?.expandAll ?? false}
              dangerouslySetInnerHTML={options?.dangerouslySetInnerHTML}
            />
          );
        })}
      </div>
    </div>
  );
}

export default function TrainingPipelineSelectableFieldsComponent(props: {
  fields: ItemTypeFieldFieldData[];
  selectedFieldJsonPointers: string[];
  onChangeSelectedFields: (fields: string[]) => void;
  itemTypeId?: string;
  options?: SelectableFieldComponentOptions;
}) {
  const {
    fields,
    selectedFieldJsonPointers,
    onChangeSelectedFields,
    itemTypeId,
    options,
  } = props;

  const { data, loading } = useGQLItemTypeHiddenFieldsQuery();

  if (loading) {
    return <ComponentLoading />;
  }

  if (fields.length === 0 || !fields.some((field) => field.value != null)) {
    return null;
  }

  const { itemTypes } = data?.myOrg ?? { itemTypes: [] };
  const hiddenFields =
    itemTypes.find((it) => it.id === itemTypeId)?.hiddenFields ?? [];

  return options?.twoColumns ? (
    <div className="flex flex-row w-full p-0 gap-3">
      <div className="flex flex-col w-1/2 p-0 gap-3">
        {fields
          .filter((it) => !hiddenFields.includes(it.name))
          // NB: Filters could be combined but this is more readable
          .filter(
            (it) =>
              it.value !== undefined &&
              (!Array.isArray(it.value) || it.value.length > 0) &&
              (!isPlainObject(it.value) ||
                Object.values(it.value).some((val) => val !== undefined)) &&
              it.type !== 'IMAGE' &&
              it.type !== 'VIDEO',
          )
          .map((field) => (
            <TrainingPipelineFieldComponent
              data={field}
              key={field.name}
              itemTypes={itemTypes}
              selectedFieldJsonPointers={selectedFieldJsonPointers}
              onSelectField={({ fieldJsonPointer }) => {
                if (selectedFieldJsonPointers.includes(fieldJsonPointer)) {
                  onChangeSelectedFields(
                    selectedFieldJsonPointers.filter(
                      (it) => it !== fieldJsonPointer,
                    ),
                  );
                } else {
                  onChangeSelectedFields([
                    ...selectedFieldJsonPointers,
                    fieldJsonPointer,
                  ]);
                }
              }}
              options={options}
            />
          ))}
      </div>
      <div className="flex flex-col w-1/4 p-0 gap-3">
        {fields
          .filter((it) => !hiddenFields.includes(it.name))
          // NB: Filters could be combined but this is more readable
          .filter(
            (it) =>
              it.value !== undefined &&
              (!Array.isArray(it.value) || it.value.length > 0) &&
              (!isPlainObject(it.value) ||
                Object.values(it.value).some((val) => val !== undefined)) &&
              (it.type === 'IMAGE' || it.type === 'VIDEO'),
          )
          .map((field) => (
            <TrainingPipelineFieldComponent
              data={field}
              key={field.name}
              itemTypes={itemTypes}
              selectedFieldJsonPointers={selectedFieldJsonPointers}
              onSelectField={({ fieldJsonPointer }) => {
                if (selectedFieldJsonPointers.includes(fieldJsonPointer)) {
                  onChangeSelectedFields(
                    selectedFieldJsonPointers.filter(
                      (it) => it !== fieldJsonPointer,
                    ),
                  );
                } else {
                  onChangeSelectedFields([
                    ...selectedFieldJsonPointers,
                    fieldJsonPointer,
                  ]);
                }
              }}
              options={options}
            />
          ))}
      </div>
    </div>
  ) : (
    <div className="flex flex-col w-full p-0 gap-3">
      {fields
        .filter((it) => !hiddenFields.includes(it.name))
        // NB: Filters could be combined but this is more readable
        .filter(
          (it) =>
            it.value !== undefined &&
            (!Array.isArray(it.value) || it.value.length > 0) &&
            (!isPlainObject(it.value) ||
              Object.values(it.value).some((val) => val !== undefined)),
        )
        .map((field) => (
          <TrainingPipelineFieldComponent
            data={field}
            key={field.name}
            itemTypes={itemTypes}
            selectedFieldJsonPointers={selectedFieldJsonPointers}
            onSelectField={({ fieldJsonPointer }) => {
              if (selectedFieldJsonPointers.includes(fieldJsonPointer)) {
                onChangeSelectedFields(
                  selectedFieldJsonPointers.filter(
                    (it) => it !== fieldJsonPointer,
                  ),
                );
              } else {
                onChangeSelectedFields([
                  ...selectedFieldJsonPointers,
                  fieldJsonPointer,
                ]);
              }
            }}
            options={options}
          />
        ))}
    </div>
  );
}
