import { m } from 'framer-motion';

export function CompilianceToolkit() {
  return (
    <div className="flex flex-col w-full max-w-3xl gap-0">
      <div className="flex w-full mb-6 ml-4 gap-6 xl:ml-8">
        <m.img
          className="object-scale-down h-9 lg:h-11"
          initial={{ y: 10, opacity: 0 }}
          whileInView={{ y: 0, opacity: 100 }}
          viewport={{ once: true, amount: 0.25 }}
          transition={{ duration: 0.5, delay: 0.5 }}
          src="/graphics/dsa-full.png"
        />
        <m.img
          className="object-scale-down h-9 lg:h-11"
          initial={{ y: 10, opacity: 0 }}
          whileInView={{ y: 0, opacity: 100 }}
          viewport={{ once: true, amount: 0.25 }}
          transition={{ duration: 0.5, delay: 1 }}
          src="/graphics/osa.png"
        />
      </div>
      <m.img
        initial={{ y: 10, opacity: 0 }}
        whileInView={{ y: 0, opacity: 100 }}
        viewport={{ once: true, amount: 0.25 }}
        transition={{ duration: 0.5, delay: 1.5 }}
        src="/graphics/compliance-toolkit.png"
      />
    </div>
  );
}
