import { gql } from '@apollo/client';
import { Button } from 'antd';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';

import CoveModal from '../dashboard/components/CoveModal';
import DashboardHeader from '../dashboard/components/DashboardHeader';

import { useGQLCancelSubscriptionMutation } from '../../graphql/generated';

gql`
  mutation CancelSubscription {
    cancelSubscription
  }
`;

export default function BillingSettings() {
  const [modalVisible, setModalVisible] = useState(false);
  const hideModal = () => setModalVisible(false);

  const [cancelSubscription, { loading, data, error }] =
    useGQLCancelSubscriptionMutation({
      onError: () => setModalVisible(true),
      onCompleted: () => setModalVisible(true),
    });

  const {
    title = '',
    body = null,
    footer = [],
  } = (() => {
    if (error) {
      return {
        title: 'Error Canceling Subscription',
        body: 'We encountered an error trying to cancel your subscription. Please try again.',
        footer: [
          {
            title: 'Cancel',
            onClick: hideModal,
            type: 'secondary' as const,
          },
          {
            title: 'OK',
            onClick: hideModal,
          },
        ],
      };
    } else if (Boolean(data?.cancelSubscription)) {
      return {
        title: 'Subscription Canceled',
        body: 'Your subscription has been canceled. We hope you come back soon!',
        footer: [
          {
            title: 'Done',
            onClick: hideModal,
          },
        ],
      };
    } else {
      return {
        title: 'Are you sure?',
        body:
          'If you cancel your subscription, we will immediately stop charging you. If ' +
          'you are past your trial period and have incurred fees for the current ' +
          'month, and have not yet paid your balance, we will send you an invoice for ' +
          'that remaining balance.',
        footer: [
          {
            title: 'Cancel Subscription',
            onClick: async () => cancelSubscription(),
            type: 'danger' as const,
          },
          {
            title: 'Cancel',
            onClick: hideModal,
            type: 'secondary' as const,
          },
        ],
      };
    }
  })();

  const modal = (
    <CoveModal title={title} visible={modalVisible} footer={footer}>
      <div className="max-w-2xl">{body}</div>
    </CoveModal>
  );

  return (
    <div className="flex flex-col w-3/5 text-start">
      <Helmet>
        <title>Billing </title>
      </Helmet>
      <DashboardHeader
        title="Billing"
        subtitle="For now, we will send monthly invoices to the email you specified as your company's contact. Invoices during trial periods will be sent on the first day of each month, and invoices after trial periods will be sent on the last day of each month. To cancel your Cove subscription, click the button below. Upon canceling, we will immediately stop processing content you're sending to us."
      />
      <div>
        <Button
          className="flex mt-4 font-medium rounded-lg"
          danger
          type="primary"
          onClick={() => setModalVisible(true)}
          loading={loading}
        >
          Cancel Subscription
        </Button>
      </div>
      {modal}
    </div>
  );
}
