import { Button } from '@/cove-ui';
import {
  useGQLCreateExistingCoveModelSamplingJobMutation,
  useGQLGetItemTypesByIdentifiersLazyQuery,
  useGQLGetSamplingJobResultsLazyQuery,
  useGQLLatestSuccessfulSamplingJobQuery,
} from '@/graphql/generated';
import {
  ArrowDownFilled,
  ArrowUpFilled,
  EraserAltFilled,
  WarningFilled,
} from '@/icons';
import { filterNullOrUndefined } from '@/utils/collections';
import { getPrimaryContentFields } from '@/utils/itemUtils';
import { gql } from '@apollo/client';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import DashboardHeader from '../components/DashboardHeader';
import ComponentLoading from '@/components/common/ComponentLoading';
import FullScreenLoading from '@/components/common/FullScreenLoading';

import FieldsComponent from '../mrt/manual_review_job/v2/ManualReviewJobFieldsComponent';

gql`
  query LatestSuccessfulSamplingJob(
    $modelId: String!
    $modelVersion: Int!
    $samplingStrategy: SamplingStrategyInput!
  ) {
    getLatestSuccessfulSamplingJobResultForModel(
      modelId: $modelId
      modelVersion: $modelVersion
      samplingStrategy: $samplingStrategy
    ) {
      ... on SamplingJobSuccess {
        samples {
          item {
            itemId
            itemType {
              id
              version
              schemaVariant
            }
            data
          }
          score
          additionalModelScore {
            score
          }
        }
      }
    }
  }

  mutation CreateExistingCoveModelSamplingJob(
    $input: CreateExistingCoveModelSamplingJobInput!
  ) {
    createExistingCoveModelSamplingJob(input: $input) {
      jobId
    }
  }
`;

export default function PreviewModel() {
  const { policyId, modelVersion, modelId } = useParams<{
    policyId: string;
    modelVersion: string;
    modelId: string;
  }>();

  const [searchParams] = useSearchParams();
  const jobIdFromSearch = searchParams.get('jobId');

  const [jobId, setJobId] = useState<string | null>(jobIdFromSearch);
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [labelMap, setLabelMap] = useState<Record<string, boolean>>({});
  if (
    policyId === undefined ||
    modelVersion === undefined ||
    modelId === undefined
  ) {
    throw new Error('Policy name, model version and model ID is required');
  }

  const modelVersionInt = parseInt(modelVersion);

  if (modelVersionInt === undefined) {
    throw new Error('Model version is required');
  }

  const samplingStrategy = {
    modelScoreSamplingStrategy: {
      score: {
        min: 0,
        max: 1,
      },
    },
  };

  const { data, loading } = useGQLLatestSuccessfulSamplingJobQuery({
    variables: { modelId, modelVersion: modelVersionInt, samplingStrategy },
  });

  const [getItemTypes, { data: itemTypesData }] =
    useGQLGetItemTypesByIdentifiersLazyQuery();

  const [startSamplingJob] = useGQLCreateExistingCoveModelSamplingJobMutation({
    onCompleted: (response) => {
      const jobId = response.createExistingCoveModelSamplingJob.jobId;

      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set('jobId', jobId);
      setJobId(jobId);
      window.history.replaceState(
        null,
        '',
        `${window.location.pathname}?${queryParams.toString()}`,
      );
    },
  });

  const [getSamplingJobResults, { data: samplingJobResultsData, stopPolling }] =
    useGQLGetSamplingJobResultsLazyQuery({
      variables: {
        jobId: jobId!,
      },
      pollInterval: 5000,
      onCompleted: (response) => {
        if (
          response.getSamplingJobResults.__typename === 'SamplingJobFailure'
        ) {
          stopPolling();
        } else if (
          response.getSamplingJobResults.__typename ===
          'SamplingJobNotFoundError'
        ) {
          stopPolling();
        }
      },
    });

  useEffect(() => {
    if (data?.getLatestSuccessfulSamplingJobResultForModel != null || loading) {
      return;
    }
    if (jobId === null) {
      startSamplingJob({
        variables: {
          input: {
            modelId,
            modelVersion: modelVersionInt,
            numSamples: 200,
            samplingStrategy,
          },
        },
      });
    } else {
      getSamplingJobResults();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data?.getLatestSuccessfulSamplingJobResultForModel,
    getSamplingJobResults,
    jobId,
    loading,
    modelId,
    modelVersionInt,
    startSamplingJob,
  ]);

  const samples =
    data?.getLatestSuccessfulSamplingJobResultForModel?.samples ??
    (samplingJobResultsData?.getSamplingJobResults.__typename ===
      'SamplingJobSuccess' &&
      samplingJobResultsData.getSamplingJobResults.samples);
  // Once we've finished fetching samples, set it to the allSamples state and
  // fetch the relevant item type versions
  useEffect(() => {
    if (samples && samples.length > 0) {
      getItemTypes({
        variables: {
          identifiers: _.uniqBy(
            samples.map((it) => ({
              id: it.item.itemType.id,
              version: it.item.itemType.version,
              schemaVariant: 'ORIGINAL',
            })),
            (it) => `${it.id}-${it.version}`,
          ),
        },
      });
    }
  }, [getItemTypes, samples]);

  if (loading) {
    return <FullScreenLoading />;
  }

  if (!samples) {
    // TODO(huynick): Fill in Null State
    return (
      <div>
        <ComponentLoading />
      </div>
    );
  }
  const itemTypes = itemTypesData?.itemTypes;
  const sortedSamples = filterNullOrUndefined(
    samples.toSorted((a, b) => {
      if (a.score == null) {
        return 1;
      }
      if (b.score == null) {
        return -1;
      }
      return b.score - a.score;
    }),
  );

  return (
    <div>
      <DashboardHeader
        title="Preview Model"
        rightComponent={
          <Button onClick={() => {}}>Save Mistakes and Retrain</Button>
        }
      />
      <div className="text-sm text-slate-400">
        most likely to violate policy
      </div>
      <div className="flex flex-row">
        <div className="pr-0.5 mt-4 mr-7 arrow-line" />
        <div className="flex flex-col max-w-full gap-8 mt-8">
          {sortedSamples.map((sample) => {
            if (sample.score == null) {
              return undefined;
            }
            const itemType = itemTypes?.find(
              (it) => sample.item.itemType.id === it.id,
            );
            const primaryFields = getPrimaryContentFields(
              itemType?.baseFields ?? [],
              sample.item.data,
            ).filter(
              (it) =>
                it.value != null &&
                !(Array.isArray(it.value) && it.value.length === 0),
            );
            const isSelected = selectedItemId === sample.item.itemId;
            const violatesButton = (
              <Button
                variant="link"
                startIcon={ArrowUpFilled}
                onClick={() => {
                  setLabelMap({
                    ...labelMap,
                    [sample.item.itemId]: true,
                  });
                }}
              >
                Violates Policy
              </Button>
            );
            const doesNotViolateButton = (
              <Button
                variant="link"
                startIcon={ArrowDownFilled}
                onClick={() => {
                  setLabelMap({
                    ...labelMap,
                    [sample.item.itemId]: false,
                  });
                }}
              >
                Does Not Violate Policy
              </Button>
            );
            const itemId = sample.item.itemId;
            const clearButton = (
              <Button
                variant="link"
                onClick={() => {
                  const { [itemId]: _, ...updatedLabelMap } = labelMap; // Destructure and omit the itemId
                  setLabelMap(updatedLabelMap); // Update state with new object without itemId
                }}
                startIcon={EraserAltFilled}
              >
                Clear Selection
              </Button>
            );
            return (
              <div key={sample.item.itemId} className="flex flex-col">
                <div className="font-sm text-slate-400">
                  {(sample.score * 100).toFixed(3)}%
                </div>
                <div
                  onClick={() =>
                    isSelected
                      ? setSelectedItemId(null)
                      : setSelectedItemId(sample.item.itemId)
                  }
                  className={`bg-white p-4 mt-2 w-full rounded cursor-pointer ${
                    isSelected ? `border-primary` : `hover:border-primary/50`
                  } border`}
                >
                  <FieldsComponent
                    fields={primaryFields}
                    itemTypeId={sample.item.itemType.id}
                    options={{ transparentBackground: true }}
                  />
                </div>
                {isSelected || sample.item.itemId in labelMap ? (
                  sample.item.itemId in labelMap ? (
                    <div className="flex flex-row items-center justify-end pt-2">
                      <div className="flex flex-row items-center justify-center p-2 rounded bg-primary/20">
                        <WarningFilled className="w-4 h-4 mr-2 text-cove-purple fill-cove-purple" />
                        <div className="text-sm text-cove-purple">
                          Marked as{' '}
                          {labelMap[sample.item.itemId]
                            ? 'Violates Policy'
                            : 'Does Not Violates Policy'}
                        </div>
                      </div>
                      {labelMap[sample.item.itemId]
                        ? clearButton
                        : violatesButton}
                      {labelMap[sample.item.itemId]
                        ? doesNotViolateButton
                        : clearButton}
                    </div>
                  ) : (
                    <div className="flex flex-row items-center justify-end pt-2">
                      <div className="text-sm text-slate-400">
                        What should this content have been marked as?
                      </div>
                      {violatesButton}
                      {doesNotViolateButton}
                    </div>
                  )
                ) : undefined}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
