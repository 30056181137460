import { GQLItemRequirement } from '@/graphql/generated';
import { ImageFilled, TextAlignJustifyFilled, VideoAlt } from '@/icons';

export default function ModelModalityIcons(props: {
  itemRequirement: GQLItemRequirement;
}) {
  switch (props.itemRequirement) {
    case GQLItemRequirement.HasText:
      return (
        <TextAlignJustifyFilled className="w-6 h-6 text-gray-500 fill-gray-500" />
      );
    case GQLItemRequirement.HasImages:
      return <ImageFilled className="w-6 h-6 text-gray-500 fill-gray-500" />;
    case GQLItemRequirement.HasTextOrImagesOrVideos:
      return (
        <div className="flex flex-row items-center gap-2">
          <TextAlignJustifyFilled className="w-6 h-6 text-gray-500 fill-gray-500" />
          <ImageFilled className="w-6 h-6 text-gray-500 fill-gray-500" />
          <VideoAlt className="w-6 h-6 text-gray-500 fill-gray-500" />
        </div>
      );
  }
}
