import { Typography } from '../../ui/Typography';

export function Clients() {
  return (
    <section className="container flex flex-col mb-8 center gap-8">
     <Typography variant="label" color="muted" align="center">
       Powering secure experiences for millions
     </Typography>
     <div className="flex w-full flex-row flex-wrap items-center justify-center gap-10 lg:justify-between [&_img]:h-full">
       {['kik', 'yikyak', 'w', 'csm', 'sidechat', 'playback', 'frfr'].map(
         (brand) => (
           <div key={brand} className="h-12">
             <img
               alt={`Brand: ${brand}}`}
               key={brand}
               className="grayscale"
               src={`/brands/${brand}.png`}
             />
           </div>
         ),
       )}
     </div>
    </section>
  );
}
