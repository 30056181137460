import { Checkbox, P } from '@/cove-ui';
import { PlusOutlined } from '@ant-design/icons';
import { gql } from '@apollo/client';
import { Input, Select } from 'antd';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate, useParams } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';

import FullScreenLoading from '../../components/common/FullScreenLoading';
import { selectFilterByLabelOption } from '../dashboard/components/antDesignUtils';
import CoveButton from '../dashboard/components/CoveButton';
import CoveModal from '../dashboard/components/CoveModal';

import {
  GQLUserRole,
  namedOperations,
  useGQLAllOrgsQuery,
  useGQLCreateOrgMutation,
  useGQLInviteUserTokenQuery,
  useGQLSignUpMutation,
} from '../../graphql/generated';
import LogoPurple from '../../images/LogoPurple.png';
import NewOrgForm from './NewOrgForm';

const { Option } = Select;

gql`
  query AllOrgs {
    allOrgs {
      id
      email
      name
    }
  }

  query InviteUserToken($token: String!) {
    inviteUserToken(token: $token) {
      ... on InviteUserTokenSuccessResponse {
        tokenData {
          token
          email
          role
          orgId
        }
      }
      ... on InviteUserTokenExpiredError {
        title
      }
      ... on InviteUserTokenMissingError {
        title
      }
    }
  }

  mutation CreateOrg($input: CreateOrgInput!) {
    createOrg(input: $input) {
      ... on CreateOrgSuccessResponse {
        id
      }
      ... on OrgWithEmailExistsError {
        title
      }
      ... on OrgWithNameExistsError {
        title
      }
    }
  }

  mutation SignUp($input: SignUpInput!) {
    signUp(input: $input) {
      ... on SignUpSuccessResponse {
        data {
          id
        }
      }
      ... on SignUpUserExistsError {
        title
      }
    }
  }
`;

/**
 * Sign Up form component
 */
export default function SignUp() {
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [password, setPassword] = useState<string | undefined>(undefined);
  const [firstName, setFirstName] = useState<string | undefined>(undefined);
  const [lastName, setLastName] = useState<string | undefined>(undefined);
  const [selectedOrgId, setSelectedOrgId] = useState<string | undefined>(
    undefined,
  );
  const [tokenEmail, setTokenEmail] = useState<string | undefined>(undefined);

  const [modalVisible, setModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [errorTitle, setErrorTitle] = useState<string | undefined>(undefined);
  const [errorText, setErrorText] = useState<string | undefined>(undefined);

  const [role, setRole] = useState<GQLUserRole>(GQLUserRole.Admin);
  const [agreedToTerms, setAgreedToTerms] = useState(false);

  const navigate = useNavigate();

  const { loading, error, data } = useGQLAllOrgsQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-and-network',
  });
  const orgs = data?.allOrgs;

  const showErrorModal = (errorOptions: { title?: string; text?: string }) => {
    setErrorTitle(errorOptions.title);
    setErrorText(errorOptions.text);
    setErrorModalVisible(true);
  };

  const [createOrg, { data: createOrgResult, loading: createOrgLoading }] =
    useGQLCreateOrgMutation({
      onError: () => showErrorModal({}),
      onCompleted: (result) => {
        if (result.createOrg.__typename === 'CreateOrgSuccessResponse') {
          setModalVisible(false);
          setSelectedOrgId(result.createOrg.id);
        }
      },
    });

  const [signUp, { loading: signUpLoading }] = useGQLSignUpMutation({
    onError: () => showErrorModal({}),
    onCompleted: (result) => {
      switch (result.signUp.__typename) {
        case 'SignUpSuccessResponse':
          navigate('/dashboard');
          break;
        case 'SignUpUserExistsError':
          showErrorModal({
            title: 'User already exists',
            text: 'This email address is associated with an existing account.',
          });
          break;
      }
    },
  });

  const params = useParams<{ token?: string }>();
  const tokenString = params.token;
  const tokenQueryParams = useGQLInviteUserTokenQuery({
    variables: { token: tokenString ?? '' },
    skip: tokenString == null,
  });
  const token = tokenQueryParams.data?.inviteUserToken;

  /**
   * If the token param was provided, when the INVITE_USER_TOKEN_QUERY
   * has finished, reset the state values to whatever the query returned
   */
  useEffect(() => {
    if (token != null) {
      switch (token.__typename) {
        case 'InviteUserTokenSuccessResponse': {
          const { email, role, orgId } = token.tokenData;
          setEmail(email);
          setTokenEmail(email);
          setRole(role);
          setSelectedOrgId(orgId);
          break;
        }
        case 'InviteUserTokenExpiredError':
          showErrorModal({
            title: token.title,
            text: 'This invite link has expired. Please have your account administrator send another invite.',
          });
          break;
        case 'InviteUserTokenMissingError':
          showErrorModal({
            title: token.title,
            text: 'This invite link is invalid. Please contact your account administrator.',
          });
          break;
      }
    }
  }, [token]);

  const modal = (
    <NewOrgForm
      visible={modalVisible}
      onSubmit={async (org) =>
        createOrg({
          variables: {
            input: org,
          },
          refetchQueries: [namedOperations.Query.AllOrgs],
        })
      }
      confirmLoading={createOrgLoading}
      onCancel={() => setModalVisible(false)}
      error={createOrgResult?.createOrg.__typename}
    />
  );

  const errorModal = (
    <CoveModal
      title={errorTitle ?? 'Something went wrong'}
      visible={errorModalVisible}
      onClose={() => setErrorModalVisible(false)}
    >
      {errorText ??
        'We encountered an issue trying to process your request. Please try again.'}
    </CoveModal>
  );

  const onSignUp = async () => {
    if (orgs == null) {
      return;
    }
    if (
      email == null ||
      password == null ||
      firstName == null ||
      lastName == null ||
      selectedOrgId == null
    ) {
      return;
    }

    signUp({
      variables: {
        input: {
          email,
          password,
          firstName,
          lastName,
          orgId: selectedOrgId,
          role,
          inviteUserToken: tokenString,
          loginMethod: 'PASSWORD',
        },
      },
    });
  };

  const emailInput = (
    <div className="flex flex-col mb-4">
      <div className="mb-1">
        <span className="text-red-500">* </span>Email
      </div>
      <Input
        onChange={(e) => setEmail(e.target.value)}
        value={tokenEmail ?? email}
        disabled={tokenEmail !== undefined}
      />
    </div>
  );

  const passwordInput = (
    <div className="flex flex-col mb-4">
      <div className="mb-1">
        <span className="text-red-500">* </span>Password
      </div>
      <Input.Password onChange={(e) => setPassword(e.target.value)} />
    </div>
  );

  const nameInputs = (
    <div className="flex flex-row justify-between mb-4 gap-2">
      <div className="flex flex-col pr-1 grow">
        <label className="mb-1">
          <span className="text-red-500">* </span>First Name
        </label>
        <Input onChange={(e) => setFirstName(e.target.value)} />
      </div>
      <div className="flex flex-col pl-1 grow">
        <label className="mb-1">
          <span className="text-red-500">* </span>Last Name
        </label>
        <Input onChange={(e) => setLastName(e.target.value)} />
      </div>
    </div>
  );

  const orgSelector = (
    <div className="flex flex-col mb-4">
      <div className="mb-1">
        <span className="text-red-500">* </span>Organization
      </div>
      <Select
        showSearch
        disabled={token != null}
        placeholder="Find Your Organization"
        optionFilterProp="children"
        dropdownMatchSelectWidth={false}
        value={selectedOrgId}
        onChange={(value: string) => setSelectedOrgId(value)}
        filterOption={selectFilterByLabelOption}
        filterSort={(optionA, optionB) => {
          return String(optionA.label)!
            .toLowerCase()
            .localeCompare(String(optionB.label!).toLowerCase());
        }}
        dropdownRender={(menu) => (
          <div>
            {menu}
            <div className="divider" />
            <div
              className="z-10 flex flex-row items-center w-full h-full p-2 cursor-pointer text-primary hover:bg-slate-100"
              onClick={() => setModalVisible(true)}
            >
              <PlusOutlined className="mr-2" /> Create a New Organization
            </div>
          </div>
        )}
      >
        {orgs?.map((org) => (
          <Option key={org.id} value={org.id} label={org.name}>
            {org.name}
          </Option>
        ))}
      </Select>
    </div>
  );

  const agreeToTermsCheckbox = (
    <div className="flex items-center mt-4 mb-8 space-x-2">
      <Checkbox onCheckedChange={setAgreedToTerms} />
      <P>
        I have read, understood, and agree to the{' '}
        <Link
          to="/terms"
          className="font-semibold text-primary hover:text-primary/70"
        >
          Terms of Use
        </Link>{' '}
        and{' '}
        <Link
          to="/privacy"
          className="font-semibold text-primary hover:text-primary/70"
        >
          Privacy Policy
        </Link>{' '}
        provided herein.
      </P>
    </div>
  );

  const validatePassword = (password: string) => {
    return password.length >= 8 && /\d/.test(password);
  };

  const signUpButton = (
    <CoveButton
      onClick={onSignUp}
      loading={signUpLoading}
      title="Sign Up"
      disabled={
        !agreedToTerms ||
        !firstName?.length ||
        !lastName?.length ||
        !email?.length ||
        !password?.length ||
        !validatePassword(password) ||
        !selectedOrgId?.length
      }
      disabledTooltipTitle={(() => {
        if (!email?.length) {
          return 'Email is required.';
        }

        if (!isEmail(email)) {
          return 'Please enter a valid email address.';
        }

        if (!password) {
          return 'Password is required.';
        }

        if (!validatePassword(password)) {
          return 'Password must be at least 8 characters and contain a number.';
        }

        if (!firstName?.length || !lastName?.length) {
          return 'First and last name are required.';
        }

        if (!selectedOrgId?.length) {
          return 'You must select an organization.';
        }

        if (!agreedToTerms) {
          return 'You must agree to the terms of use and privacy policy.';
        }

        return undefined;
      })()}
    />
  );

  if (loading || tokenQueryParams.loading) {
    return <FullScreenLoading />;
  }
  if (error || tokenQueryParams.error) {
    console.log(error);
    console.log(tokenQueryParams.error);
    return <div />;
  }
  if (!orgs || !orgs.length) {
    // Safe to throw here because 'loading' and 'error'
    // have both already been checked above
    throw new Error('No orgs available for selection');
  }

  return (
    <div className="flex flex-col h-full overflow-y-scroll">
      <Helmet>
        <title>Sign Up</title>
      </Helmet>
      <div className="flex flex-col items-center justify-center w-full h-screen mb-0 bg-slate-100">
        <div className="flex flex-col justify-center rounded-2xl w-[500px] m-9 p-12 overflow-scroll border border-slate-200 border-solid">
          <Link to="/" className="flex items-center justify-center w-full my-2">
            <img src={LogoPurple} alt="Logo" width="55" height="70" />
          </Link>
          <div className="py-5 text-2xl font-bold">
            Create your Cove account
          </div>
          {emailInput}
          {passwordInput}
          {nameInputs}
          {orgSelector}
          {agreeToTermsCheckbox}
          {signUpButton}
          <div className="pt-2">
            Already have an account?{' '}
            <Link
              to="/login"
              className="font-semibold text-primary hover:text-primary/70"
            >
              Log In
            </Link>
          </div>
          {modal}
          {errorModal}
        </div>
      </div>
    </div>
  );
}
