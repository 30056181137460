import { DateRangePicker, Select } from '@/cove-ui';
import {
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/cove-ui/Select';
import { useGQLDashboardOrgQuery } from '@/graphql/generated';
import {
  FileExclamationFilled,
  FlowChartAltFilled,
  PieChartAlt1Filled,
  TapFilled,
  UsersFilled,
} from '@/icons';
import { LookbackLength } from '@/utils/time';
import { makeEnumLike } from '@protego-api/types';
import { subDays } from 'date-fns';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';

import DashboardHeader from '../components/DashboardHeader';
import FullScreenLoading from '@/components/common/FullScreenLoading';

import { isMrtOnlyOrg } from '../Dashboard';
import { ChartType } from '../rules/dashboard/visualization/RulesDashboardInsights';
import RuleDashboardInsightsChart from '../rules/dashboard/visualization/rulesDashboardInsightsChart';
import OverviewCard from './OverviewCard';
import OverviewChart from './OverviewChart';
import OverviewTable from './OverviewTable';

export const TimeDivisionOption = makeEnumLike(['HOUR', 'DAY'] as const);
export type TimeDivisionOptions = keyof typeof TimeDivisionOption;
export function getDisplayNameForTimeDivision(
  timeDivision: TimeDivisionOptions,
) {
  switch (timeDivision) {
    case 'HOUR':
      return 'Hourly breakdown';
    case 'DAY':
      return 'Daily breakdown';
  }
}

export default function Overview() {
  const { data, loading, error } = useGQLDashboardOrgQuery();
  const [timeDivision, setTimeDivision] = useState<TimeDivisionOptions>('DAY');
  const [customTimeWindow, setCustomTimeWindow] = useState({
    start: subDays(new Date(), 7),
    end: new Date(),
  });
  if (error) {
    throw error;
  }
  const hasMrtAccess = data?.myOrg?.hasMrtAccess;
  const hasRulesAccess = data?.myOrg?.id ? !isMrtOnlyOrg(data.myOrg.id) : false;

  const totalActionsTakenCard = (
    <OverviewCard
      title="Actions taken"
      icon={TapFilled}
      iconColor="text-teal-300"
      timeWindow={customTimeWindow}
      statistic="TOTAL_ACTIONS"
    />
  );

  const jobsPendingReviewCard = (
    <OverviewCard
      title="Jobs pending review"
      icon={UsersFilled}
      iconColor="text-orange-400"
      timeWindow={customTimeWindow}
      statistic="JOBS_PENDING"
    />
  );

  const topPolicyViolationsCard = (
    <OverviewCard
      title="Top policy violations"
      icon={FileExclamationFilled}
      iconColor="text-amber-400"
      timeWindow={customTimeWindow}
      statistic="VIOLATIONS_PER_POLICY"
    />
  );

  const topRuleMatchesCard = (
    <OverviewCard
      title="Top rules matched"
      icon={FlowChartAltFilled}
      iconColor="text-amber-400"
      timeWindow={customTimeWindow}
      statistic="MATCHES_PER_RULE"
    />
  );

  const automatedVsManualActionsCard = (
    <OverviewCard
      title="Automated vs. manual actions"
      icon={PieChartAlt1Filled}
      iconColor="text-amber-400"
      timeWindow={customTimeWindow}
      statistic="AUTOMATED_VS_MANUAL"
    />
  );

  const cards = (() => {
    if (hasMrtAccess && hasRulesAccess) {
      return [
        totalActionsTakenCard,
        jobsPendingReviewCard,
        automatedVsManualActionsCard,
      ];
    } else if (hasMrtAccess) {
      return [
        totalActionsTakenCard,
        jobsPendingReviewCard,
        topPolicyViolationsCard,
      ];
    } else {
      return [
        totalActionsTakenCard,
        topPolicyViolationsCard,
        topRuleMatchesCard,
      ];
    }
  })();

  const decisionsPerModeratorChart = (
    <OverviewChart
      title="Decisions per moderator"
      icon={UsersFilled}
      iconColor="text-orange-400"
      metric="Decisions"
      timeDivision={timeDivision}
      timeWindow={customTimeWindow}
      chartType={ChartType.BAR}
    />
  );

  const actionsPerRuleChart = (chartType: ChartType.BAR | ChartType.LINE) => (
    <OverviewChart
      title="Actions per rule"
      icon={FlowChartAltFilled}
      iconColor="text-amber-400"
      metric="Actions"
      timeDivision={timeDivision}
      timeWindow={customTimeWindow}
      chartType={chartType}
    />
  );

  const topViolationsByPolicyTable = (
    <OverviewTable
      title="Policy Violations"
      icon={FileExclamationFilled}
      iconColor="text-amber-400"
      groupBy="policy"
      timeWindow={customTimeWindow}
    />
  );

  const charts = (() => {
    if (hasMrtAccess && hasRulesAccess) {
      return [decisionsPerModeratorChart, actionsPerRuleChart(ChartType.LINE)];
    } else if (hasMrtAccess) {
      return [decisionsPerModeratorChart, topViolationsByPolicyTable];
    } else {
      return [actionsPerRuleChart(ChartType.BAR), topViolationsByPolicyTable];
    }
  })();

  return (
    <div className="flex flex-col">
      <Helmet>
        <title>Overview</title>
      </Helmet>
      <DashboardHeader
        title="Overview"
        rightComponent={
          <div className="flex gap-3">
            <Select
              onValueChange={(value) =>
                setTimeDivision(value as TimeDivisionOptions)
              }
              value={timeDivision}
            >
              <SelectTrigger className="w-[180px] bg-white">
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {Object.values(TimeDivisionOption).map((val) => (
                    <SelectItem value={val} key={val}>
                      {getDisplayNameForTimeDivision(val)}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
            <DateRangePicker
              initialDateFrom={customTimeWindow.start}
              initialDateTo={customTimeWindow.end}
              onUpdate={({ range }) => {
                setCustomTimeWindow({
                  start: range.from,
                  end: range.to ?? range.from,
                });
              }}
              align="end"
            />
          </div>
        }
      />
      {loading ? (
        <FullScreenLoading />
      ) : (
        <div className="flex flex-col w-full gap-4 mb-12">
          <div className="flex flex-col w-full gap-4 sm:flex-row">{cards}</div>
          <div className="flex w-full">
            <RuleDashboardInsightsChart
              lookback={LookbackLength.CUSTOM}
              timeWindow={customTimeWindow}
              timeDivision={timeDivision}
              title="Total actions"
              initialGroupBy="ACTION_ID"
            />
          </div>
          <div className="flex w-full gap-4">{charts}</div>
        </div>
      )}
    </div>
  );
}
