import {
  GQLBaseField,
  GQLGetSamplingJobResultsQuery,
  GQLViolatesPolicy,
} from '@/graphql/generated';
import { getPrimaryContentFields } from '@/utils/itemUtils';

export type LabeledSample = Extract<
  GQLGetSamplingJobResultsQuery['getSamplingJobResults'],
  { __typename: 'SamplingJobSuccess' }
>['samples'][0] & { violatesPolicy: GQLViolatesPolicy };

export const encodeStringForJsonPointer = (str: string) =>
  str.replace('~', '~0').replace('/', '~1');

// Takes an array of strings and constructs a JSON path, e.g. the input ['a',
// 'b'] would output '/a/b'.
export const stringPathToJsonPointer = (path: string[]) =>
  `/${path.map(encodeStringForJsonPointer).join('/')}`;

export const getAllJsonPointersForSample = (
  sample: LabeledSample,
  itemTypes: readonly { id: string; baseFields: readonly GQLBaseField[] }[],
) =>
  getPrimaryContentFields(
    itemTypes
      ?.filter((it) => it.id === sample.item.itemType.id)
      ?.flatMap((it) => it.baseFields) ?? [],
    sample.item.data,
  )
    .filter((field) => field.value != null)
    .flatMap((field) => {
      switch (field.type) {
        case 'ARRAY':
          return (field.value as unknown[]).map(
            (_, i) => `/${encodeStringForJsonPointer(field.name)}/${i}`,
          );
        case 'MAP':
          return Object.keys(field.value as Record<string, unknown>).map(
            (key) =>
              `/${encodeStringForJsonPointer(
                field.name,
              )}/${encodeStringForJsonPointer(key)}`,
          );
        default:
          return [`/${encodeStringForJsonPointer(field.name)}`];
      }
    });

export function getLabeledItemsForSubmission(opts: {
  samples: LabeledSample[];
  selectedSampleFields: Record<string, string[]>;
  policyId: string;
  itemTypes: readonly { id: string; baseFields: readonly GQLBaseField[] }[];
  samplingStrategy: string;
}) {
  const {
    samples,
    selectedSampleFields,
    policyId,
    itemTypes,
    samplingStrategy,
  } = opts;

  const positiveLabels = samples.flatMap((sample) => {
    const allJsonPointers = getAllJsonPointersForSample(sample, itemTypes);
    if (
      selectedSampleFields[sample.item.itemId].some(
        (it) => !allJsonPointers.includes(it),
      )
    ) {
      throw Error(
        'Invalid JSON pointer in selected sample field. Selected fields: ' +
          selectedSampleFields[sample.item.itemId].join(', ') +
          '. All JSON pointers: ' +
          allJsonPointers.join(', '),
      );
    }

    return selectedSampleFields[sample.item.itemId].map((field) => ({
      samplingStrategy,
      policyIdentifier: {
        id: policyId,
      },
      item: {
        itemId: sample.item.itemId,
        itemType: {
          id: sample.item.itemType.id,
          version: sample.item.itemType.version,
          schemaVariant: 'ORIGINAL' as const,
        },
        data: sample.item.data,
      },
      violatesPolicy: GQLViolatesPolicy.True,
      itemFieldJsonPointers: [field],
    }));
  });

  const negativeLabels = samples.flatMap((sample) =>
    getAllJsonPointersForSample(sample, itemTypes)
      .filter(
        (field) => !selectedSampleFields[sample.item.itemId].includes(field),
      )
      .map((field) => ({
        samplingStrategy,
        policyIdentifier: {
          id: policyId,
        },
        item: {
          itemId: sample.item.itemId,
          itemType: {
            id: sample.item.itemType.id,
            version: sample.item.itemType.version,
            schemaVariant: 'ORIGINAL' as const,
          },
          data: sample.item.data,
        },
        violatesPolicy: sample.violatesPolicy,
        itemFieldJsonPointers: [field],
      })),
  );

  const entireItemLabels = samples.map((sample) => ({
    samplingStrategy,
    policyIdentifier: {
      id: policyId,
    },
    item: {
      itemId: sample.item.itemId,
      itemType: {
        id: sample.item.itemType.id,
        version: sample.item.itemType.version,
        schemaVariant: 'ORIGINAL' as const,
      },
      data: sample.item.data,
    },
    violatesPolicy:
      selectedSampleFields[sample.item.itemId].length > 0
        ? GQLViolatesPolicy.True
        : GQLViolatesPolicy.False,
    itemFieldJsonPointers: [''],
  }));

  return [...positiveLabels, ...negativeLabels, ...entireItemLabels];
}
