import { Label, Slider, Switch } from '@/cove-ui';
import {
  useGQLOrgDefaultSafetySettingsQuery,
  useGQLSetOrgDefaultSafetySettingsMutation,
} from '@/graphql/generated';
import GoldenRetrieverPuppies from '@/images/GoldenRetrieverPuppies.png';
import { gql } from '@apollo/client';
import { notification } from 'antd';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import CoveButton from '../dashboard/components/CoveButton';
import FormHeader from '../dashboard/components/FormHeader';
import FullScreenLoading from '@/components/common/FullScreenLoading';

import {
  BLUR_LEVELS,
  type BlurStrength,
} from '../dashboard/mrt/manual_review_job/v2/ncmec/NCMECMediaViewer';

gql`
  query OrgDefaultSafetySettings {
    myOrg {
      defaultInterfacePreferences {
        moderatorSafetyMuteVideo
        moderatorSafetyGrayscale
        moderatorSafetyBlurLevel
      }
    }
  }

  mutation SetOrgDefaultSafetySettings(
    $orgDefaultSafetySettings: ModeratorSafetySettingsInput!
  ) {
    setOrgDefaultSafetySettings(
      orgDefaultSafetySettings: $orgDefaultSafetySettings
    ) {
      _
    }
  }
`;

export default function ManualReviewSafetySettings() {
  const [settings, setSettings] = useState<{
    moderatorSafetyBlurLevel: BlurStrength;
    moderatorSafetyGrayscale: boolean;
    moderatorSafetyMuteVideo: boolean;
  }>({
    moderatorSafetyBlurLevel: 2,
    moderatorSafetyGrayscale: true,
    moderatorSafetyMuteVideo: true,
  });
  const [notificationApi, notificationContextHolder] =
    notification.useNotification();

  const { loading, error, data } = useGQLOrgDefaultSafetySettingsQuery();

  const [saveSafetySettings, { loading: mutationLoading }] =
    useGQLSetOrgDefaultSafetySettingsMutation({
      onCompleted: () =>
        notificationApi.success({ message: 'Default safety settings saved!' }),
      onError() {
        notificationApi.error({
          message:
            "Your organization's safety settings failed to save. Please try again.",
        });
      },
    });

  useEffect(() => {
    if (!data?.myOrg?.defaultInterfacePreferences) {
      return;
    }
    const {
      moderatorSafetyMuteVideo,
      moderatorSafetyGrayscale,
      moderatorSafetyBlurLevel,
    } = data.myOrg.defaultInterfacePreferences;
    setSettings({
      moderatorSafetyMuteVideo,
      moderatorSafetyGrayscale,
      moderatorSafetyBlurLevel: moderatorSafetyBlurLevel as BlurStrength,
    });
  }, [data?.myOrg?.defaultInterfacePreferences]);

  if (loading) {
    return <FullScreenLoading />;
  }

  if (error || !data?.myOrg?.defaultInterfacePreferences) {
    throw error ?? new Error('Could not load safety settings');
  }

  return (
    <div className="flex flex-col text-start">
      <Helmet>
        <title>Default Organization Safety Settings</title>
      </Helmet>
      <FormHeader
        title="Standardize Your Organization's Safety Settings"
        subtitle="Here you can configure your organization's default safety settings. Whenever a new employee in your organization creates a Cove account, these default safety settings will be applied to their account. Every time they view a reported image or video in Cove, we'll use these settings to maintain their safety and well-being. If an individual employee wants to override these settings, they can do so in their personal Safety Settings page."
      />
      <div className="my-8 divider" />
      <div className="flex gap-8">
        <div className="flex flex-col justify-center">
          <div className="mb-4 text-lg font-medium text-slate-700">
            Organization-Wide Safety Settings
          </div>
          <div className="flex items-center h-10 gap-2">
            <Label htmlFor="blur-level">Blur</Label>
            <Slider
              id="blur-level"
              className="w-32"
              min={0}
              max={Object.keys(BLUR_LEVELS).length - 1}
              onValueChange={([strength]) =>
                setSettings({
                  ...settings,
                  moderatorSafetyBlurLevel: strength as BlurStrength,
                })
              }
              value={[settings.moderatorSafetyBlurLevel]}
              step={1}
            />
          </div>
          <div className="flex items-center mb-2 space-x-2">
            <Switch
              id="grayscale"
              defaultChecked
              onCheckedChange={(value) =>
                setSettings({
                  ...settings,
                  moderatorSafetyGrayscale: value,
                })
              }
              checked={settings.moderatorSafetyGrayscale}
            />
            <Label htmlFor="grayscale">Grayscale</Label>
          </div>
          <div className="flex items-center space-x-2">
            <Switch
              id="mute-videos"
              defaultChecked
              onCheckedChange={(value) =>
                setSettings({
                  ...settings,
                  moderatorSafetyMuteVideo: value,
                })
              }
              checked={settings.moderatorSafetyMuteVideo}
            />
            <Label htmlFor="mute-videos">Mute Videos</Label>
          </div>
        </div>
        <img
          className={`rounded object-scale-down w-96 h-60 ${
            settings.moderatorSafetyBlurLevel != null
              ? BLUR_LEVELS[settings.moderatorSafetyBlurLevel]
              : 'blur-sm'
          } ${settings.moderatorSafetyGrayscale ? 'grayscale' : ''}`}
          alt="puppies"
          src={GoldenRetrieverPuppies}
        />
      </div>
      <div className="my-8 divider" />
      <div className="flex justify-start">
        <CoveButton
          title="Save"
          loading={mutationLoading}
          onClick={() => {
            saveSafetySettings({
              variables: {
                orgDefaultSafetySettings: settings,
              },
            });
          }}
        />
      </div>
      {notificationContextHolder}
    </div>
  );
}
