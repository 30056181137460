import DemoPrimaryButton from '../components/DemoPrimaryButton';
import DemoSecondaryButton from '../components/DemoSecondaryButton';

import LogoAndWordmarkAndAIDarkPurple from '../../../../../images/LogoAndWordmarkAndAIDarkPurple.png';

export default function DemoCompletedScreen(props: {
  goBack: () => void;
  selectedPolicies: string[];
  completedPolicies: string[];
}) {
  const { completedPolicies } = props;
  return (
    <div className="flex flex-col items-center justify-center w-full h-full bg-gray-50">
      <div className="flex flex-col items-center justify-center max-w-4xl p-12 bg-white border border-solid rounded-lg border-slate-200">
        <img alt="logo" src={LogoAndWordmarkAndAIDarkPurple} width={240} />
        <div className="pt-8 pb-4 text-3xl font-semibold">
          You just trained AI moderators for the following policies
        </div>
        <div className="pb-12 text-base">
          We will create Rules that use these AI moderators to enforce your
          policies automatically.
        </div>
        <div className="block w-full max-w-2xl p-4 border border-solid rounded-lg border-slate-200">
          {completedPolicies.map((policy) => (
            <div key={policy} className="inline-flex px-8 py-6 text-base">
              {policy}
            </div>
          ))}
        </div>
        <div className="flex items-center justify-center pt-12 gap-8">
          <DemoSecondaryButton title="Add more policies" onClick={() => {}} />
          <DemoPrimaryButton title="Launch Cove" onClick={() => {}} />
        </div>
      </div>
    </div>
  );
}
