import { Button, Select } from '@/cove-ui';
import {
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/cove-ui/Select';
import {
  useGQLCreateExistingCoveModelSamplingJobMutation,
  useGQLGetComparisonSamplingJobResultsLazyQuery,
  useGQLGetItemTypesByIdentifiersLazyQuery,
  useGQLGetLiveModelForPolicyQuery,
  useGQLLatestSuccessfulSamplingJobLazyQuery,
  useGQLPoliciesQuery,
} from '@/graphql/generated';
import { stripTypename } from '@/graphql/inputHelpers';
import { getPrimaryContentFields } from '@/utils/itemUtils';
import { gql } from '@apollo/client';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import FullScreenLoading from '@/components/common/FullScreenLoading';

import type { ItemTypeFieldFieldData } from '../../item_types/itemTypeUtils';
import FieldsComponent from '../../mrt/manual_review_job/v2/ManualReviewJobFieldsComponent';

gql`
  query GetLiveModelForPolicy($policyId: ID!) {
    getLiveModelForPolicy(policyId: $policyId) {
      id
      version
    }
  }

  query GetComparisonSamplingJobResults($jobId: ID!) {
    getSamplingJobResults(jobId: $jobId) {
      ... on SamplingJobSuccess {
        samples {
          item {
            itemId
            itemType {
              id
              version
              schemaVariant
            }
            data
          }
          score
          additionalModelScore {
            score
          }
        }
        samplingStrategy
      }
      ... on SamplingJobFailure {
        title
      }
      ... on SamplingJobNotFoundError {
        title
      }
    }
  }
`;

function SampleComparisonHeaderRow() {
  return (
    <div className="flex flex-row items-center justify-end font-bold">
      <div className="basis-[12.5%] flex justify-center items-center mr-1">
        New
      </div>
      <div className="basis-[12.5%] flex justify-center items-center mr-1">
        Live
      </div>
    </div>
  );
}

function SampleComparisonRow(props: {
  sample: {
    fields: ItemTypeFieldFieldData[];
    itemTypeId: string;
    newModelScore: string;
    currentModelScore: string;
  };
}) {
  const { fields, itemTypeId, newModelScore, currentModelScore } = props.sample;
  const differenceThreshold =
    Math.abs(parseFloat(newModelScore) - parseFloat(currentModelScore)) >= 0.1;

  return (
    <div className="flex flex-row items-center">
      <div
        className={`p-4 bg-white rounded basis-3/4 ${
          differenceThreshold ? 'border border-gray-500' : ''
        }`}
      >
        <FieldsComponent
          fields={fields}
          itemTypeId={itemTypeId}
          options={{ transparentBackground: true }}
        />
      </div>
      <div className="basis-[12.5%] flex justify-center items-center">
        <div
          className={`p-2 bg-white border rounded-md ${
            differenceThreshold ? 'border-gray-500' : 'border-gray-300'
          }`}
        >
          {Number(newModelScore).toFixed(3)}
        </div>
      </div>
      <div className="basis-[12.5%] flex justify-center items-center">
        <div
          className={`p-2 bg-white border rounded-md ${
            differenceThreshold ? 'border-gray-500' : 'border-gray-300'
          }`}
        >
          {Number(currentModelScore).toFixed(3)}
        </div>
      </div>
    </div>
  );
}

export default function ModelComparison() {
  const { policyId, modelVersion, modelId } = useParams<{
    policyId: string;
    modelVersion: string;
    modelId: string;
  }>();

  if (policyId == null || modelVersion == null || modelId == null) {
    throw new Error('Policy ID, model version and model ID are all required');
  }

  const [modelToSortBy, setModelToSortBy] = useState<'NEW' | 'CURRENT'>('NEW');
  const [jobId, setJobId] = useState<string | null>(null);
  const {
    data: liveModelData,
    loading: liveModelLoading,
    error: liveModelError,
  } = useGQLGetLiveModelForPolicyQuery({ variables: { policyId } });
  const {
    data: policiesData,
    loading: policiesLoading,
    error: policiesError,
  } = useGQLPoliciesQuery();

  const modelVersionInt = parseInt(modelVersion);

  if (modelVersionInt === undefined) {
    throw new Error('Model version is required');
  }

  const liveModel = liveModelData?.getLiveModelForPolicy;

  const [
    getCachedSamples,
    { data: cachedSamplesData, loading: cachedSamplesLoading },
  ] = useGQLLatestSuccessfulSamplingJobLazyQuery();

  const [getItemTypes, { data: itemTypesData }] =
    useGQLGetItemTypesByIdentifiersLazyQuery();

  const [startSamplingJob] = useGQLCreateExistingCoveModelSamplingJobMutation({
    onCompleted: (response) => {
      const jobId = response.createExistingCoveModelSamplingJob.jobId;

      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set('jobId', jobId);
      setJobId(jobId);
      window.history.replaceState(
        null,
        '',
        `${window.location.pathname}?${queryParams.toString()}`,
      );
    },
  });

  const [getSamplingJobResults, { data: samplingJobResultsData, stopPolling }] =
    useGQLGetComparisonSamplingJobResultsLazyQuery({
      variables: {
        jobId: jobId!,
      },
      pollInterval: 5000,
      onCompleted: (response) => {
        if (
          response.getSamplingJobResults.__typename === 'SamplingJobFailure'
        ) {
          stopPolling();
        } else if (
          response.getSamplingJobResults.__typename ===
          'SamplingJobNotFoundError'
        ) {
          stopPolling();
        }
      },
    });

  const samplingStrategy = useMemo(() => {
    if (!liveModel) {
      return null;
    }
    return {
      modelScoreSamplingStrategy: {
        score: {
          min: 0.0,
          max: 1.0,
        },
        additionalScoringModel: stripTypename(liveModel),
      },
    };
  }, [liveModel]);

  useEffect(() => {
    if (!samplingStrategy) {
      return;
    }
    getCachedSamples({
      variables: { modelId, modelVersion: modelVersionInt, samplingStrategy },
    });
  }, [getCachedSamples, modelId, modelVersionInt, samplingStrategy]);

  useEffect(() => {
    if (
      cachedSamplesData?.getLatestSuccessfulSamplingJobResultForModel != null ||
      cachedSamplesLoading ||
      samplingJobResultsData?.getSamplingJobResults != null ||
      liveModel == null ||
      samplingStrategy == null
    ) {
      return;
    }

    if (jobId == null) {
      startSamplingJob({
        variables: {
          input: {
            modelId,
            modelVersion: parseInt(modelVersion),
            numSamples: 200,
            samplingStrategy,
          },
        },
      });
    } else {
      getSamplingJobResults();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cachedSamplesData?.getLatestSuccessfulSamplingJobResultForModel,
    cachedSamplesLoading,
    getSamplingJobResults,
    jobId,
    samplingJobResultsData?.getSamplingJobResults,
    startSamplingJob,
    liveModel,
  ]);

  const policy = policiesData?.myOrg?.policies.find((it) => it.id === policyId);

  const samples = useMemo(() => {
    const fetchedSamples =
      cachedSamplesData?.getLatestSuccessfulSamplingJobResultForModel
        ?.samples ??
      (samplingJobResultsData?.getSamplingJobResults.__typename ===
      'SamplingJobSuccess'
        ? samplingJobResultsData.getSamplingJobResults.samples
        : []);
    return fetchedSamples.map((it) => ({
      item: it.item,
      newModelScore: it.score ? it.score.toString() : 'N/A',
      currentModelScore: it.additionalModelScore!.score
        ? it.additionalModelScore!.score.toString()
        : 'N/A',
    }));
  }, [
    cachedSamplesData?.getLatestSuccessfulSamplingJobResultForModel,
    samplingJobResultsData?.getSamplingJobResults,
  ]);

  useEffect(() => {
    if (samples && samples.length > 0) {
      getItemTypes({
        variables: {
          identifiers: _.uniqBy(
            samples.map((it) => ({
              id: it.item.itemType.id,
              version: it.item.itemType.version,
              schemaVariant: 'ORIGINAL' as const,
            })),
            (it) => `${it.id}-${it.version}`,
          ),
        },
      });
    }
  }, [getItemTypes, samples]);

  if (liveModelLoading || policiesLoading) {
    return <FullScreenLoading />;
  }

  if (liveModelError || policiesError) {
    return <div>Error loading live model</div>;
  }

  if (policy == null) {
    throw new Error(`Policy with ID ${policyId} not found`);
  }

  const itemTypes = itemTypesData?.itemTypes;

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row gap-2">
        <div>Custom AI Models</div>
        <div>/</div>
        <span className="font-semibold">Compare Models</span>
      </div>
      <div className="flex flex-row items-end justify-between">
        <div className="text-2xl font-bold">Compare {policy.name} Models</div>
        <div className="flex flex-row items-end gap-4">
          <div className="flex flex-col gap-2">
            <div>Sorted by</div>
            <Select
              value={modelToSortBy}
              onValueChange={(value) =>
                setModelToSortBy(value as 'NEW' | 'CURRENT')
              }
            >
              <SelectTrigger className="bg-white w-72">
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectItem key="NEW" value="NEW">
                    New Model
                  </SelectItem>
                  <SelectItem key="CURRENT" value="CURRENT">
                    Current Model
                  </SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
          <Button onClick={() => {}} size="lg">
            Continue to Rules
          </Button>
        </div>
      </div>
      <>
        <SampleComparisonHeaderRow />
        {_.reverse(
          _.sortBy(samples, [
            modelToSortBy === 'NEW' ? 'newModelScore' : 'currentModelScore',
          ]),
        ).map((sample) => {
          const itemType = itemTypes?.find(
            (itemType) => itemType.id === sample.item.itemType.id,
          );
          const primaryFields = getPrimaryContentFields(
            itemType?.baseFields ?? [],
            sample.item.data,
          ).filter(
            (it) =>
              it.value != null &&
              !(Array.isArray(it.value) && it.value.length === 0),
          );

          return (
            <SampleComparisonRow
              key={sample.item.itemId}
              sample={{
                fields: primaryFields,
                itemTypeId: sample.item.itemType.id,
                newModelScore: sample.newModelScore,
                currentModelScore: sample.currentModelScore,
              }}
            />
          );
        })}
      </>
    </div>
  );
}
