import { m, useAnimationControls } from 'framer-motion';
import { useEffect, useState, type CSSProperties } from 'react';

import { Typography } from '../../components/ui/Typography';

import { cn } from '../../lib/utils';

const paths = {
  path1:
    'M5.966 124.22v0c25.453.04 48.779-10.811 58.756-27.333l38.615-63.947c10.44-17.288 34.847-28.643 61.482-28.6v0',
  path2:
    'M164.171 4.85711V4.85711C147.774 4.8458 132.862 9.47185 122.177 17.8847L98.419 36.591C74.8893 55.1171 42.0518 65.3043 5.94439 65.2794V65.2794',
  path3:
    'M164.575 65.5961V65.5961C148.135 65.6075 133.188 60.9826 122.476 52.57L98.6511 33.8591C75.0622 15.3335 42.1472 5.14891 5.94484 5.17383V5.17383',
  path4:
    'M5.93435 4.23692V4.23692C31.4586 4.23692 54.83 15.118 64.8688 31.6751L103.839 95.9499C114.344 113.275 138.799 124.661 165.508 124.661V124.661',
};

const pathVariants = {
  start: {
    offsetDistance: '0%',
  },
  end: {
    offsetDistance: '100%',
  },
};

export interface ModerationFlowProps {
  path: keyof typeof paths;
  animate?: boolean;
  style?: CSSProperties;
  title: string;
  jobCount: number;
  onComplete: () => void;
}

export function ModerationFlow({
  path,
  style,
  animate,
  onComplete,
  title,
  jobCount: initialJobCount,
}: ModerationFlowProps) {
  const [jobCount, setJobCount] = useState(initialJobCount);
  const [cardActive, setCardActive] = useState(false);

  const pathControls = useAnimationControls();

  let timeout: NodeJS.Timeout;

  useEffect(() => {
    async function triggerAnimation() {
      if (animate) {
        await pathControls.start(
          ['path2', 'path3'].includes(path) ? 'start' : 'end',
        );
        setJobCount((count) => count + 1);
        setCardActive(true);
        onComplete();
      } else {
        pathControls.set(['path2', 'path3'].includes(path) ? 'end' : 'start');
        setCardActive(false);
        setJobCount((count) => count - 1);
      }
    }

    triggerAnimation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animate]);

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="relative" style={style}>
      <div
        className={cn('absolute z-10', {
          'top-4 left-0 -translate-x-[80%]': ['path1', 'path2'].includes(path),
          '-bottom-16 left-0 -translate-x-[80%]': ['path3'].includes(path),
          'bottom-2 left-0 -translate-x-[80%]': ['path4'].includes(path),
        })}
      >
        <m.img
          variants={pathVariants}
          animate={pathControls}
          transition={{ duration: 1, delay: 0.2 }}
          src={`/graphics/moderation-${
            ['path2', 'path4'].includes(path) ? 'card2' : 'card1'
          }.svg`}
          className="absolute [offset-rotate:0deg]"
          style={{ offsetPath: `url(#${path})` }}
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="171"
          height="129"
          fill="none"
          viewBox="0 0 171 129"
        >
          <path
            id={path}
            stroke="#69A9F6"
            strokeLinecap="round"
            strokeWidth="10"
            d={paths[path]}
            className="transition-all duration-500"
            opacity={animate ? 1 : 0.4}
            vectorEffect="non-scaling-stroke"
          />
        </svg>
      </div>
      <div className="relative z-20 inline-block px-3 py-2 bg-white border border-solid rounded-lg border-primary w-max home-shadow-lg min-w-28">
        <div
          className={cn(
            'transition-opacity duration-500 w-full',
            'justify-between items-baseline inline-flex flex-row gap-2 opacity-40',
            { 'opacity-100': cardActive },
          )}
        >
          <Typography as="span" size="sm">
            {title}
          </Typography>
          <Typography
            as="span"
            color="subtle"
            size="sm"
            align="right"
            className="w-12"
          >
            {jobCount} jobs
          </Typography>
        </div>
      </div>
    </div>
  );
}

const flows = [
  {
    title: 'Spam Queue',
    jobCount: 3,
    path: 'path1',
    top: 0,
  },
  {
    title: 'CSAM Queue',
    jobCount: 5,
    path: 'path2',
    top: 0.75,
  },
  {
    title: 'Hate Speech Queue',
    jobCount: 2,
    path: 'path3',
    top: 4.5,
  },
  {
    title: 'Counterfeit Queue',
    jobCount: 3,
    path: 'path4',
    top: 1,
  },
];

const actions = [
  'Delete',
  'Allow',
  'Mute User',
  'Ban User',
  'Report to NCMEC',
  'Escalate',
];

export function Moderation() {
  const [currentAnimation, setCurrentAnimation] = useState(0);
  const [activeAction, setActiveAction] = useState('');

  let timeout: NodeJS.Timeout;

  function onComplete() {
    timeout = setTimeout(() => {
      setActiveAction(actions[Math.floor(Math.random() * actions.length)]);

      timeout = setTimeout(() => {
        setActiveAction('');

        timeout = setTimeout(() => {
          setCurrentAnimation((v) => (v + 1) % 4);
        }, 500);
      }, 500);
    }, 500);
  }

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <img
        alt="Queue"
        src="/graphics/queue-static.svg"
        className="block lg:hidden"
      />
      <div className="relative flex-row hidden lg:flex gap-8">
        <div className="flex flex-row items-center justify-start">
          <img
            src="/graphics/card1.svg"
            alt="Moderation"
            className="z-20 relative w-[140px] rounded-lg home-shadow-lg"
          />

          <div className="ml-12 scale-90">
            {flows.map(({ path, top, ...props }, idx) => (
              <ModerationFlow
                {...props}
                key={path}
                path={path as ModerationFlowProps['path']}
                style={{ marginTop: `${top}rem` }}
                onComplete={onComplete}
                animate={currentAnimation === idx}
              />
            ))}
          </div>
        </div>

        <div className="">
          <Typography variant="title" size="xs">
            Your Decision
          </Typography>

          <ul className="list-none mt-4 mb-0 w-max text-sm border border-solid border-default rounded-lg [&>li]:p-2 [&>li]:border-b [&>li:last-of-type]:border-none [&>li]:pr-8 ps-0">
            {actions.map((action) => (
              <li
                key={action}
                className={cn(
                  'p-2 border-b text-xs last-of-type:border-none pr-8 transition-all duration-500',
                  {
                    'bg-black/10 opacity-50': activeAction === action,
                  },
                )}
              >
                {action}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}
