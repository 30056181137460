import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import ComponentLoading from '../../../../../components/common/ComponentLoading';
import CoveModal from '../../../components/CoveModal';

import { useGQLOrgDataForModelTrainingQuery } from '../../../../../graphql/generated';
import TrainingPipelineFooter from '../TrainingPipelineFooter';

export default function TrainingPipelinePolicyCompletedScreen(props: {}) {
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [searchParams] = useSearchParams();
  const policyId = searchParams.get('policyId') ?? '';

  const navigate = useNavigate();

  const { data, loading, error } = useGQLOrgDataForModelTrainingQuery();

  if (loading) {
    return <ComponentLoading />;
  }

  if (error) {
    throw error;
  }

  const discardModal = (
    <CoveModal
      title="Are you sure you want to discard this model?"
      visible={showDiscardModal}
      onClose={() => setShowDiscardModal(false)}
      footer={[
        {
          title: 'Cancel',
          onClick: () => setShowDiscardModal(false),
          type: 'primary',
        },
        {
          title: 'Discard',
          onClick: () => {
            // TODO: implement
          },
          type: 'danger',
        },
      ]}
    >
      If you discard the model, you'll lose all progress and will move on to the
      next policy. If you'd like to save your progress without actually using
      the model yet, you can click 'Save as Draft'.
    </CoveModal>
  );

  const policy = data?.myOrg?.policies.find((it) => it.id === policyId);

  if (!policy) {
    throw new Error(`Could not find policy with id ${policyId}`);
  }
  const { name: policyName } = policy;

  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <div className="flex flex-col items-start justify-center h-full max-w-2xl text-start">
        <div className="pb-6 text-3xl font-bold">
          Your {policyName} AI model is complete!
        </div>
        <div className="pb-4 text-base text-slate-500">
          Congratulations! You've finished teaching your AI model how to detect{' '}
          {policyName}. If you deploy the model, we'll add your AI model to a
          Cove Rule, which will automatically start enforcing your {policyName}{' '}
          policy on all of the content you send to Cove.
        </div>
      </div>
      <TrainingPipelineFooter
        primaryButton={{
          title: 'Done',
          onClick: () => navigate('/dashboard/models_and_policies/policies'),
        }}
      />
      {discardModal}
    </div>
  );
}
