import { useNavigate } from 'react-router-dom';

import LogoAndWordmarkPurple from '../../../../images/LogoAndWordmarkPurple.png';

export default function TrainingPipelineHeader() {
  const navigate = useNavigate();
  return (
    <div className="sticky top-0 z-50 flex items-center justify-between w-full py-6 border-t-0 border-b border-l-0 border-r-0 border-solid px-14 border-slate-200 bg-slate-50">
      <img
        className="float-left"
        src={LogoAndWordmarkPurple}
        alt="Logo"
        width="110"
        height="29"
        onClick={() => navigate('/dashboard/models_and_policies/policies')}
      />
    </div>
  );
}
