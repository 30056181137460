import { m } from 'framer-motion';
import { useRef } from 'react';

import {
  SignalCarousel,
  type SignalCard,
} from '../../../components/SignalCarousel';
import { Typography } from '../../../components/ui/Typography';

const cards = [
  [
    {
      name: 'Custom: Spam Model Score',
      partner: 'Cove',
      active: true,
    },
    {
      name: 'Nudity Score',
      partner: 'AWS Rekognition',
    },
    {
      name: 'Counterfeit Goods Score',
      partner: 'Cove',
    },
    {
      name: 'Hate Speech Score',
      partner: 'OpenAI',
    },
    {
      name: 'Drug Sales Score',
      partner: 'Cove',
    },
    {
      name: 'Identity Hate Score',
      partner: 'Clarifai',
    },
  ],
  [
    {
      name: 'Violence Score',
      partner: 'Hive',
    },
    {
      name: 'Phishing Score',
      partner: 'Cove',
      active: true,
    },
    {
      name: 'Bullying Score',
      partner: 'Spectrum Labs',
    },
    {
      name: 'User Risk Score',
      partner: 'Cove',
      active: true,
    },
    {
      name: 'Insult Score',
      partner: 'Perspective',
    },
    {
      name: 'Language Detection',
      partner: 'Cove',
    },
  ],
] satisfies [SignalCard[], SignalCard[]];

export function Signals() {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div className="flex flex-col w-full max-w-3xl" ref={ref}>
      <div className="flex flex-col gap-3">
        <m.img
          className="object-contain max-w-full h-min max-h-20 w-min"
          initial={{ y: 10, opacity: 0 }}
          whileInView={{ y: 0, opacity: 100 }}
          viewport={{ once: true, amount: 'some' }}
          transition={{ duration: 0.5, delay: 1 }}
          src="/graphics/condition-1.png"
        />
        <m.img
          className="object-contain max-w-full ml-6 sm:ml-16 h-min max-h-20 w-min"
          initial={{ y: 10, opacity: 0 }}
          whileInView={{ y: 0, opacity: 100 }}
          viewport={{ once: true, amount: 'some' }}
          transition={{ duration: 0.5, delay: 2 }}
          src="/graphics/condition-2.png"
        />
        <m.img
          className="object-contain max-w-full ml-6 sm:ml-16 h-min max-h-20 w-min"
          initial={{ y: 10, opacity: 0 }}
          whileInView={{ y: 0, opacity: 100 }}
          viewport={{ once: true, amount: 'some' }}
          transition={{ duration: 0.5, delay: 3 }}
          src="/graphics/condition-3.png"
        />
      </div>
      <m.div
        initial={{ y: 10, opacity: 0 }}
        whileInView={{ y: 0, opacity: 100 }}
        viewport={{ once: true, amount: 'some' }}
        transition={{ duration: 0.5, delay: 4 }}
      >
        <Typography variant="title" size="sm" className="py-8 text-center">
          Signal Library
        </Typography>
      </m.div>

      <m.div className="flex flex-col gap-3">
        {cards.map((cards, i) => (
          <m.div
            key={i}
            initial={{ y: 10, opacity: 0 }}
            whileInView={{ y: 0, opacity: 100 }}
            viewport={{ once: true, amount: 'some' }}
            transition={{ duration: 0.5, delay: 4 }}
          >
            <SignalCarousel
              index={i + 1}
              direction={i % 2 ? 'left' : 'right'}
              key={i}
              cards={cards}
            />
          </m.div>
        ))}
      </m.div>
    </div>
  );
}
