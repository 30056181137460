import HandInCircleBlue from '../../../../../../images/HandInCircleBlue.png';

export default function TrainingPipelineLabelSamplesModal(props: {
  hasPositiveLabels: boolean;
  visible: boolean;
  policyName: string;
  labelingStage: 'partially_complete' | 'complete';
  onRedefinePolicy?: () => void;
  onRestartLabeling?: () => void;
}) {
  const {
    hasPositiveLabels,
    visible,
    policyName,
    labelingStage,
    onRedefinePolicy,
    onRestartLabeling,
  } = props;

  if (!visible) {
    return null;
  }

  const title = () => {
    if (!hasPositiveLabels && labelingStage === 'partially_complete') {
      return `Are you sure you haven't seen any content that violates the ${policyName} policy yet?`;
    } else if (hasPositiveLabels && labelingStage === 'complete') {
      // TODO: Add a request to fetch 10 more items that are likely to be
      // negative and have the customer label them.
      return `Unable to Create Model at this Time`;
    }

    throw new Error('Invalid state, update implementation to handle this case');
  };
  const subtitle = () => {
    if (!hasPositiveLabels && labelingStage === 'partially_complete') {
      return `This could mean that your policy is not specific enough to identify ${policyName}, or that you accidentally mislabeled some content. We can't train an AI model without any violations, so to continue, you can either edit your policy or restart labeling.`;
    } else if (hasPositiveLabels && labelingStage === 'complete') {
      return `We're unable to create a model for ${policyName} at this time because every sample violated the policy so we don't have any negative samples with which to train the model. Please contact us to get help setting up your model.`;
    }

    throw new Error('Invalid state, update implementation to handle this case');
  };

  return (
    <div className="absolute z-50 w-full h-full pt-24 bg-opacity-50 px-72 bg-slate-500">
     <div className="flex flex-col items-center px-12 py-8 text-center bg-white border border-solid rounded-lg gap-4 border-slate-200">
       <img className="my-4" src={HandInCircleBlue} alt="HandInCircle" width={48} height={48} />
       <div className="text-2xl font-bold">{title()}</div>
       <div className="mb-8">{subtitle()}</div>
       {labelingStage === 'partially_complete' && (
         <>
           <div
             className="flex items-center justify-center px-6 py-4 mt-4 font-bold text-white rounded-lg cursor-pointer bg-cove-purple hover:bg-cove-purple-hover"
             onClick={onRedefinePolicy}
           >{`Edit ${policyName} policy`}</div>
           <div
             className="mt-4 mb-8 font-bold cursor-pointer text-cove-purple hover:text-cove-purple-hover"
             onClick={onRestartLabeling}
           >{`Restart labeling content for ${policyName}`}</div>
         </>
       )}
     </div>
    </div>
  );
}
