import { GQLSignalType, useGQLPoliciesQuery } from '@/graphql/generated';
import type { CoreSignal } from '@/models/signal';
import { Form, Select } from 'antd';

import { selectFilterByLabelOption } from '@/webpages/dashboard/components/antDesignUtils';

import type { ConditionLocation, RuleFormLeafCondition } from '../../../types';

const { Option } = Select;

export default function RuleFormConditionSignalArgs(props: {
  condition: RuleFormLeafCondition;
  location: ConditionLocation;
  onUpdateSignalArgs: (args: CoreSignal['args']) => void;
}) {
  const { condition, location, onUpdateSignalArgs } = props;
  const { conditionSetIndex, conditionIndex } = location;

  const { data, loading, error } = useGQLPoliciesQuery();

  if (error) {
    throw error;
  }

  if (loading || !data?.myOrg) {
    return null;
  }
  const policies = data.myOrg.policies;

  // Don't show this if the selected signal is not GPT4O_MINI. The AGGREGATED
  // signal will also need signal args, but they'll be handled through a
  // completely different UI. For now, for the sake of expediency, this
  // component only supports selecting policy IDs as signal args.
  if (condition.signal?.type !== GQLSignalType.Gpt4OMini) {
    return null;
  }

  return (
    <Form.Item
      key={`RuleFormCondition-comparator-form-item_set_index_${conditionSetIndex}_index_${conditionIndex}`}
      className="!mb-0 !pl-4 !align-middle"
      name={[conditionSetIndex, conditionIndex, 'arg']}
      initialValue={
        condition.signal.args?.__typename === 'Gpt4oMiniSignalArgs'
          ? condition.signal.args.policyId
          : undefined
      }
    >
      {/* Needs to be wrapped in a div for the state to work properly */}
      <div
        key={`RuleFormCondition-comparator-wrapper_set_index_${conditionSetIndex}_index_${conditionIndex}`}
        className="flex flex-col items-start"
      >
        <div className="pb-1 text-xs font-bold">Policy</div>
        <Select
          key={`RuleFormCondition-comparator-select_set_index_${conditionSetIndex}_index_${conditionIndex}`}
          placeholder="Select a comparison"
          defaultValue={
            condition.signal.args?.__typename === 'Gpt4oMiniSignalArgs'
              ? condition.signal.args.policyId
              : undefined
          }
          value={
            condition.signal.args?.__typename === 'Gpt4oMiniSignalArgs'
              ? condition.signal.args.policyId
              : undefined
          }
          onChange={(value) =>
            onUpdateSignalArgs({
              __typename: 'Gpt4oMiniSignalArgs',
              policyId: value,
            })
          }
          allowClear
          showSearch
          filterOption={selectFilterByLabelOption}
          dropdownMatchSelectWidth={false}
        >
          {policies.map((policy) => (
            <Option
              key={`RuleFormCondition-comparator-option_set_index_${conditionSetIndex}_index_${conditionIndex}_${policy.id}`}
              value={policy.id}
              label={policy.name}
            >
              {policy.name}
            </Option>
          ))}
        </Select>
        <div className="invisible pb-1 text-xs font-bold">Comparison</div>
      </div>
    </Form.Item>
  );
}
