import { useGQLGetRelatedItemsQuery } from '@/graphql/generated';
import { ItemTypeFieldFieldData } from '@/webpages/dashboard/item_types/itemTypeUtils';
import { gql } from '@apollo/client';
import { ItemIdentifier } from '@protego-api/types';

import FieldsComponent from './ManualReviewJobFieldsComponent';

gql`
  query getRelatedItems($itemIdentifiers: [ItemIdentifierInput!]!) {
    latestItemSubmissions(itemIdentifiers: $itemIdentifiers) {
      ... on UserItem {
        id
        submissionId
        submissionTime
        data
        type {
          id
          name
          baseFields {
            name
            type
            required
            container {
              containerType
              keyScalarType
              valueScalarType
            }
          }
          schemaFieldRoles {
            displayName
            createdAt
            profileIcon
            backgroundImage
          }
        }
      }
      ... on ContentItem {
        id
        submissionId
        submissionTime
        data
        type {
          id
          name
          baseFields {
            name
            type
            required
            container {
              containerType
              keyScalarType
              valueScalarType
            }
          }
        }
      }
      ... on ThreadItem {
        id
        submissionId
        submissionTime
        data
        type {
          id
          name
          baseFields {
            name
            type
            required
            container {
              containerType
              keyScalarType
              valueScalarType
            }
          }
        }
      }
    }
  }
`;
export default function ContentRelatedItemComponent(props: {
  relatedItem: ItemIdentifier;
  unblurAllMedia: boolean;
}) {
  const { relatedItem, unblurAllMedia } = props;
  const { data, error } = useGQLGetRelatedItemsQuery({
    variables: {
      itemIdentifiers: [relatedItem],
    },
  });
  if (!data || error) {
    return null;
  }
  const item = data.latestItemSubmissions[0];

  const fieldData = item.type.baseFields.map(
    (
      itemTypeField, // itemTypeField comes back as a GQLBaseField, and the GQL types
    ) =>
      ({
        ...itemTypeField,
        value: item.data[itemTypeField.name],
      }) as ItemTypeFieldFieldData,
  );
  return (
    <div className="flex flex-col items-start justify-start w-full mt-8">
      <div className="flex flex-col w-full">
        <div className="text-lg font-semibold text-start">
          {/* TODO: make this title org-agnostic  */}
          {'Reviewed Location'}
        </div>
        <div className="my-6 divider" />
      </div>

      <div className="max-w-full min-w-1/2 grow">
        <FieldsComponent
          fields={fieldData}
          itemTypeId={item.type.id}
          options={{
            unblurAllMedia,
            maxHeightImage: 300,
            maxHeightVideo: 300,
          }}
        />
      </div>
    </div>
  );
}
