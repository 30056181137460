import { Button, Select } from '@/cove-ui';
import {
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/cove-ui/Select';
import {
  GQLItemRequirement,
  GQLUserPenaltySeverity,
  useGQLGetOrgIsReadyToCreateCoveModelsQuery,
  useGQLModelsDashboardQuery,
  type GQLCoveModel,
} from '@/graphql/generated';
import { SparklesFilled } from '@/icons';
import { filterNullOrUndefined } from '@/utils/collections';
import { decimalToPercentage } from '@/utils/number';
import { parseDatetimeToReadableStringInCurrentTimeZone } from '@/utils/time';
import { gql } from '@apollo/client';
import { ExternalLink } from 'lucide-react';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import CoveBadge from '../../components/CoveBadge';
import CoveModal from '../../components/CoveModal';
import DashboardHeader from '../../components/DashboardHeader';
import CopyTextComponent from '@/components/common/CopyTextComponent';
import FullScreenLoading from '@/components/common/FullScreenLoading';

import { treeFromList, type TreeNode } from '../../../../utils/tree';
import type { Policy } from '../../policies/PoliciesDashboard';
import { tripadvisorDemoModelConfigs } from '../customer_utils/tripadvisor';
import ModelModalityIcons from './ModelModalityIcons';

gql`
  query ModelsDashboard {
    myOrg {
      isDemoOrg
      policies {
        id
        name
        policyText
        enforcementGuidelines
        parentId
        policyType
      }
      models {
        id
        status
        version
        policy {
          id
        }
        itemRequirement
        createdAt
        alias
      }
    }
  }
`;

function SelectPolicyAndCreateModel(props: {
  policies: readonly { id: string; name: string }[];
  title?: string;
}) {
  const [selectedPolicyId, setSelectedPolicyId] = useState<string | undefined>(
    undefined,
  );
  const navigate = useNavigate();
  const { title, policies } = props;

  return (
    <div className="flex flex-col gap-2 p-4 bg-gray-100 border border-gray-200 border-solid rounded-md border-px">
      {title ? <div className="text-lg font-semibold">{title}</div> : null}
      <div className="flex flex-row items-center self-start gap-4">
        <Select onValueChange={(value) => setSelectedPolicyId(value)}>
          <SelectTrigger className="bg-white w-72 focus:ring-indigo-500">
            <SelectValue placeholder="Select a policy" />
          </SelectTrigger>
          {/* z-index of CoveModal is 1000, so this needs to be higher */}
          <SelectContent className="z-[2000]">
            <SelectGroup>
              {policies.map((policy) => (
                <SelectItem key={policy.id} value={policy.id}>
                  {policy.name}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
        <Button
          variant="secondary"
          size="sm"
          disabled={selectedPolicyId === undefined}
          onClick={() => {
            navigate(`/training/select_modality?policyId=${selectedPolicyId}`);
          }}
        >
          Continue
        </Button>
      </div>
    </div>
  );
}

function CreateAnotherModel(props: {
  subPolicies: readonly { id: string; name: string }[];
  topLevelPolicy: { id: string; name: string };
  hasModels: boolean;
  onSubmit: (policyId: string) => void;
  isDemoOrg: boolean;
}) {
  const { subPolicies, hasModels, topLevelPolicy, isDemoOrg } = props;
  const [selectedPolicyId, setSelectedPolicyId] = useState<string | undefined>(
    undefined,
  );
  const [modalVisible, setModalVisible] = useState(false);
  const navigate = useNavigate();

  const cannotCreateModelModal = (
    <CoveModal
      title="Create a Custom AI Model"
      visible={modalVisible}
      onClose={() => setModalVisible(false)}
    >
      <div className="flex flex-col">
        This is a demo account, so you cannot create new models. Please let us
        know if you'd like to create additional models as part of your trial or
        proof of concept!
      </div>
    </CoveModal>
  );

  return (
    <div className="flex flex-col gap-2 p-6 my-4 mr-4 bg-gray-100 border border-gray-200 border-solid rounded-lg basis-5/12 border-px">
      <div className="text-lg font-semibold">
        Create
        {hasModels
          ? ' another '
          : ['a', 'e', 'i', 'o', 'u'].includes(
              topLevelPolicy.name[0].toLowerCase(),
            )
          ? ' an '
          : ' a '}
        {topLevelPolicy.name} AI Model
      </div>
      <div className="flex flex-row items-center self-start gap-4">
        <Select onValueChange={setSelectedPolicyId}>
          <SelectTrigger className="bg-white w-72">
            <SelectValue placeholder="Select a policy" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectItem key={topLevelPolicy.id} value={topLevelPolicy.id}>
                {topLevelPolicy.name}
              </SelectItem>
              {subPolicies.map((policy) => (
                <SelectItem key={policy.id} value={policy.id}>
                  {topLevelPolicy.name}: {policy.name}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
        <Button
          className="hover:no-underline hover:text-primary/70"
          variant="link"
          disabled={selectedPolicyId === undefined}
          onClick={() => {
            if (isDemoOrg) {
              setModalVisible(true);
            } else {
              navigate(
                `/training/select_modality?policyId=${selectedPolicyId}`,
              );
            }
          }}
        >
          Continue
        </Button>
        {cannotCreateModelModal}
      </div>
    </div>
  );
}

function ModelCard(props: {
  policy: TreeNode<Policy>;
  model: Pick<GQLCoveModel, 'id' | 'itemRequirement' | 'createdAt' | 'alias'>;
}) {
  const { policy, model } = props;
  const { alias } = model;
  const navigate = useNavigate();

  return (
    <div
      className="flex flex-col p-6 my-4 mr-4 bg-white border border-gray-200 border-solid rounded-lg cursor-pointer border-px basis-5/12"
      key={model.id}
      onClick={() => {
        navigate(`/dashboard/models_and_policies/model/${model.id}`);
      }}
    >
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center gap-2">
          <div className="text-xl font-semibold">{policy.value.name}</div>
          {alias && (
            <CoveBadge
              colorVariant={alias === 'LIVE' ? 'soft-green' : 'soft-yellow'}
              shapeVariant="rounded"
              label={alias}
            />
          )}
        </div>
        <ModelModalityIcons itemRequirement={model.itemRequirement} />
      </div>
      <div className="pt-2">
        {(() => {
          switch (model.itemRequirement) {
            case GQLItemRequirement.HasImages:
              return 'Monomodal: Images';
            case GQLItemRequirement.HasTextOrImagesOrVideos:
              return 'Multimodal: Text, Images, and Videos';
            case GQLItemRequirement.HasText:
              return 'Monomodal: Text';
          }
        })()}
      </div>
      <div>
        Created at:{' '}
        {parseDatetimeToReadableStringInCurrentTimeZone(model.createdAt)}
      </div>
      {Object.keys(tripadvisorDemoModelConfigs).includes(policy.value.id) ? (
        <div className="flex gap-2">
          <div>
            Precision:{' '}
            {decimalToPercentage(
              tripadvisorDemoModelConfigs[policy.value.id].precision,
            )}
          </div>
          •
          <div>
            Recall:{' '}
            {decimalToPercentage(
              tripadvisorDemoModelConfigs[policy.value.id].recall,
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default function ModelsDashboard() {
  const { data, loading, error } = useGQLModelsDashboardQuery();
  const { data: hasAccess, loading: hasAccessLoading } =
    useGQLGetOrgIsReadyToCreateCoveModelsQuery();
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState(false);

  if (loading || hasAccessLoading) {
    return <FullScreenLoading />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const policies = data?.myOrg?.policies ?? [];
  const models = data?.myOrg?.models ?? [];
  const isDemoOrg = data?.myOrg?.isDemoOrg ?? false;

  const createModelModal = (
    <CoveModal
      title="Create a Custom AI Model"
      visible={modalVisible}
      onClose={() => setModalVisible(false)}
    >
      {isDemoOrg ? (
        <div className="flex flex-col">
          This is a demo account, so you cannot create new models. Please let us
          know if you'd like to create additional models as part of your trial
          or proof of concept!
        </div>
      ) : (
        <SelectPolicyAndCreateModel policies={policies} />
      )}
    </CoveModal>
  );

  const createButton = (
    <Button onClick={() => setModalVisible(true)}>Create New Model</Button>
  );

  if (!hasAccess?.getOrgIsReadyToCreateCoveModels) {
    return (
      <div className="flex flex-col gap-4">
        <Helmet>
          <title>Models</title>
        </Helmet>
        <DashboardHeader title="Custom AI Models" />
        <div className="flex items-start justify-center w-full h-full">
          <div className="flex flex-col items-center justify-center p-12 mt-4 bg-white shadow rounded-xl">
            <div className="pb-3 text-8xl">
              <SparklesFilled className="w-8 h-8 fill-primary text-primary" />
            </div>
            <div className="text-2xl max-w-[440px] pb-2">
              Get Started with Cove AI
            </div>
            <div className="max-w-[440px] pt-2 px-4 text-center text-slate-500">
              Your organization does not have access to Custom AI Models yet.
              Contact the Cove team at support@getcove.com to get started.
            </div>
          </div>
        </div>
      </div>
    );
  }

  const policyTree = treeFromList<Policy>(
    policies,
    { id: '-1', name: 'root', penalty: GQLUserPenaltySeverity.None },
    (policy) => ({
      id: policy.id,
      name: policy.name,
      penalty: policy.penalty,
      policyText: policy.policyText,
      enforcementGuidelines: policy.enforcementGuidelines,
    }),
  );

  return (
    <div className="flex flex-col gap-4">
      <Helmet>
        <title>Models</title>
      </Helmet>
      <DashboardHeader
        title="Custom AI Models"
        subtitle="All your policies with Custom AI Models are listed below. You can manage, upgrade, or see model performance for each model. Each model is associated with one policy or sub-policy."
        rightComponent={createButton}
      />
      {models.length === 0 ? (
        <SelectPolicyAndCreateModel
          title="Create your first Custom AI Model"
          policies={policies}
        />
      ) : (
        policyTree.root.children.map((policy) => {
          const allChildren = policy.children
            .flatMap((child) => child.children)
            .concat(policy.children);
          const topLevelModels = models
            .filter(
              (model) =>
                model.policy.id === policy.value.id && model.alias != null,
            )
            .sort((a, b) =>
              b.createdAt.toString().localeCompare(a.createdAt.toString()),
            );
          const mostRecentModels = topLevelModels;
          const childModelCards = filterNullOrUndefined(
            allChildren.map((child) => {
              const childModels = models
                .filter(
                  (model) =>
                    model.policy.id === child.value.id && model.alias != null,
                )
                .sort((a, b) =>
                  b.createdAt.toString().localeCompare(a.createdAt.toString()),
                );

              if (childModels.length === 0) {
                return null;
              }

              return (
                <React.Fragment key={child.value.id}>
                  {childModels.map((model) => (
                    <ModelCard key={model.id} policy={child} model={model} />
                  ))}
                </React.Fragment>
              );
            }),
          );
          return (
            <div key={policy.value.id}>
              <div className="pb-2 text-lg font-semibold">
                {policy.value.name}
              </div>
              <div className="flex flex-row items-center text-base text-slate-400">
                Top level policy
                <div className="pl-4">ID:</div>
                <CopyTextComponent value={policy.value.id} />
                <div className="pl-4" />
                <Button
                  className="!fill-none"
                  variant="link"
                  endIcon={ExternalLink}
                  onClick={() =>
                    navigate(
                      `/dashboard/models_and_policies/policies/form/${policy.value.id}`,
                    )
                  }
                >
                  Manage Policy
                </Button>
              </div>
              <div className="flex flex-wrap">
                {mostRecentModels.map((model) => (
                  <ModelCard key={model.id} policy={policy} model={model} />
                ))}
                <CreateAnotherModel
                  subPolicies={allChildren.map((child) => ({
                    id: child.value.id,
                    name: child.value.name,
                  }))}
                  topLevelPolicy={policy.value}
                  hasModels={mostRecentModels.length > 0}
                  onSubmit={() => {}}
                  isDemoOrg={isDemoOrg}
                />
              </div>
              {childModelCards.length > 0 && (
                <div>
                  <div className="py-4 text-gray-500">Sub-Policy models</div>
                  <div className="flex flex-wrap">{childModelCards}</div>
                </div>
              )}
            </div>
          );
        })
      )}
      {createModelModal}
    </div>
  );
}
