import { decimalToPercentage } from '@/utils/number';
import { parseDatetimeToMonthDayYearDateStringInCurrentTimeZone } from '@/utils/time';
import { CircleHelp } from 'lucide-react';
import { useParams } from 'react-router-dom';

import { tripadvisorDemoModelConfigs } from '../customer_utils/tripadvisor';

function ModelMetricCard(props: {
  title: string;
  value: string;
  lastMeasured: string;
}) {
  const { title, value, lastMeasured } = props;

  return (
    <div className="flex flex-col flex-grow gap-4 p-4 bg-white border border-gray-300 rounded-md">
      <div className="flex flex-row items-center justify-between">
        <div className="font-bold">{title}</div>
        <CircleHelp className="w-4 h-4" />
      </div>
      <div className="text-3xl font-bold">{value}</div>
      <div className="text-sm text-gray-500">Last Measured: {lastMeasured}</div>
    </div>
  );
}

export default function ModelPerformanceMetrics() {
  const { policyId } = useParams<{
    policyId: string;
    modelVersion: string;
    modelId: string;
  }>();

  if (!policyId) {
    throw new Error('No policy ID provided');
  }

  const precision =
    tripadvisorDemoModelConfigs[policyId]?.precision ?? undefined;
  const recall = tripadvisorDemoModelConfigs[policyId]?.recall ?? undefined;
  const f1Score =
    precision && recall
      ? ((2 * (precision * recall)) / (precision + recall)).toFixed(2)
      : undefined;

  if (!precision && !recall) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <div className="flex flex-col items-center justify-center p-12 bg-white shadow-md rounded-xl text-slate-500">
          <div className="text-slate-500 text-3xl max-w-[400px] pb-2">
            No Model Metrics
          </div>
          <div className="text-slate-500 text-base pt-2 max-w-[400px]">
            Please contact Cove to get metrics for your models.
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-row gap-8">
      <ModelMetricCard
        title="Precision"
        value={decimalToPercentage(precision)}
        lastMeasured={parseDatetimeToMonthDayYearDateStringInCurrentTimeZone(
          new Date(),
        )}
      />
      <ModelMetricCard
        title="Recall"
        value={decimalToPercentage(recall)}
        lastMeasured={parseDatetimeToMonthDayYearDateStringInCurrentTimeZone(
          new Date(),
        )}
      />
      <ModelMetricCard
        title="F1"
        value={`${f1Score}`}
        lastMeasured={parseDatetimeToMonthDayYearDateStringInCurrentTimeZone(
          new Date(),
        )}
      />
    </div>
  );
}
