import { gql } from '@apollo/client';
import { Button, DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import { Row } from 'react-table';

import CoveModal from '../../../components/CoveModal';
import { CoveModalFooterButtonProps } from '../../../components/CoveModalFooter';
import FormSectionHeader from '../../../components/FormSectionHeader';
import CoveButton from '@/webpages/dashboard/components/CoveButton';

import { useGQLRunRetroactionMutation } from '../../../../../graphql/generated';
import { DAY, HOUR, WEEK } from '../../../../../utils/time';
import RuleRetroactionResultsTable from './RuleRetroactionResultsTable';
import RuleRetroactionResultView from './RuleRetroactionResultView';

enum RetroactionShortcut {
  PAST_HOUR = 'Past 1 hour',
  PAST_THREE_HOURS = 'Past 3 hours',
  PAST_TWELVE_HOURS = 'Past 12 hours',
  PAST_DAY = 'Past day',
  PAST_THREE_DAYS = 'Past 3 days',
  PAST_WEEK = 'Past week',
  PAST_TWO_WEEKS = 'Past 2 weeks',
}

gql`
  mutation RunRetroaction($input: RunRetroactionInput!) {
    runRetroaction(input: $input) {
      ... on RunRetroactionSuccessResponse {
        _
      }
    }
  }
`;

export default function RuleRetroaction(props: { ruleId: string }) {
  const { ruleId } = props;

  const [startTime, setStartTime] = useState<Moment | undefined>(undefined);
  const [endTime, setEndTime] = useState<Moment | undefined>(undefined);
  const [resultsVisible, setResultsVisible] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [results, setResults] = useState<string[]>([]);
  const [selectedRow, setSelectedRow] = useState<
    Row<{ id: string; actions: string[] }> | undefined
  >(undefined);
  const [modalVisible, setModalVisible] = useState(false);

  const [runRetroaction] = useGQLRunRetroactionMutation({
    onError: (error) => console.log(error),
    onCompleted: (result) => {
      console.log(result.runRetroaction);
    },
  });

  const onRunRetroaction = async () => {
    setResultsVisible(true);
    setModalVisible(true);

    runRetroaction({
      variables: {
        input: {
          ruleId,
          startAt: startTime!.toString(),
          endAt: endTime!.toString(),
        },
      },
    });
  };

  const header = (
    <div className="flex flex-col items-start pt-4 text-start">
      <div className="text-2xl font-semibold">Retroaction</div>
      <div className="w-4/5 text-sm text-slate-500">
        Retroaction allows you to run your rule on historical data, and execute
        any actions that you've configured in the rule.
        <br />
        <br />
        <span className="font-semibold">IMPORTANT: </span>Retroaction will
        actually execute actions on content caught by your rule, so you should
        only run it once you're sure your rule is configured correctly.
      </div>
    </div>
  );

  const shortcutButton = (
    shortcutLength: RetroactionShortcut,
    unixtime: number,
  ) => {
    return (
      <Button
        key={shortcutLength}
        className="px-2 mx-1 text-sm font-medium rounded"
        onClick={() => {
          setStartTime(moment.unix(unixtime / 1000));
          setEndTime(moment());
        }}
      >
        {shortcutLength}
      </Button>
    );
  };

  const timeWindowSection = (
    <div className="flex flex-col items-start">
      <FormSectionHeader
        title="Select a Time Window"
        subtitle={
          <div>
            Select the time window over which you want to run your Retroaction.
            We will pull all of the content you sent us during that time window,
            run it through this rule, and log which content the rule would have
            actioned on if it had been turned on during that time window.
            <br />
            <br />
            Retroaction can take some time to run, especially if you select a
            large time window. We'll notify you when it has completed, and you
            can come back here to inspect the results.
          </div>
        }
      />
      <div className="flex flex-col justify-start m-6 gap-4">
        <div className="flex items-center justify-between">
          <div className="mr-4 font-medium text-start">Start:</div>
          <DatePicker
            value={startTime}
            showTime={{ format: 'HH:mm' }}
            format="YYYY-MM-DD HH:mm"
            onOk={setStartTime}
            className="mr-1 rounded"
          />
        </div>
        <div className="flex items-center justify-between">
          <div className="mr-4 font-medium text-start">End:</div>
          <DatePicker
            value={endTime}
            showTime={{ format: 'HH:mm' }}
            format="YYYY-MM-DD HH:mm"
            onOk={setEndTime}
            className="mr-1 rounded"
          />
        </div>
      </div>
      <div className="flex items-center mb-2">
        <div className="mr-4 text-sm font-medium">Shortcuts: </div>
        {shortcutButton(RetroactionShortcut.PAST_HOUR, Date.now() - HOUR)}
        {shortcutButton(
          RetroactionShortcut.PAST_THREE_HOURS,
          Date.now() - 3 * HOUR,
        )}
        {shortcutButton(
          RetroactionShortcut.PAST_TWELVE_HOURS,
          Date.now() - 12 * HOUR,
        )}
        {shortcutButton(RetroactionShortcut.PAST_DAY, Date.now() - DAY)}
        {shortcutButton(
          RetroactionShortcut.PAST_THREE_DAYS,
          Date.now() - 3 * DAY,
        )}
        {shortcutButton(RetroactionShortcut.PAST_WEEK, Date.now() - WEEK)}
        {shortcutButton(
          RetroactionShortcut.PAST_TWO_WEEKS,
          Date.now() - 2 * WEEK,
        )}
      </div>
    </div>
  );

  const modalFooter: CoveModalFooterButtonProps[] = [
    {
      title: 'OK',
      type: 'primary',
      onClick: () => setModalVisible(false),
    },
  ];

  const modal = (
    <CoveModal
      title="Retroaction job started"
      visible={modalVisible}
      onClose={() => setModalVisible(false)}
      footer={modalFooter}
    >
      Your retroaction job is in progress! You should see results soon.
    </CoveModal>
  );

  return (
    <div className="flex flex-col">
      {header}
      <div className="my-6 divider" />
      {timeWindowSection}
      <div className="my-6 divider" />
      <CoveButton
        title="Run Retroaction"
        onClick={onRunRetroaction}
        disabled={
          startTime == null ||
          endTime == null ||
          !endTime.isSameOrBefore(startTime)
        }
        disabledTooltipTitle={
          endTime!.isSameOrBefore(startTime)
            ? 'End time must be later than start time'
            : undefined
        }
      />
      {resultsVisible ? (
        <>
          <div className="my-6 divider" />
          <div className="flex mt-6">
            <div
              className="flex flex-col mr-4"
              style={selectedRow != null ? { width: '60%' } : {}}
            >
              <RuleRetroactionResultsTable
                onSelectRow={(row) => setSelectedRow(row)}
                results={results}
              />
            </div>
            {selectedRow && (
              <div className="flex flex-col ml-4">
                <RuleRetroactionResultView selectedRow={selectedRow} />
              </div>
            )}
          </div>
        </>
      ) : null}
      {modal}
    </div>
  );
}
