import { ChevronDown, ChevronUp } from '@/icons';
import { CheckOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import ComponentLoading from '../../../../../../components/common/ComponentLoading';
import { selectFilterByLabelOption } from '@/webpages/dashboard/components/antDesignUtils';

import {
  GQLGetSamplingJobResultsQuery,
  useGQLActionsQuery,
  useGQLGetItemTypesByIdentifiersQuery,
} from '../../../../../../graphql/generated';
import BlueThresholdDrag from '../../../../../../images/BlueThresholdDrag.png';
import OrangeThresholdDrag from '../../../../../../images/OrangeThresholdDrag.png';
import PurpleThresholdDrag from '../../../../../../images/PurpleThresholdDrag.png';
import RedThresholdDrag from '../../../../../../images/RedThresholdDrag.png';
import YellowThresholdDrag from '../../../../../../images/YellowThresholdDrag.png';
import { getPrimaryContentFields } from '../../../../../../utils/itemUtils';
import FieldsComponent from '../../../../mrt/manual_review_job/v2/ManualReviewJobFieldsComponent';

const { Option } = Select;

type EntryItem = Extract<
  GQLGetSamplingJobResultsQuery['getSamplingJobResults'],
  { __typename: 'SamplingJobSuccess' }
>['samples'][0]['item'];

export type Entry = {
  item: EntryItem;
  score: number;
};

export type Threshold = {
  action: {
    id: string;
    name: string;
  };
  score: number;
  inEditMode: boolean;
};

export default function TrainingPipelineSetThresholdsDragAndDropComponent(props: {
  entries: Entry[];
  thresholds: Threshold[];
  setThresholds: (thresholds: Threshold[]) => void;
}) {
  const { entries, thresholds, setThresholds } = props;
  const {
    loading: itemTypesLoading,
    error: itemTypesError,
    data: itemTypesData,
  } = useGQLGetItemTypesByIdentifiersQuery({
    variables: {
      identifiers: _.uniqBy(
        entries.map((entry) => ({
          id: entry.item.itemType.id,
          version: entry.item.itemType.version,
          schemaVariant: 'ORIGINAL',
        })),
        (it) => `${it.id}-${it.version}`,
      ),
    },
  });
  const itemTypes = itemTypesData?.itemTypes;
  const {
    loading: actionsLoading,
    error: actionsError,
    data: actionsData,
  } = useGQLActionsQuery();
  const allOrgActions = actionsData?.myOrg?.actions;

  const initialOrderedEntries: (Entry | Threshold)[] = _.reverse(
    _.sortBy(entries, 'score'),
  );

  const [orderedComponents, setOrderedComponents] = useState<
    (Entry | Threshold)[]
  >(initialOrderedEntries);

  useEffect(() => {
    if (allOrgActions) {
      // Start with (at most) two thresholds set to the first two actions in the
      // allOrgActions array. Their scores are set to -1 because we'll update
      // them to more fitting initial scores as we insert them into their
      // respective places in the orderedComponents array.
      const initialThresholds = allOrgActions.slice(0, 2).map((action) => ({
        action: _.pick(action, ['id', 'name']),
        score: -1,
        inEditMode: false,
      }));
      // Now we insert the thresholds into the orderedComponents array, and
      // update their scores based on the scores of their neighbors.
      initialThresholds.forEach((threshold, i) => {
        const indexToInsert = 5 * (i + 1);
        const score = initialOrderedEntries[indexToInsert - 1].score;
        initialOrderedEntries.splice(indexToInsert, 0, {
          action: threshold.action,
          score,
          inEditMode: false,
        });
        initialThresholds[i] = {
          ...initialThresholds[i],
          score,
        };
      });
      // Lastly, we save the new thresholds and orderedComponents
      setThresholds(initialThresholds);
      setOrderedComponents(initialOrderedEntries);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allOrgActions]); // We only want this to run once when allOrgActions is available

  const thresholdsInRelativeOrder = orderedComponents.filter(
    (it): it is Threshold => 'action' in it,
  );

  const getStylesForThresholdIndex = useCallback(
    (index: number) => {
      switch (index) {
        case 0:
          return {
            src: RedThresholdDrag,
            bgColor: 'bg-[#EF4444]',
            borderColor: 'border-[#EF4444]',
          };
        case 1:
          return {
            src: OrangeThresholdDrag,
            bgColor: 'bg-[#F59E0B]',
            borderColor: 'border-[#F59E0B]',
          };
        case 2:
          return {
            src: YellowThresholdDrag,
            bgColor: 'bg-[#F5D00B]',
            borderColor: 'border-[#F5D00B]',
          };
        case 3:
          return {
            src: BlueThresholdDrag,
            bgColor: 'bg-cove-blue',
            borderColor: 'border-cove-blue',
          };
        case 4:
          return {
            src: PurpleThresholdDrag,
            bgColor: 'bg-cove-purple',
            borderColor: 'border-cove-purple',
          };
        default:
          throw new Error('Cannot have a 6th threshold');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [entries],
  );

  const updateThresholdComponent = (
    newThreshold: Threshold,
    indexInThresholdList: number,
    indexInAllComponents: number,
  ) => {
    const newThresholds = [...thresholdsInRelativeOrder];
    newThresholds.splice(indexInThresholdList, 1, newThreshold);
    const resultingList = [...orderedComponents];
    resultingList.splice(
      indexInAllComponents,
      1,
      newThresholds[indexInThresholdList],
    );
    setThresholds(newThresholds);
    setOrderedComponents(resultingList);
  };

  const deleteThresholdComponent = (
    indexInThresholdList: number,
    indexInAllComponents: number,
  ) => {
    const newThresholds = [...thresholdsInRelativeOrder];
    newThresholds.splice(indexInThresholdList, 1);
    const resultingList = [...orderedComponents];
    resultingList.splice(indexInAllComponents, 1);
    setThresholds(newThresholds);
    setOrderedComponents(resultingList);
  };

  if (itemTypesLoading || actionsLoading) {
    return <ComponentLoading />;
  }
  if (itemTypesError || actionsError) {
    throw itemTypesError ?? actionsError!;
  }

  return (
    <div className="self-center w-3/5 ml-60">
      <div className="mb-8 text-sm font-semibold text-slate-500">
        most likely to violate policy
      </div>

      <DragDropContext
        onDragEnd={(update) => {
          const { source, destination } = update;

          if (!destination || source.index === destination.index) {
            return;
          }
          let finalDestinationIndex = destination.index;

          if (
            orderedComponents[destination.index] &&
            'action' in orderedComponents[destination.index]
          ) {
            // We're trying to place a threshold on top of another threshold, so force it to one space
            // away, and prefer the direction from which the dragged threshold came (e.g. if we're
            // dragging threshold 1 down and collide with threshold 2, move threshold 1 *up* one spot).
            if (source.index < destination.index) {
              // Threshold came from above, so force it up one spot
              finalDestinationIndex = destination.index - 1;
            } else {
              // Threshold came from below, so force it down one spot
              finalDestinationIndex = destination.index + 1;
            }
          }

          const resultingList = [...orderedComponents];
          // Get the threshold and assign it the score of where it will soon be positioned
          const newThreshold = {
            ...resultingList[source.index],
            score:
              finalDestinationIndex === 0
                ? 1
                : orderedComponents[
                    // The new score we assign to the threshold entry should be equal to
                    // the score of the element right before its final destination (so
                    // the threshold represents all the scores greater than or equal to
                    // that previous element). The element right before the final destination
                    // depends on whether the threshold is being dragged up or down. For example,
                    // if the array is [0, 1, 2, threshold, 4, 5, 6] and we drag the threshold
                    // to position 5 (i.e. finalDestinationIndex === 5), we want to set the
                    // newThreshold's score to the same score as element 5's score, and that index
                    // is finalDestinationIndex. If, on the other hand, we drag the threshold to
                    // position 1, we have to shift elements 1 & 2 to the right and set newThreshold's
                    // score to the same score as element 0's score. element 0 is at index
                    // finalDestinationIndex - 1.
                    source.index > finalDestinationIndex
                      ? finalDestinationIndex - 1
                      : finalDestinationIndex
                  ].score,
          };
          // Remove the threshold element from the list, then add it back at the finalDestinationIndex
          resultingList.splice(source.index, 1);
          resultingList.splice(finalDestinationIndex, 0, newThreshold);
          // Save threshold values for parent component
          const newThresholds = [...thresholds];
          const updatedThresholdIndex = newThresholds.findIndex(
            (it) => it.score === orderedComponents[source.index].score,
          )!;
          newThresholds[updatedThresholdIndex] = {
            ...newThresholds[updatedThresholdIndex],
            score: newThreshold.score,
          };
          setThresholds(newThresholds);
          // Finally, save the new ordered list of components
          setOrderedComponents(resultingList);
        }}
      >
        <Droppable droppableId="list">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {orderedComponents.map((entry, i) => (
                <Draggable
                  draggableId={
                    'item' in entry ? entry.item.itemId : entry.action.id
                  }
                  key={'item' in entry ? entry.item.itemId : entry.action.id}
                  index={i}
                  isDragDisabled={'item' in entry}
                >
                  {(provided) => {
                    if ('item' in entry) {
                      const values = Object.values(entry.item.data);
                      const itemType = itemTypes?.find(
                        (it) => entry.item.itemType.id === it.id,
                      );
                      const primaryFields = getPrimaryContentFields(
                        itemType?.baseFields ?? [],
                        entry.item.data,
                      ).filter(
                        (it) =>
                          it.value != null &&
                          !(Array.isArray(it.value) && it.value.length === 0),
                      );
                      const content =
                        values.length === 1 ? (
                          String(values[0])
                        ) : itemType ? (
                          <FieldsComponent
                            fields={primaryFields}
                            itemTypeId={entry.item.itemType.id}
                            options={{
                              hideLabels: primaryFields.length === 1,
                            }}
                          />
                        ) : (
                          JSON.stringify(entry.item.data, null, 4)
                        );

                      const getColorForEntry = (entry: Entry) => {
                        if (
                          thresholdsInRelativeOrder.length === 0 ||
                          entry.score < thresholdsInRelativeOrder.at(-1)!.score
                        ) {
                          return 'border-[#10B981]';
                        } else if (thresholdsInRelativeOrder.length === 1) {
                          return entry.score >=
                            thresholdsInRelativeOrder[0].score
                            ? 'border-[#EF4444]'
                            : 'border-[#10B981]';
                        }
                        for (
                          let i = thresholdsInRelativeOrder.length - 1;
                          i > 0;
                          i--
                        ) {
                          if (
                            entry.score >= thresholdsInRelativeOrder[i].score &&
                            entry.score < thresholdsInRelativeOrder[i - 1].score
                          ) {
                            return getStylesForThresholdIndex(i).borderColor;
                          }
                        }
                        return 'border-[#EF4444]';
                      };

                      return (
                        <div
                          key={`${entry.item.itemId}-pill`}
                          className={`flex items-center justify-center relative`}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <div
                            className={`flex text-sm text-start grow max-w-4xl overflow-scroll flex-col items-start justify-center border-solid border-0 -my-4 py-8 pl-6 border-l-2 ${getColorForEntry(
                              entry,
                            )}`}
                          >
                            {i === 0 ? (
                              <ChevronUp className="absolute fill-[#EF4444] -ml-[32px] -top-6 w-6" />
                            ) : null}
                            <div className="pb-1.5 text-slate-500 text-xs">
                              {entry.score.toFixed(3)}
                            </div>
                            <div className="w-full p-2 overflow-y-scroll rounded max-h-64 bg-slate-100">
                              {content}
                            </div>
                            {i === orderedComponents.length - 1 ? (
                              <ChevronDown className="absolute fill-[#10B981] -ml-[32px] -bottom-6 w-6" />
                            ) : null}
                          </div>
                        </div>
                      );
                    } else {
                      const thresholdIndex =
                        thresholdsInRelativeOrder.findIndex(
                          (it) => it.action.id === entry.action.id,
                        );
                      const styles = getStylesForThresholdIndex(thresholdIndex);

                      return (
                        <div
                          className="-ml-10"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <div className="flex flex-row items-center ml-3 text-start">
                            <div
                              className="z-10 cursor-grab active:cursor-grabbing"
                              {...provided.dragHandleProps}
                            >
                              <div className="relative">
                                <img alt="Drag" src={styles?.src} width={74} />
                                <div className="absolute">
                                  <div
                                    className={`relative flex p-4 rounded-md justify-between gap-4 border border-solid bg-[#F5FAFF] items-center bottom-12 w-72 -left-80 ${styles.borderColor}`}
                                  >
                                    {entry.inEditMode ? (
                                      <div className="flex items-center justify-between w-full">
                                        <Select
                                          className="w-52"
                                          placeholder="Select action"
                                          allowClear
                                          showSearch
                                          filterOption={
                                            selectFilterByLabelOption
                                          }
                                          dropdownMatchSelectWidth={false}
                                          value={entry.action.id}
                                          onChange={(value: string) =>
                                            updateThresholdComponent(
                                              {
                                                ...entry,
                                                action: _.pick(
                                                  allOrgActions!.find(
                                                    (it) => it.id === value,
                                                  )!,
                                                  ['id', 'name'],
                                                ),
                                              },
                                              thresholdIndex,
                                              i,
                                            )
                                          }
                                        >
                                          {allOrgActions?.map((action) => {
                                            return (
                                              <Option
                                                key={action.id}
                                                value={action.id}
                                                label={action.name}
                                              >
                                                {action.name}
                                              </Option>
                                            );
                                          })}
                                        </Select>
                                        <div className="flex flex-col">
                                          <CheckOutlined
                                            className="!text-cove-success-green cursor-pointer p-2"
                                            onClick={() =>
                                              updateThresholdComponent(
                                                {
                                                  ...entry,
                                                  inEditMode: false,
                                                },
                                                thresholdIndex,
                                                i,
                                              )
                                            }
                                          />
                                          <DeleteOutlined
                                            className="!text-cove-red cursor-pointer p-2"
                                            onClick={() =>
                                              deleteThresholdComponent(
                                                thresholdIndex,
                                                i,
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="flex items-center justify-between w-full">
                                        <div className="text-base h-fit">
                                          {entry.action.name}
                                        </div>
                                        {/* TODO: Estimate prevalence */}
                                        {/* <div>~100 / day</div> */}
                                        <EditOutlined
                                          className="p-2 cursor-pointer text-cove-blue hover:text-cove-blue-hover"
                                          onClick={() =>
                                            updateThresholdComponent(
                                              {
                                                ...entry,
                                                inEditMode: true,
                                              },
                                              thresholdIndex,
                                              i,
                                            )
                                          }
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={`w-full -ml-2 h-0.5 z-10 ${styles.bgColor}`}
                            />
                          </div>
                        </div>
                      );
                    }
                  }}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className="mt-8 text-sm font-semibold text-slate-500">
        least likely to violate policy
      </div>
    </div>
  );
}
