import { cn } from '@/lib/utils';
import { ReloadIcon } from '@radix-ui/react-icons';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-primary text-primary-foreground hover:bg-indigo-800',
        destructive:
          'bg-destructive text-destructive-foreground hover:bg-red-600',
        outline:
          'border border-primary text-primary bg-white hover:text-indigo-800 hover:border-indigo-800',
        secondary:
          'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary hover:text-indigo-800',
      },
      size: {
        default: 'h-9 px-4 py-2',
        sm: 'h-8 rounded-md px-3 text-xs',
        lg: 'h-10 rounded-md px-8',
        icon: 'h-9 w-9',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
  startIcon?: React.JSXElementConstructor<React.SVGProps<SVGSVGElement>>;
  endIcon?: React.JSXElementConstructor<React.SVGProps<SVGSVGElement>>;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      loading = false,
      startIcon: StartIcon,
      endIcon: EndIcon,
      children,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button';

    const content = loading ? (
      <>
        <ReloadIcon
          data-testid="loading-spinner"
          className="w-3 h-3 mr-2 animate-spin fill-inherit text-inherit"
        />
        {children}
      </>
    ) : (
      <>
        {StartIcon && !loading && (
          <span className="flex items-center justify-center mr-2">
            {<StartIcon className="w-3 h-3 fill-inherit text-inherit" />}
          </span>
        )}
        {children}
        {EndIcon && !loading && (
          <span className="ml-2">
            {<EndIcon className="w-3 h-3 fill-inherit text-inherit" />}
          </span>
        )}
      </>
    );

    const wrappedContent =
      asChild && loading ? <span>{content}</span> : content;

    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }), {
          'opacity-75 pointer-events-none': loading,
        })}
        ref={ref}
        disabled={loading || props.disabled}
        {...props}
      >
        {wrappedContent}
      </Comp>
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
