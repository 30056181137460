import { Drawer, DrawerContent, DrawerPortal } from '@/cove-ui/Drawer';
import { Close } from '@/icons';
import HTMLRenderer from '@/webpages/dashboard/policies/HTMLRenderer';
import { useState } from 'react';

import TabBar from '@/webpages/dashboard/components/TabBar';

export default function TrainingPipelinePolicyDrawer(props: {
  policy: { name: string; policyText: string; enforcementGuidelines: string };
  open: boolean;
  onClose: () => void;
}) {
  const { policy, open, onClose } = props;

  const [selectedTab, setSelectedTab] = useState<
    'policy_definition' | 'enforcement_guidelines'
  >('policy_definition');

  const close = () => {
    setSelectedTab('policy_definition');
    onClose();
  };

  return (
    <Drawer open={open} onClose={close}>
      <DrawerPortal>
        <DrawerContent
          onKeyDown={(e) => {
            if (e.key === 'Escape') {
              props.onClose();
            }

            if (e.key === '1') {
              setSelectedTab('policy_definition');
            }

            if (e.key === '2') {
              setSelectedTab('enforcement_guidelines');
            }
          }}
        >
          <div className="flex flex-col px-12 pb-24 gap-2">
            <div
              className="self-end mt-4 mr-4 cursor-pointer"
              onClick={() => props.onClose()}
            >
              <Close className="w-4 h-4" />
            </div>
            <div className="text-base font-semibold">{policy.name}</div>
            <TabBar
              tabs={[
                {
                  label: 'Definition',
                  icon: (
                    <div className="px-2 py-1 text-xs text-gray-400 border border-gray-400 border-solid rounded-md">
                      1
                    </div>
                  ),
                  value: 'policy_definition',
                },
                {
                  label: 'Enforcement Guidelines',
                  icon: (
                    <div className="px-2 py-1 text-xs text-gray-400 border border-gray-400 border-solid rounded-md">
                      2
                    </div>
                  ),
                  value: 'enforcement_guidelines',
                },
              ]}
              initialSelectedTab={selectedTab}
              onTabClick={(val) => setSelectedTab(val)}
              currentSelectedTab={selectedTab}
            />
            {selectedTab === 'policy_definition' ? (
              <div className="overflow-auto text-base h-60">
                <HTMLRenderer
                  rawHTML={
                    policy.policyText ?? 'Could not fetch policy definition'
                  }
                />
              </div>
            ) : (
              <div className="overflow-auto text-base h-60">
                <HTMLRenderer
                  rawHTML={
                    policy.enforcementGuidelines ??
                    'There are no enforcement guidelines for this policy'
                  }
                />
              </div>
            )}
          </div>
        </DrawerContent>
      </DrawerPortal>
    </Drawer>
  );
}
