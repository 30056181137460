import { Button, DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import { Row } from 'react-table';

import FormSectionHeader from '../../../components/FormSectionHeader';
import CoveButton from '@/webpages/dashboard/components/CoveButton';

import { DAY, HOUR, WEEK } from '../../../../../utils/time';
import RuleBacktestResultsTable from './RuleBacktestResultsTable';
import RuleBacktestResultView from './RuleBacktestResultView';

enum BacktestShortcut {
  PAST_HOUR = 'Past 1 hour',
  PAST_THREE_HOURS = 'Past 3 hours',
  PAST_TWELVE_HOURS = 'Past 12 hours',
  PAST_DAY = 'Past day',
  PAST_THREE_DAYS = 'Past 3 days',
  PAST_WEEK = 'Past week',
  PAST_TWO_WEEKS = 'Past 2 weeks',
}

export default function RuleBacktest() {
  const [startTime, setStartTime] = useState<Moment | undefined>(undefined);
  const [endTime, setEndTime] = useState<Moment | undefined>(undefined);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [resultsVisible, setResultsVisible] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [results, setResults] = useState<string[]>([]);
  const [selectedRow, setSelectedRow] = useState<Row<any> | undefined>(
    undefined,
  );

  const header = (
    <div className="flex flex-col items-start pt-4 text-start">
      <div className="text-2xl font-semibold">Backtest</div>
      <div className="w-4/5 text-sm text-slate-500">
        Backtests allow you to test your rule on historical data. Backtests will
        never execute any actions or mutate any data, so you can run them
        without worry.
      </div>
    </div>
  );

  const shortcutButton = (
    shortcutLength: BacktestShortcut,
    unixtime: number,
  ) => {
    return (
      <Button
        key={shortcutLength}
        className="px-2 mx-1 text-sm font-medium rounded"
        onClick={() => {
          setStartTime(moment.unix(unixtime / 1000));
          setEndTime(moment());
        }}
      >
        {shortcutLength}
      </Button>
    );
  };

  const timeWindowSection = (
    <div className="flex flex-col items-start">
      <FormSectionHeader
        title="Select a Time Window"
        subtitle={
          <div>
            Select the time window over which you want to run your backtest. We
            will pull all of the content you sent us during that time window,
            run it through this rule, and log which content the rule would have
            actioned on if it had been turned on during that time window.
            <br />
            <br />
            Backtests can take some time to run, especially if you select a
            large time window. We'll notify you when it has completed, and you
            can come back here to inspect the results.
          </div>
        }
      />
      <div className="flex flex-col justify-start m-6 gap-4">
        <div className="flex items-center justify-between">
          <div className="mr-4 font-medium text-start">Start:</div>
          <DatePicker
            value={startTime}
            showTime={{ format: 'HH:mm' }}
            format="YYYY-MM-DD HH:mm"
            onOk={setStartTime}
            className="mr-1 rounded"
          />
        </div>
        <div className="flex items-center justify-between">
          <div className="mr-4 font-medium text-start">End:</div>
          <DatePicker
            value={endTime}
            showTime={{ format: 'HH:mm' }}
            format="YYYY-MM-DD HH:mm"
            onOk={setEndTime}
            className="mr-1 rounded"
          />
        </div>
      </div>
      <div className="flex items-center mb-2">
        <div className="mr-4 text-sm font-medium">Shortcuts: </div>
        {shortcutButton(BacktestShortcut.PAST_HOUR, Date.now() - HOUR)}
        {shortcutButton(
          BacktestShortcut.PAST_THREE_HOURS,
          Date.now() - 3 * HOUR,
        )}
        {shortcutButton(
          BacktestShortcut.PAST_TWELVE_HOURS,
          Date.now() - 12 * HOUR,
        )}
        {shortcutButton(BacktestShortcut.PAST_DAY, Date.now() - DAY)}
        {shortcutButton(BacktestShortcut.PAST_THREE_DAYS, Date.now() - 3 * DAY)}
        {shortcutButton(BacktestShortcut.PAST_WEEK, Date.now() - WEEK)}
        {shortcutButton(BacktestShortcut.PAST_TWO_WEEKS, Date.now() - 2 * WEEK)}
      </div>
    </div>
  );

  return (
    <div className="flex flex-col">
      {header}
      <div className="my-6 divider" />
      {timeWindowSection}
      <div className="my-6 divider" />
      <CoveButton
        title="Run Backtest"
        onClick={() => {
          // TODO: Fill in
        }}
        disabled={
          startTime == null ||
          endTime == null ||
          !endTime.isSameOrBefore(startTime)
        }
      />
      {resultsVisible ? (
        <>
          <div className="my-6 divider" />
          <div className="flex mt-6">
            <div
              className="flex flex-col mr-4"
              style={selectedRow != null ? { width: '60%' } : {}}
            >
              <RuleBacktestResultsTable
                onSelectRow={(row) => setSelectedRow(row)}
                results={results}
              />
            </div>
            {selectedRow && (
              <div className="flex flex-col ml-4">
                <RuleBacktestResultView selectedRow={selectedRow} />
              </div>
            )}
          </div>
        </>
      ) : null}
    </div>
  );
}
