import LabelExamples from '../../../../../images/LabelExamples.png';
import TellPolicies from '../../../../../images/TellPolicies.png';
import UploadContent from '../../../../../images/UploadContent.png';
import DemoFooter from '../DemoFooter';

function DemoOverviewCard(props: { title: string; imagePath: string }) {
  return (
    <div className="flex flex-col justify-between border border-gray-200 border-solid rounded-xl">
      <div className="my-6 text-2xl font-semibold">{props.title}</div>
      <img
        className="object-contain max-w-full max-h-full"
        alt={props.title}
        src={props.imagePath}
      />
    </div>
  );
}

export default function DemoOverviewScreen(props: {
  goToNextScreen: () => void;
  goBack: () => void;
}) {
  const { goToNextScreen, goBack } = props;
  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex flex-col items-center justify-center w-full h-full grow">
        <div className="text-3xl font-bold">
          Create custom AI models in three steps
        </div>
        <div className="w-full px-24 py-12 grid grid-cols-3 gap-6">
          <DemoOverviewCard
            title="Upload your content"
            imagePath={UploadContent}
          />
          <DemoOverviewCard
            title="Tell us your policies"
            imagePath={TellPolicies}
          />
          <DemoOverviewCard title="Label examples" imagePath={LabelExamples} />
        </div>
      </div>
      <DemoFooter
        primaryButton={{
          title: 'Upload your content',
          onClick: goToNextScreen,
        }}
        onBack={goBack}
      />
    </div>
  );
}
