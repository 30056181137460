import {
  useGQLItemTypesQuery,
  type GQLFieldType,
  type GQLScalarType,
} from '@/graphql/generated';
import { assertUnreachable } from '@/utils/misc';
import { Checkbox, Input, Select } from 'antd';
import { useState } from 'react';

import ComponentLoading from '@/components/common/ComponentLoading';
import CoveModal from '@/webpages/dashboard/components/CoveModal';

const { Option } = Select;

const forbiddenFieldTypes = [
  'MAP',
  'DATETIME',
  'RELATED_ITEM',
  'POLICY_ID',
] satisfies GQLFieldType[] as GQLFieldType[];

export default function InternalModelTrainingAddLabelModal(props: {
  visible: boolean;
  onSave: () => void;
  onClose: () => void;
}) {
  const { visible, onSave, onClose } = props;

  const [selectedItemTypeId, setSelectedItemTypeId] = useState<
    string | undefined
  >(undefined);
  const [testData, setTestData] = useState<Record<string, string | string[]>>(
    {},
  );
  const [violatingFields, setViolatingFields] = useState<string[]>([]);

  const {
    data: itemTypesData,
    loading: itemTypesLoading,
    error: itemTypesError,
  } = useGQLItemTypesQuery();

  if (itemTypesLoading) {
    return <ComponentLoading />;
  }

  if (itemTypesError || itemTypesData == null) {
    return <div className="text-red-500">Error: could not load form data</div>;
  }

  const selectedItemType = itemTypesData?.myOrg?.itemTypes?.find(
    (itemType) => itemType.id === selectedItemTypeId,
  );

  const getPlaceholder = (
    fieldType: GQLFieldType,
    containerValueScalarType: GQLScalarType | undefined,
  ): string => {
    switch (fieldType) {
      case 'AUDIO':
        return 'https://test.com/audio.mp3';
      case 'BOOLEAN':
        return 'false';
      case 'GEOHASH':
        return '9q8yy';
      case 'ID':
      case 'NUMBER':
        return '123';
      case 'IMAGE':
        return 'https://test.com/image.jpg';
      case 'VIDEO':
        return 'https://test.com/video.mp4';
      case 'STRING':
        return 'Some text...';
      case 'URL':
        return 'https://test.com';
      case 'USER_ID':
        return 'user-id';
      case 'ARRAY':
        return `${getPlaceholder(
          containerValueScalarType!,
          undefined,
        )}, ${getPlaceholder(containerValueScalarType!, undefined)}`;
      case 'MAP':
      case 'DATETIME':
      case 'RELATED_ITEM':
      case 'POLICY_ID':
        throw new Error('Unsupported field type');
      default:
        assertUnreachable(fieldType);
    }
  };

  return (
    <CoveModal
      title={`Add Label`}
      visible={visible}
      onClose={onClose}
      footer={[
        {
          title: 'Save Label',
          type: 'primary',
          onClick: () => {
            onSave();
          },
          loading: false,
          disabled:
            selectedItemType == null ||
            selectedItemType?.baseFields?.some(
              (it) =>
                !forbiddenFieldTypes.includes(it.type) &&
                it.required &&
                !testData[it.name],
            ),
        },
      ]}
    >
      <div className="flex flex-col w-full gap-4">
        {selectedItemType ? (
          <div className="font-medium text-gray-500">
            Fill in the fields below to test the rule with sample data
          </div>
        ) : null}
        <div className="flex flex-row items-center gap-2">
          Item Type:
          <Select<string>
            placeholder="Select Item Type"
            dropdownMatchSelectWidth={false}
            onChange={(value) => {
              setSelectedItemTypeId(value);
            }}
            value={selectedItemTypeId}
          >
            {itemTypesData?.myOrg?.itemTypes?.map((itemType) => (
              <Option
                key={itemType.id}
                value={itemType.id}
                label={itemType.name}
              >
                {itemType.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="grid grid-cols-2 gap-4">
          {selectedItemType?.baseFields
            ?.filter((field) => !forbiddenFieldTypes.includes(field.type))
            .map((field) => (
              <div key={field.name} className="flex flex-col">
                <label className="pb-2 w-fit">
                  {field.name}
                  {field.required && <span className="text-red-500"> *</span>}
                </label>
                <Input
                  placeholder={getPlaceholder(
                    field.type,
                    field.container?.valueScalarType,
                  )}
                  onChange={(e) => {
                    const value =
                      field.type === 'ARRAY'
                        ? e.target.value.includes(',')
                          ? e.target.value.split(',').map((item) => item.trim())
                          : [e.target.value]
                        : e.target.value;

                    setTestData({
                      ...testData,
                      [field.name]: value,
                    });
                  }}
                />
              </div>
            ))}
        </div>
        {selectedItemType?.baseFields && (
          <div className="flex flex-col mt-4">
            <h3 className="mb-2 font-medium text-gray-700">
              Which fields are violating?
            </h3>
            <div className="flex flex-wrap">
              {selectedItemType?.baseFields
                ?.filter((field) => !forbiddenFieldTypes.includes(field.type))
                .map((field) => (
                  <div key={field.name} className="flex flex-col mb-2 mr-4">
                    {field.type === 'ARRAY' &&
                    Array.isArray(testData[field.name]) ? (
                      (testData[field.name] as string[]).map((_, index) => (
                        <div
                          key={`${field.name}-${index}`}
                          className="flex items-center"
                        >
                          <Checkbox
                            id={`checkbox-${field.name}-${index}`}
                            value={violatingFields.includes(
                              `${field.name}[${index}]`,
                            )}
                            onChange={(e) => {
                              setViolatingFields(
                                e.target.checked
                                  ? [
                                      ...violatingFields,
                                      `${field.name}[${index}]`,
                                    ]
                                  : violatingFields.filter(
                                      (it) => it !== `${field.name}[${index}]`,
                                    ),
                              );
                            }}
                          >
                            {`${field.name}[${index}]`}
                          </Checkbox>
                        </div>
                      ))
                    ) : (
                      <div className="flex items-center">
                        <Checkbox
                          id={`checkbox-${field.name}`}
                          value={violatingFields.includes(field.name)}
                          onChange={(e) => {
                            setViolatingFields(
                              e.target.checked
                                ? [...violatingFields, field.name]
                                : violatingFields.filter(
                                    (it) => it !== field.name,
                                  ),
                            );
                          }}
                        >
                          {field.name}
                        </Checkbox>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </CoveModal>
  );
}
