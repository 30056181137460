import { BalanceFilled } from '@/icons';
import { domAnimation, LazyMotion, m, type Variants } from 'framer-motion';
import { useState } from 'react';

import { GlobalReg } from '../components/animations/compliance/GlobalReg';
import { NCMEC } from '../components/animations/compliance/NCMEC';
import { Reports } from '../components/animations/compliance/Reports';
import { HorizontalDashes } from '../components/animations/HorizontalDashes';
import { VerticalDashes } from '../components/animations/VerticalDashes';
import { FAQ } from '../components/FAQ';
import RequestDemoModal from '../components/RequestDemoModal';
import { FeatureSection } from '../components/sections/landing/FeatureSection';
import { Button } from '../components/ui/Button';
import { Typography } from '../components/ui/Typography';

const faqQuestions = [
  {
    question: 'Can I use the Moderator Console by itself?',
    answer:
      'Yes, all our products can be used individually or as part of a larger suite based on the solutions you need. ',
  },
  {
    question: 'Can I customize actions and permissions per review queue?',
    answer:
      'Yes, you can customize which moderators can access a particular queue, which content ends up in that queue, and which actions can be taken in that queue.',
  },
  {
    question:
      'Do my decisions get fed back into my Cove AI Models as labeled data?',
    answer:
      'While we can work with any size of data set for training, we recommend 1 million samples...',
  },
];

const supportRegions = [
  {
    title: 'EU Digital Services Act',
    image: 'dsa',
    features: [
      'Track all your enforcement metrics',
      'Automatically generate transparency reports',
      'Manual review tooling',
      'Appeals & Statements of Reason',
    ],
  },
  {
    title: 'UK Online Safety Act',
    image: 'ukosa',
    features: [
      'Track metrics on your platform',
      'Automatically detect illegal content',
      'Handle Notice + Takedown obligations',
      'Child Safety-specific reporting and review',
    ],
  },
  {
    title: 'CSAM reporting to NCMEC',
    image: 'ncmec',
    features: [
      'Detect and review suspected CSAM',
      'Send CSAM and rich metadata directly to NCMEC',
      'Preserve all data for 90 days in case of subpoena',
      'Moderator safety features for well-being',
    ],
  },
  {
    title: 'GDPR Compliance',
    image: BalanceFilled,
    features: [
      'Comply with data access and deletion requests',
      'Expand to EU markets with confidence',
      'Prioritize the privacy and safety of your users',
    ],
  },
];

const gridVariants: Variants = {
  hidden: {
    opacity: 0,
    y: 10,
  },
  visible: (i) => ({
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      delay: i > 1 ? 0.5 : 0.2,
      viewport: {
        amount: 'all',
        once: true,
      },
    },
  }),
};

export default function Compliance() {
  const [requestDemoModalVisible, setRequestDemoModalVisible] = useState(false);
  const showRequestDemoModal = () => setRequestDemoModalVisible(true);
  return (
    <LazyMotion features={domAnimation}>
      <FeatureSection
        size="md"
        className="!pt-40"
        title="Automatically comply with local and global regulations"
        description="Scale your products globally without having to worry about regional regulations. Cove’s Compliance Toolkit automatically gets your technical systems compliant with no extra work."
        cta={<Button onClick={showRequestDemoModal}>Learn more</Button>}
      >
        <GlobalReg />
      </FeatureSection>

      <FeatureSection
        label="Transparency reports"
        title="Generate transparency reports with one click"
        description="When you manage your moderation through Cove, you can automatically generate a DSA- and OSA-compliant transparency report at the click of a button."
        cta={
          <Button onClick={showRequestDemoModal}>Get started with Cove</Button>
        }
      >
        <Reports />
      </FeatureSection>

      <FeatureSection
        label="NCMEC reporting"
        title="Submit reports to NCMEC with ease through our CyberTip integration."
        description="With Cove's specialized CSAM review workflow, you can easily review potential CSAM and report it to NCMEC with all the required information and labels."
        cta={
          <Button onClick={showRequestDemoModal}>
            See how the integration works
          </Button>
        }
      >
        <NCMEC />
      </FeatureSection>

      <FeatureSection
        title="Supported regions and requirements"
        orientation="ttb"
        size="md"
        centered
      >
        <m.div
          variants={gridVariants}
          initial="hidden"
          whileInView="visible"
          className="relative w-full grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16"
        >
          <div
            className="[--cell-width:calc(100cqw/12)] mask-ellipse absolute -top-[200px] -left-[calc(var(--cell-width)*3)] w-[200%] h-[calc(100%+300px)] bg-graph-paper"
            style={{
              backgroundSize: 'var(--cell-width) 100px',
              maskSize: 'calc(100cqw+(var(--cell-width)*3)) 100%',
              maskPosition: '0 0',
            }}
          ></div>

          <HorizontalDashes
            width={320}
            className="absolute top-0 left-0 z-20 -translate-x-1/2 mask-to-l"
          />

          <HorizontalDashes
            width={320}
            className="absolute top-0 right-0 z-20 translate-x-1/2 mask-to-r"
          />

          <VerticalDashes
            height={320}
            className="absolute top-0 left-0 z-20 -translate-y-1/2 mask-to-t"
          />

          <VerticalDashes
            height={320}
            className="absolute top-0 right-0 z-20 -translate-y-1/2 mask-to-t"
          />

          {supportRegions.map((region, i) => {
            const image =
              typeof region.image === 'string' ? (
                <img
                  className="w-20 h-20 sm:w-24 sm:h-24"
                  src={`/graphics/${region.image}.png`}
                  alt={region.title}
                />
              ) : (
                <region.image className="w-20 h-20 text-primary" />
              );
            return (
              <m.div
                key={i}
                variants={gridVariants}
                custom={i}
                className="z-20 py-12 bg-white border border-solid px-9 border-primary rounded-4xl"
              >
                <div className="flex flex-col items-start justify-between gap-6 sm:flex-row">
                  <div className="w-full">
                    <div className="flex items-center justify-between gap-4">
                      <Typography variant="headline" size="md">
                        {region.title}
                      </Typography>
                      <div className="flex w-20 h-20 sm:hidden">{image}</div>
                    </div>

                    <div className="flex flex-col mt-4 gap-1.5">
                      {region.features.map((feature, j) => (
                        <Typography key={j} variant="body" size="lg">
                          {feature}
                        </Typography>
                      ))}
                    </div>
                  </div>
                  <div className="hidden w-24 h-24 sm:flex">{image}</div>
                </div>
              </m.div>
            );
          })}
        </m.div>
      </FeatureSection>

      <FAQ title="Frequently Asked Questions" questions={faqQuestions} />

      <FeatureSection
        title="Get started with Cove today"
        orientation="ttb"
        description="Schedule a demo to chat with someone on our team to see how Cove’s Compliance Toolkit can be a fit for your team."
        cta={
          <Button className="mt-6 sm:mt-0" onClick={showRequestDemoModal}>
            Schedule a demo
          </Button>
        }
        gap={false}
      />
      <RequestDemoModal
        visible={requestDemoModalVisible}
        onClose={() => setRequestDemoModalVisible(false)}
        entrypoint="COMPLIANCE"
      />
    </LazyMotion>
  );
}
