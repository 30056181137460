import { LinkedInOriginal, TwitterFilled } from '@/icons';
import { keyBy } from 'lodash';
import { useParams } from 'react-router-dom';

import { FormattedDate } from '../components/FormattedDate';
import { Typography } from '../components/ui/Typography';

import OpenAIBlogPost from '../content/blog_posts/openai';
import PlatformPoliciesPt1BlogPost from '../content/blog_posts/platform-policies-pt-1';
import PlatformSpecificPoliciesPt2BlogPost from '../content/blog_posts/platform-policies-pt-2';
import SafetyLayerBlogPost from '../content/blog_posts/safety-layer';
import Soc2BlogPost from '../content/blog_posts/soc2';
import Soc2Type2BlogPost from '../content/blog_posts/soc2-type-2';
import type { BlogPostConfig } from '../content/blog_posts/types';

export const BlogPosts = [
  OpenAIBlogPost,
  SafetyLayerBlogPost,
  Soc2BlogPost,
  PlatformPoliciesPt1BlogPost,
  Soc2Type2BlogPost,
  PlatformSpecificPoliciesPt2BlogPost,
] satisfies BlogPostConfig[];

// Create a map where the key is the slug and the value is the BlogPostConfig
const BlogPostsBySlug = keyBy(BlogPosts, 'slug');

export default function BlogPost() {
  const { slug } = useParams<{ slug?: string }>();
  if (!slug || !BlogPostsBySlug[slug]) {
    throw new Error(`Missing or invalid slug provided: ${slug}`);
  }
  const { title, author, date, coverPhoto, content } = BlogPostsBySlug[slug];
  const { url: imageUrl, attribution } = coverPhoto;

  return (
    <article className="container max-w-6xl py-40 mx-auto prose prose-xl prose-li:marker:text prose-img:rounded-lg">
      <FormattedDate
        date={date}
        variant="body"
        className="mb-3 not-prose"
        size="lg"
      />
      <Typography className="not-prose" variant="display" size="sm">
        {title}
      </Typography>
      {author && (
        <div className="flex flex-row items-center justify-start pt-6 gap-4 not-prose">
          <img width={50} height={50} src={author.image} alt={author.name} />

          <div className="flex flex-col opacity-50 gap-0">
            <Typography size="lg" variant="body">
              {author.name}
            </Typography>

            <div className="flex flex-row gap-2">
              {author.twitter && (
                <a
                  className="text-inherit decoration-inherit"
                  href={`https://x.com/${author.twitter}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TwitterFilled width={14} height={14} />
                </a>
              )}

              {author.linkedin && (
                <a
                  className="text-inherit decoration-inherit"
                  href={`https://linkedin.com/in/${author.linkedin}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LinkedInOriginal width={14} height={14} />
                </a>
              )}
            </div>
          </div>
        </div>
      )}
      <img src={imageUrl} alt={title} className="w-full max-w-[44rem] mb-1" />
      {attribution && <div className="text-xs">{attribution}</div>}
      {content}
    </article>
  );
}
