type TripadvisorDemoModelConfig = {
  threshold: number;
  precision: number;
  recall: number;
};

export const tripadvisorDemoModelConfigs: {
  [key: string]: TripadvisorDemoModelConfig;
} = {
  // Affiliation Stated in Review
  '06f6aa153e5': {
    threshold: 0.04350169556,
    precision: 0.89,
    recall: 0.77,
  },
  // Allegation of Fraud
  '6f6aa153e57': {
    threshold: 0.2,
    precision: 0.97,
    recall: 1,
  },
  // Commercial
  '241c7725366': {
    threshold: 0.1664868811,
    precision: 0.93,
    recall: 0.88,
  },
  // Discriminatory Language
  c8d73277927: {
    threshold: 0.6,
    precision: 0.91,
    recall: 1,
  },
  // Experience > 1 Year
  bfc977f5402: {
    threshold: 0.01120047242,
    precision: 1,
    recall: 0.62,
  },
  // Hard to Read
  '69cf5ad3463': {
    threshold: 0.75,
    precision: 0.94,
    recall: 0.85,
  },
  // Illegal Activity
  fc977f54024: {
    threshold: 0.05,
    precision: 1,
    recall: 0.5,
  },
  // Ineligible Experience
  c977f540247: {
    threshold: 0.95,
    precision: 0.81,
    recall: 0.95,
  },
  // Irrelevant Commentary
  '977f5402474': {
    threshold: 0.7,
    precision: 0.93,
    recall: 0.97,
  },
  // Listing Update
  '9cf5ad34639': {
    threshold: 0.03781995156,
    precision: 0.89,
    recall: 0.89,
  },
  // Multiple Listings
  '8d732779277': {
    threshold: 0.05595213209,
    precision: 1,
    recall: 0.25,
  },
  // Non-Original
  d7327792773: {
    threshold: 0.1339489323,
    precision: 0.95,
    recall: 0.73,
  },
  // Not Substantive
  '7327792773d': {
    threshold: 0.2270261516,
    precision: 0.93,
    recall: 0.84,
  },
  // Personally Insulting
  ab421514d19: {
    threshold: 0.85,
    precision: 0.95,
    recall: 0.91,
  },
  // Pre-Review
  b421514d19b: {
    threshold: 0.982493112,
    precision: 0.77,
    recall: 0.71,
  },
  // Private Information
  '421514d19b6': {
    threshold: 0.00374628303,
    precision: 0.95,
    recall: 0.76,
  },
  // Profane or Vulgar
  e7fe47167c8: {
    threshold: 0.9,
    precision: 0.97,
    recall: 0.94,
  },
  // Property Policy Complaint
  '7fe47167c8d': {
    threshold: 0.1573543654,
    precision: 0.82,
    recall: 0.7,
  },
  // Secondhand Experience
  '21514d19b6a': {
    threshold: 0.5494004052,
    precision: 0.9,
    recall: 0.56,
  },
  // Sexually Explicit or Graphic
  b53ba6407a7: {
    threshold: 0.2682091411,
    precision: 1,
    recall: 0.93,
  },
  // SHI
  '53ba6407a79': {
    threshold: 0.2067978777,
    precision: 1,
    recall: 0.47,
  },
  // TA Commentary
  '1514d19b6aa': {
    threshold: 0.1318563269,
    precision: 0.94,
    recall: 0.81,
  },
  // Threatening Language
  '514d19b6aab': {
    threshold: 0.2,
    precision: 1,
    recall: 1,
  },
  // Will Not List
  '3ba6407a79d': {
    threshold: 1,
    precision: 0.78,
    recall: 0.98,
  },
};
