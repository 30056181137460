import { m } from 'framer-motion';

export function CustomModels() {
  return (
    <div className="flex w-full max-w-3xl">
      <div className="z-10 flex flex-col w-2/5 pt-8 overflow-visible gap-4 sm:gap-8 sm:pt-24">
        <m.img
          className="ml-4 -mr-8 sm:-mr-16"
          initial={{ y: 10, opacity: 0 }}
          whileInView={{ y: 0, opacity: 100 }}
          viewport={{ once: true, amount: 'some' }}
          transition={{ duration: 0.5, delay: 1 }}
          src="/graphics/custom-models-voice.png"
        />
        <m.img
          initial={{ y: 10, opacity: 0 }}
          whileInView={{ y: 0, opacity: 100 }}
          viewport={{ once: true, amount: 'some' }}
          transition={{ duration: 0.5, delay: 0.75 }}
          src="/graphics/custom-models-graph.png"
        />
      </div>
      <div className="w-3/5 -translate-y-8 sm:translate-y-0">
        <m.img
          className="w-full"
          initial={{ y: 10, opacity: 0 }}
          whileInView={{ y: 0, opacity: 100 }}
          viewport={{ once: true, amount: 'some' }}
          transition={{ duration: 0.5, delay: 0.5 }}
          src="/graphics/custom-models-label.png"
        />
      </div>
    </div>
  );
}
