import jsonpointer from 'jsonpointer';
import type { JsonObject, JsonValue } from 'type-fest';

export const getFieldAtJsonPointer = (
  item: JsonObject,
  pointer: string,
): JsonValue | undefined => {
  try {
    return jsonpointer.get(item, pointer);
  } catch (e) {
    return undefined;
  }
};
