import { useGQLPoliciesQuery } from '@/graphql/generated';
import { ArrowDownWideNarrow, ChartLine, TextCursorInput } from 'lucide-react';
import { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import TabBar from '../../components/TabBar';
import FullScreenLoading from '@/components/common/FullScreenLoading';

import ModelSpotTest from '../ModelSpotTest';
import PreviewModel from '../PreviewModel';
import ModelPerformanceMetrics from './ModelPerformanceMetrics';

const modelPerformanceTabs = [
  'scored_samples',
  'performance_metrics',
  'spot_test',
] as const;
type ModelPerformanceTab = (typeof modelPerformanceTabs)[number];

export default function ModelPerformance() {
  const { policyId, modelVersion, modelId } = useParams<{
    policyId: string;
    modelVersion: string;
    modelId: string;
  }>();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  if (
    tab != null &&
    !modelPerformanceTabs.includes(tab as ModelPerformanceTab)
  ) {
    throw new Error('Invalid tab');
  }

  if (policyId == null || modelVersion == null || modelId == null) {
    throw new Error('Policy ID, model version and model ID are all required');
  }

  const [selectedTab, setSelectedTab] = useState<ModelPerformanceTab>(
    (tab as ModelPerformanceTab) ?? 'scored_samples',
  );

  const {
    data: policiesData,
    loading: policiesLoading,
    error: policiesError,
  } = useGQLPoliciesQuery();

  if (policiesLoading) {
    return <FullScreenLoading />;
  }

  if (policiesError) {
    throw Error('Error fetching policies');
  }

  const policy = policiesData?.myOrg?.policies.find((p) => p.id === policyId);
  if (policy == null) {
    throw new Error('Policy not found');
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row gap-2">
        <div>Custom AI Models</div>
        <div>/</div>
        <span>{policy.name}</span>
        <div>/</div>
        <span className="font-semibold">Performance</span>
      </div>
      <TabBar
        tabs={[
          {
            label: 'Scored Samples',
            value: 'scored_samples',
            icon: <ArrowDownWideNarrow className="w-4 h-4" />,
          },
          {
            label: 'Performance Metrics',
            value: 'performance_metrics',
            icon: <ChartLine className="w-4 h-4" />,
          },
          {
            label: 'Spot Test',
            value: 'spot_test',
            icon: <TextCursorInput className="w-4 h-4" />,
          },
        ]}
        initialSelectedTab={selectedTab}
        onTabClick={setSelectedTab}
      />
      {(() => {
        switch (selectedTab) {
          case 'scored_samples':
            return <PreviewModel />;
          case 'performance_metrics':
            return <ModelPerformanceMetrics />;
          case 'spot_test':
            return <ModelSpotTest />;
        }
      })()}
    </div>
  );
}
