import { Button } from '@/cove-ui';
import {
  ChevronDown,
  ChevronUp,
  Pencil,
  Plus,
  Sparkles,
  TrashCan,
} from '@/icons';
import { gql } from '@apollo/client';
import { Input } from 'antd';
import { SparklesIcon } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import CopyTextComponent from '../../../components/common/CopyTextComponent';
import FullScreenLoading from '../../../components/common/FullScreenLoading';
import CoveBadge from '../components/CoveBadge';
import CoveButton from '../components/CoveButton';
import CoveModal from '../components/CoveModal';
import { CoveModalFooterButtonProps } from '../components/CoveModalFooter';
import DashboardHeader from '../components/DashboardHeader';

import {
  GQLUserPenaltySeverity,
  GQLUserPermission,
  useGQLDeletePolicyMutation,
  useGQLGetOrgIsReadyToCreateCoveModelsQuery,
  useGQLIsDemoOrgQuery,
  useGQLPoliciesWithModelsQuery,
} from '../../../graphql/generated';
import { userHasPermissions } from '../../../routing/permissions';
import { Tree, treeFromList, TreeNode } from '../../../utils/tree';
import { ModalInfo } from '../types/ModalInfo';

export type Policy = {
  id: string;
  name: string;
  penalty: GQLUserPenaltySeverity;
  policyText?: string;
  enforcementGuidelines?: string;
};

export type PoliciesDashboardState = {
  canEditPolicies: boolean;
  isEdited: boolean;
  policyTree: Tree<Policy>;
};

gql`
  fragment PolicyFields on Policy {
    id
    name
    policyText
    enforcementGuidelines
    parentId
    policyType
    userStrikeCount
    applyUserStrikeCountConfigToChildren
  }

  query Policies {
    myOrg {
      policies {
        ...PolicyFields
      }
    }
  }

  query PoliciesWithModels {
    myOrg {
      policies {
        ...PolicyFields
      }
      models {
        id
        status
        version
        alias
        policy {
          id
        }
      }
    }
    me {
      permissions
    }
  }

  mutation AddPolicies($policies: [AddPolicyInput!]!) {
    addPolicies(policies: $policies) {
      policies {
        ...PolicyFields
      }
      failures
    }
  }

  query GetOrgIsReadyToCreateCoveModels {
    getOrgIsReadyToCreateCoveModels
  }

  mutation UpdatePolicy($input: UpdatePolicyInput!) {
    updatePolicy(input: $input) {
      ...PolicyFields
    }
  }

  mutation DeletePolicy($id: ID!) {
    deletePolicy(id: $id)
  }

  query IsDemoOrg {
    myOrg {
      isDemoOrg
    }
  }
`;

/**
 * Policy Dashboard screen
 */
export default function PoliciesDashboard() {
  const navigate = useNavigate();

  const { loading, error, data, refetch } = useGQLPoliciesWithModelsQuery();
  const { error: orgIsReadyError, data: orgIsReadyData } =
    useGQLGetOrgIsReadyToCreateCoveModelsQuery();
  const orgIsReady =
    !orgIsReadyError && orgIsReadyData?.getOrgIsReadyToCreateCoveModels;
  const { data: isDemoOrgData } = useGQLIsDemoOrgQuery();
  const isDemoOrg = isDemoOrgData?.myOrg?.isDemoOrg ?? false;
  const [modalVisible, setModalVisible] = useState(false);

  const [deletePolicy] = useGQLDeletePolicyMutation();

  const [modalInfo, setModalInfo] = useState<ModalInfo>({
    visible: false,
    title: '',
    body: '',
    okText: 'OK',
    onOk: () => {},
    okIsDangerButton: false,
    cancelVisible: false,
  });
  const [policyTree, setPolicyTree] = useState<Tree<Policy>>(
    new Tree<Policy>('root', {
      id: '-1',
      name: 'root',
      penalty: GQLUserPenaltySeverity.None,
    }),
  );
  const [expandedPolicies, setExpandedPolicies] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const filteredPolicyTree = useMemo(() => {
    if (searchTerm.trim().length === 0) {
      return policyTree;
    }

    return policyTree.filterTree((policy) =>
      policy.name.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }, [policyTree, searchTerm]);

  const treeSize = policyTree.size();
  const policyNames = policyTree.getValues((policy) => policy.name);

  const onHideModal = () => {
    setModalInfo({ ...modalInfo, visible: false });
    refetch();
  };

  const permissions = data?.me?.permissions;
  const policyList = data?.myOrg?.policies;
  const policyIdsWithDeployedModels = data?.myOrg?.models
    .filter((it) => it.status === 'DEPLOYED')
    .map((it) => it.policy.id);

  useMemo(() => {
    if (policyList) {
      setPolicyTree(
        treeFromList<Policy>(
          policyList,
          { id: '-1', name: 'root', penalty: GQLUserPenaltySeverity.None },
          (policy) => ({
            id: policy.id,
            name: policy.name,
            penalty: policy.penalty,
            policyText: policy.policyText,
            enforcementGuidelines: policy.enforcementGuidelines,
          }),
        ),
      );
    }
  }, [policyList]);

  const noPoliciesYet = useMemo(
    () => policyTree.root && policyTree.root.isLeaf,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [policyTree.root, treeSize],
  );

  const toggleExpanded = (policyName: string) => {
    if (expandedPolicies.includes(policyName)) {
      setExpandedPolicies(expandedPolicies.filter((it) => it !== policyName));
    } else {
      setExpandedPolicies([...expandedPolicies, policyName]);
    }
  };

  useEffect(() => {
    if (searchTerm.length > 0) {
      // Expand all policies
      setExpandedPolicies(policyNames);
    } else {
      setExpandedPolicies([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const renderPolicy = (policy: TreeNode<Policy>) => {
    const children = policy.children.length ? (
      <div className="flex items-stretch h-full pb-8">
        <div className="flex w-px h-full mx-2 bg-slate-200" />
        <div className="flex flex-col justify-center w-full pl-8 mb-6">
          {policy.children.map((child, i) => (
            <div key={i} className="flex flex-col">
              {renderPolicy(child)}
            </div>
          ))}
        </div>
      </div>
    ) : null;

    const previewPolicyText = policy.value.policyText?.replace(/<[^>]+>/g, ' ');

    const cannotCreateModelModal = (
      <CoveModal
        title="Train a Custom Model"
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
      >
        <div className="flex flex-col">
          This is a demo account, so you cannot create new models. Please let us
          know if you'd like to create additional models as part of your trial
          or proof of concept!
        </div>
      </CoveModal>
    );

    const spotTestModel =
      data?.myOrg?.models.find(
        (it) => it.policy.id === policy.value.id && it.alias === 'LIVE',
      ) ??
      data?.myOrg?.models.find(
        (it) => it.policy.id === policy.value.id && it.alias === 'BETA',
      ) ??
      undefined;

    return (
      <div key={policy.key} className="flex flex-col w-full">
        <div className="flex flex-col items-stretch mb-6">
          <div
            className={`flex items-start justify-between pb-4 rounded-md border border-solid w-full bg-white border-slate-200`}
          >
            <div className="flex flex-col w-full">
              <div className="flex items-center gap-6 px-6 pt-6 pb-3">
                <div className="text-base font-bold text-start">
                  {policy.value?.name}
                </div>
                <div className="flex items-center gap-2 text-slate-400">
                  ID: <CopyTextComponent value={policy.value.id} />
                </div>
                <div className="grow" />
                {policyIdsWithDeployedModels?.includes(policy.value.id) && (
                  <div className="justify-self-end">
                    <CoveBadge
                      colorVariant="soft-green"
                      shapeVariant="rounded"
                      label="AI Enabled"
                      icon={<Sparkles />}
                    />
                  </div>
                )}
              </div>
              <div className="flex flex-col justify-between text-slate-700 text-start">
                <div className="px-6">
                  {previewPolicyText ? (
                    <div className="max-two-lines">{previewPolicyText}</div>
                  ) : (
                    'No definition provided'
                  )}
                </div>
                <div className="my-4 divider" />
                <div className="flex flex-row justify-between px-6">
                  {policy.children.length ? (
                    <div
                      className="flex items-center gap-4 font-medium cursor-pointer text-primary fill-primary"
                      onClick={() => toggleExpanded(policy.value.name)}
                    >
                      <div>
                        {policy.children.length === 1
                          ? '1 Sub-Policy'
                          : `${policy.children.length} Sub-Policies`}
                      </div>
                      <div className="flex items-center justify-start gap-1.5">
                        {expandedPolicies.includes(policy.value.name) ? (
                          <ChevronUp className="flex text-xs" height="12px" />
                        ) : (
                          <ChevronDown className="flex text-xs" height="12px" />
                        )}
                      </div>
                    </div>
                  ) : (
                    <div />
                  )}
                  {userHasPermissions(permissions, [
                    GQLUserPermission.ManageOrg,
                  ]) ? (
                    <div className="flex flex-row self-end">
                      {!policyIdsWithDeployedModels?.includes(
                        policy.value.id,
                      ) && orgIsReady ? (
                        <div className="mr-12">
                          <Button
                            className="!fill-none"
                            onClick={() => {
                              if (isDemoOrg) {
                                setModalVisible(true);
                              } else {
                                navigate(
                                  `/training/select_modality?policyId=${policy.value.id}`,
                                );
                              }
                            }}
                            startIcon={SparklesIcon}
                            variant="outline"
                          >
                            Train a Custom Model
                          </Button>
                          {cannotCreateModelModal}
                        </div>
                      ) : null}
                      {policyIdsWithDeployedModels?.includes(policy.value.id) &&
                      orgIsReady &&
                      spotTestModel != null ? (
                        <div className="mr-12">
                          <CoveButton
                            onClick={() =>
                              navigate(
                                `/dashboard/models_and_policies/models/performance/${policy.value.id}/${spotTestModel?.id}/${spotTestModel?.version}?tab=spot_test`,
                              )
                            }
                            title={
                              spotTestModel.alias === 'LIVE'
                                ? 'Spot Test Live Model'
                                : 'Spot Test Beta Model'
                            }
                            type="secondary"
                            size="small"
                            fontWeight="normal"
                          />
                        </div>
                      ) : null}
                      <div
                        onClick={() =>
                          navigate(
                            `/dashboard/models_and_policies/policies/form?parentPolicyId=${policy.value.id}`,
                          )
                        }
                        className="flex flex-row items-center mr-12 cursor-pointer text-primary fill-primary"
                      >
                        <Plus height="12px" className="pr-2" />
                        Add Sub Policy
                      </div>
                      <div
                        onClick={() =>
                          navigate(
                            `/dashboard/models_and_policies/policies/form/${policy.value.id}`,
                          )
                        }
                        className="flex flex-row items-center mr-12 cursor-pointer text-primary fill-primary"
                      >
                        <Pencil height="12px" className="pr-2" />
                        Edit
                      </div>
                      <div
                        onClick={() =>
                          setModalInfo({
                            visible: true,
                            title: `Delete ${policy.value.name} policy?`,
                            body: `Please confirm you'd like to delete this policy. This action cannot be undone.`,
                            okText: 'OK',
                            cancelText: 'Cancel',
                            onOk: () => {
                              deletePolicy({
                                variables: { id: policy.value.id },
                                onCompleted: onHideModal,
                                onError: () =>
                                  setModalInfo({
                                    visible: true,
                                    title: 'Error',
                                    body: 'Error deleting policy. Please try again.',
                                    okText: 'OK',
                                    onOk: onHideModal,
                                    okIsDangerButton: false,
                                    cancelVisible: false,
                                  }),
                              });
                            },
                            onCancel: onHideModal,
                            okIsDangerButton: true,
                            cancelVisible: true,
                          })
                        }
                        className="flex flex-row items-center cursor-pointer text-cove-alert-red fill-cove-alert-red"
                      >
                        <TrashCan height="12px" className="pr-2" />
                        Delete
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        {expandedPolicies.includes(policy.value.name) ? children : null}
      </div>
    );
  };

  const policies = useMemo(() => {
    return (
      <div className="flex flex-col items-stretch w-full mb-6">
        {filteredPolicyTree?.root.children.map(renderPolicy)}
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredPolicyTree, treeSize, policyNames, noPoliciesYet]);

  const modalFooter: CoveModalFooterButtonProps[] = [
    {
      title: modalInfo.okText,
      type: modalInfo.okIsDangerButton ? 'danger' : 'primary',
      onClick: modalInfo.onOk,
    },
  ];
  if (modalInfo.cancelVisible) {
    modalFooter.unshift({
      title: 'Cancel',
      onClick: onHideModal,
      type: modalInfo.okIsDangerButton ? 'primary' : 'secondary',
    });
  }

  const modal = (
    <CoveModal
      title={modalInfo.title}
      visible={modalInfo.visible}
      onClose={onHideModal}
      footer={modalFooter}
    >
      {modalInfo.body}
    </CoveModal>
  );

  if (error) {
    throw error;
  }
  if (loading) {
    return <FullScreenLoading />;
  }

  const searchBar = (
    <Input
      key="searchBar"
      className="!w-48"
      placeholder="Search"
      value={searchTerm}
      onChange={(event) => setSearchTerm(event.target.value)}
      allowClear
      // Note: we autofocus here because the input component behaves weirdly
      // otherwise...specifically, after writing the first character (or
      // removing the last character when there's only a single character in the
      // field), it unfocuses automatically, which is an incredibly annoying
      // user experience. Instead, let's just autofocus. The users who aren't
      // trying to search won't care/notice, and the ones who are will be
      // grateful that it's already selected for them.
      autoFocus
    />
  );

  return (
    <div className="flex flex-col items-start">
      <Helmet>
        <title>Policies</title>
      </Helmet>
      <div className="w-full">
        <DashboardHeader
          title="Policies"
          subtitle="Create policy categories here so you can track metrics across various policies. You can assign each rule you create to one or more of these policies."
          rightComponent={
            userHasPermissions(permissions, [GQLUserPermission.ManageOrg]) ? (
              <CoveButton
                type="primary"
                onClick={() => navigate(`form`)}
                title="Create New Policy"
              />
            ) : null
          }
        />
      </div>
      {searchBar}
      <div className="flex flex-row items-stretch w-full mt-6">
        <div className="flex flex-col items-start w-full overflow-y-auto">
          {policies}
        </div>
      </div>
      {modal}
    </div>
  );
}
