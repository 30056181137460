import { ChevronRightFilled, EnvelopeAltFilled } from '@/icons';
import { Meta, StoryFn } from '@storybook/react';

import { Button, ButtonProps } from './Button';

export default {
  title: 'Components/Button',
  component: Button,
  argTypes: {
    variant: {
      control: { type: 'select' },
      options: [
        'default',
        'destructive',
        'outline',
        'secondary',
        'ghost',
        'link',
      ],
    },
    size: {
      control: { type: 'select' },
      options: ['default', 'sm', 'lg', 'icon'],
    },
    asChild: { control: 'boolean' },
    disabled: { control: 'boolean' },
  },
} as Meta;

const Template: StoryFn<ButtonProps> = (args) => <Button {...args} />;

export const Default = Template.bind({});
Default.args = {
  variant: 'default',
  size: 'default',
  children: 'Button',
};

export const Destructive = Template.bind({});
Destructive.args = {
  variant: 'destructive',
  size: 'default',
  children: 'Button',
};

export const Outline = Template.bind({});
Outline.args = {
  variant: 'outline',
  size: 'default',
  children: 'Button',
};

export const Secondary = Template.bind({});
Secondary.args = {
  variant: 'secondary',
  size: 'default',
  children: 'Button',
};

export const Ghost = Template.bind({});
Ghost.args = {
  variant: 'ghost',
  size: 'default',
  children: 'Button',
};

export const LinkButton = Template.bind({});
LinkButton.args = {
  variant: 'link',
  size: 'default',
  children: 'Link Button',
};

export const Small = Template.bind({});
Small.args = {
  variant: 'default',
  size: 'sm',
  children: 'Small Button',
};

export const Large = Template.bind({});
Large.args = {
  variant: 'default',
  size: 'lg',
  children: 'Large Button',
};

export const Icon = Template.bind({});
Icon.args = {
  variant: 'default',
  size: 'icon',
  children: <ChevronRightFilled />,
};

export const WithStartIcon = Template.bind({});
WithStartIcon.args = {
  variant: 'default',
  startIcon: EnvelopeAltFilled,
  children: 'Login with Email',
};

export const WithEndIcon = Template.bind({});
WithEndIcon.args = {
  variant: 'default',
  endIcon: EnvelopeAltFilled,
  children: 'Login with Email',
};

export const Loading = Template.bind({});
Loading.args = {
  variant: 'default',
  size: 'default',
  loading: true,
  children: 'Please wait...',
};

export const AsChild = Template.bind({});
AsChild.args = {
  variant: 'default',
  size: 'default',
  asChild: true,
  children: <a href="/login">Login</a>,
};
