import { useGQLUserAndOrgQuery } from '@/graphql/generated';
import { filterNullOrUndefined } from '@/utils/collections';
import { getPrimaryContentFields } from '@/utils/itemUtils';
import { jsonParse, type JsonOf } from '@/utils/typescript-types';
import { type Field } from '@protego-api/types';
import _ from 'lodash';
import Papa from 'papaparse';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Navigate } from 'react-router-dom';
import type { JsonObject } from 'type-fest';

import CopyTextComponent from '@/components/common/CopyTextComponent';
import CoveSelect from '@/components/common/CoveSelect';
import FullScreenLoading from '@/components/common/FullScreenLoading';
import TrainingPipelineSelectableFieldsComponent from '@/webpages/dashboard/models/training/components/TrainingPipelineSelectableFieldsComponent';

type Label =
  | {
      label: 'violates' | 'borderline';
      violatingFields: string[];
    }
  | {
      label: 'does_not_violate' | 'unlabeled';
      violatingFields?: undefined;
    };

type ImportedSample = {
  OCCURRENCE_ID: string;
  ITEM_DATA: Record<string, JsonObject>;
  ITEM_TYPE_SCHEMA: Field[];
};

const updateStoredData = (
  fileName: string,
  labels: Record<string, Label>,
  csvData: ImportedSample[],
) => {
  const storedData = localStorage.getItem('labelingData_items');
  const parsedData = storedData ? JSON.parse(storedData) : {};
  parsedData[fileName] = { labels, csvData };
  localStorage.setItem('labelingData_items', JSON.stringify(parsedData));
};

const getAllStoredData = (): Record<
  string,
  {
    labels: Record<string, Label>;
    csvData: ImportedSample[];
  }
> => {
  const storedData = localStorage.getItem('labelingData_items');
  return storedData ? JSON.parse(storedData) : {};
};

export default function InternalItemLabeling() {
  const { data, loading } = useGQLUserAndOrgQuery();
  const userEmail = data?.me?.email;

  const [fileName, setFileName] = useState<string | undefined>(undefined);
  const [selectedJobName, setSelectedJobName] = useState<string | undefined>(
    undefined,
  );
  const [importedData, _setImportedData] = useState<ImportedSample[]>([]);
  const [dataToViolatesMap, setDataToViolatesMap] = useState<
    Record<string, Label>
  >({});
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [dropdownOptions, setDropdownOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [selectedViolatingFields, setSelectedViolatingFields] = useState<
    string[]
  >([]);

  const resetState = useCallback(() => {
    setSelectedJobName(undefined);
    setImportedData([]);
    setDataToViolatesMap({});
    setFileName(undefined);
    setSelectedIndex(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setImportedData = useCallback((data: ImportedSample[]) => {
    _setImportedData(data);
    setDataToViolatesMap(
      Object.fromEntries(
        data
          .map((data) => [data.OCCURRENCE_ID, 'unlabeled'])
          .filter(([id, _]) => id !== ''),
      ),
    );
  }, []);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        complete: (results) => {
          const newCsvData: ImportedSample[] = (
            results.data as { [key: string]: string }[]
          )
            .map((it) => {
              try {
                return {
                  OCCURRENCE_ID: it.OCCURRENCE_ID,
                  ITEM_DATA: jsonParse(it.ITEM_DATA as JsonOf<unknown>),
                  ITEM_TYPE_SCHEMA: jsonParse(
                    it.ITEM_TYPE_SCHEMA as JsonOf<Field[]>,
                  ),
                };
              } catch (error) {
                console.error('Error parsing item:', it, 'Error:', error);
                return null;
              }
            })
            .filter((item): item is ImportedSample => {
              return (
                typeof item === 'object' &&
                item !== null &&
                'OCCURRENCE_ID' in item &&
                typeof item.OCCURRENCE_ID === 'string' &&
                item.OCCURRENCE_ID !== ''
              );
            });

          const newDataToViolatesMap = Object.fromEntries(
            newCsvData.map((item) => [
              item.OCCURRENCE_ID,
              { label: 'unlabeled' as const },
            ]),
          );

          const newFileName = file.name;
          const newSelectedJobName = file.name;
          const newSelectedIndex = 0;
          const newDropdownOptions = [
            ...dropdownOptions,
            { value: file.name, label: file.name },
          ];

          setImportedData(
            newCsvData.map((it) => _.omit(it, 'ITEM_TYPE_SCHEMA_FIELD_ROLES')),
          );
          setFileName(newFileName);
          setSelectedJobName(newSelectedJobName);
          setSelectedIndex(newSelectedIndex);
          setDropdownOptions(newDropdownOptions);

          updateStoredData(newFileName, newDataToViolatesMap, newCsvData);
        },
      });
    }
  };

  const markAsViolating = useCallback(() => {
    setDataToViolatesMap((prev) => {
      const currentId = importedData[selectedIndex].OCCURRENCE_ID;
      const updatedMap = {
        ...prev,
        [currentId]: {
          label: 'violates' as const,
          violatingFields: selectedViolatingFields,
        },
      };
      if (selectedJobName) {
        updateStoredData(selectedJobName, updatedMap, importedData);
      }
      return updatedMap;
    });
    setSelectedViolatingFields([]);
  }, [selectedIndex, selectedJobName, importedData, selectedViolatingFields]);

  const markAsNotViolating = useCallback(() => {
    setDataToViolatesMap((prev) => {
      const currentId = importedData[selectedIndex].OCCURRENCE_ID;
      const updatedMap = {
        ...prev,
        [currentId]: {
          label: 'does_not_violate' as const,
        },
      };
      if (selectedJobName) {
        updateStoredData(selectedJobName, updatedMap, importedData);
      }
      return updatedMap;
    });
  }, [selectedIndex, selectedJobName, importedData]);

  const markAsBorderline = useCallback(() => {
    setDataToViolatesMap((prev) => {
      const currentId = importedData[selectedIndex].OCCURRENCE_ID;
      const updatedMap = {
        ...prev,
        [currentId]: {
          label: 'borderline' as const,
          violatingFields: selectedViolatingFields,
        },
      };
      if (selectedJobName) {
        updateStoredData(selectedJobName, updatedMap, importedData);
      }
      return updatedMap;
    });
    setSelectedViolatingFields([]);
  }, [selectedIndex, selectedJobName, importedData, selectedViolatingFields]);

  useEffect(() => {
    const moveToNextItem = () => {
      setSelectedIndex((prevIndex) => prevIndex + 1);
    };

    const handleKeyDown = (event: { key: string }) => {
      if (selectedIndex >= importedData.length) {
        return;
      }

      if (event.key === 'ArrowLeft' && selectedViolatingFields.length === 0) {
        markAsNotViolating();
        moveToNextItem();
      } else if (
        event.key === 'ArrowRight' &&
        selectedViolatingFields.length > 0
      ) {
        markAsViolating();
        moveToNextItem();
      } else if (
        event.key === 'ArrowUp' &&
        selectedViolatingFields.length > 0
      ) {
        markAsBorderline();
        moveToNextItem();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [
    importedData,
    markAsBorderline,
    markAsNotViolating,
    markAsViolating,
    selectedIndex,
    selectedViolatingFields,
  ]);

  const storedData = getAllStoredData();
  const storedJobNames = Object.keys(storedData);
  useEffect(() => {
    setDropdownOptions(
      storedJobNames.map((jobName) => ({
        value: jobName,
        label: jobName,
      })),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileName]);

  if (loading) {
    return <FullScreenLoading />;
  }

  if (
    !userEmail?.includes('@getcove.com') &&
    !userEmail?.includes('@example.com')
  ) {
    return <Navigate replace to="/" />;
  }

  const labelingOutput = filterNullOrUndefined(
    importedData.map((row) => {
      const label = dataToViolatesMap[row.OCCURRENCE_ID];

      const relevantItem = importedData.find(
        (it) => it.OCCURRENCE_ID === row.OCCURRENCE_ID,
      );
      if (!relevantItem) {
        return null;
      }

      if (label.label === 'violates' || label.label === 'borderline') {
        return JSON.stringify([
          ...label.violatingFields.map((it) => ({
            score: label.label === 'violates' ? 1 : 0.5,
            field: it,
          })),
          ...Object.keys(relevantItem.ITEM_DATA)
            .filter((field) => !label.violatingFields.includes(`/${field}`))
            .map((it) => ({
              score: 0,
              field: it,
            })),
        ]);
      } else {
        return JSON.stringify(
          Object.keys(relevantItem.ITEM_DATA).map((it) => ({
            score: 0,
            field: `/${it}`,
          })),
        );
      }
    }),
  );

  const newCsv = (() => {
    const parsedData = labelingOutput.map((row) => ({ score: row }));

    const newCsv = Papa.unparse(parsedData, {
      header: true,
      delimiter: ',',
    });
    console.log(newCsv);
    return newCsv;
  })();

  const jobSelector = (
    <CoveSelect
      mode="single"
      placeholder="Select or upload job"
      value={selectedJobName}
      onSelect={(value) => {
        if (value === 'upload') {
          setSelectedJobName(undefined);
          fileInputRef.current?.click();
        } else if (value === 'delete') {
          localStorage.removeItem('labelingData_items');
          resetState();
        } else {
          // Set state variables for existing job
          const jobData = storedData[value];
          if (jobData) {
            setImportedData(jobData.csvData);
            setDataToViolatesMap(jobData.labels);
            setSelectedIndex(0);
            setSelectedJobName(value);
          } else {
            alert('Job data not found. Reupload csv file.');
          }
        }
      }}
      onDeselect={resetState}
      options={[
        ...dropdownOptions,
        { value: 'upload', label: 'Upload New File' },
        ...(dropdownOptions.length > 0
          ? [{ value: 'delete', label: 'Delete All' }]
          : []),
      ].filter(
        (option): option is { value: string; label: string } => option !== null,
      )}
    />
  );

  const contentSection = (() => {
    if (
      Object.values(dataToViolatesMap).every(
        (it) => it.label !== 'unlabeled',
      ) &&
      selectedIndex >= importedData.length
    ) {
      return (
        <div className="flex flex-row self-start w-1/2 p-4 mt-4 bg-green-100 border border-green-500 gap-2 rounded-md">
          <div className="font-semibold text-green-700">
            All items have been labeled!
          </div>
          <CopyTextComponent
            value={newCsv ?? ''}
            displayValue="Click here to copy labels"
          />
        </div>
      );
    } else {
      const itemToBeLabeled = importedData[selectedIndex];
      const fieldData = getPrimaryContentFields(
        itemToBeLabeled.ITEM_TYPE_SCHEMA.map((field) => ({
          name: field.name,
          type: field.type,
          container: field.container
            ? { valueScalarType: field.container.valueScalarType }
            : undefined,
        })),
        itemToBeLabeled.ITEM_DATA,
      );

      return (
        <pre className="p-4 overflow-auto bg-gray-100 rounded-md text-wrap">
          <div className="mb-4">
            <strong>Current Label:</strong>
            <div className="mt-1 ml-4">
              {
                dataToViolatesMap[importedData[selectedIndex].OCCURRENCE_ID]
                  ?.label
              }
            </div>
          </div>
          <TrainingPipelineSelectableFieldsComponent
            fields={fieldData}
            selectedFieldJsonPointers={selectedViolatingFields}
            onChangeSelectedFields={setSelectedViolatingFields}
            options={{
              unblurAllMedia: true,
              expandAll: true,
              dangerouslySetInnerHTML: true,
              twoColumns: true,
            }}
          />
        </pre>
      );
    }
  })();

  const buttonSection = (
    <div className="flex flex-col items-start gap-2">
      <div className="flex flex-row p-4 gap-4">
        <div
          className={`px-2 py-1 border border-solid select-none ${
            selectedIndex > 0
              ? 'cursor-pointer'
              : 'cursor-not-allowed opacity-50'
          }`}
          onClick={() => {
            if (selectedIndex > 0) {
              setSelectedIndex((prevIndex) => prevIndex - 1);
            }
          }}
        >
          Previous
        </div>
        <div
          className={`px-2 py-1 border border-solid select-none ${
            selectedIndex < importedData.length - 1 ||
            (selectedIndex === importedData.length - 1 &&
              importedData.every(
                (item) =>
                  dataToViolatesMap[item.OCCURRENCE_ID]?.label !== 'unlabeled',
              ))
              ? 'cursor-pointer'
              : 'cursor-not-allowed opacity-50'
          }`}
          onClick={() => {
            if (selectedIndex < importedData.length - 1) {
              setSelectedIndex((prevIndex) => prevIndex + 1);
            } else if (
              selectedIndex === importedData.length - 1 &&
              importedData.every(
                (item) =>
                  dataToViolatesMap[item.OCCURRENCE_ID]?.label !== 'unlabeled',
              )
            ) {
              setSelectedIndex(importedData.length);
            }
          }}
        >
          Next
        </div>
        <div
          className={`px-2 py-1 border border-solid select-none ${
            importedData.some((item, index) => index > selectedIndex)
              ? 'cursor-pointer'
              : 'cursor-not-allowed opacity-50'
          }`}
          onClick={() => {
            const nextUnlabeledIndex = importedData.findIndex(
              (item, _) => dataToViolatesMap[item.OCCURRENCE_ID]?.label == null,
            );

            if (nextUnlabeledIndex !== -1) {
              setSelectedIndex(nextUnlabeledIndex);
            } else {
              setSelectedIndex(importedData.length);
            }
          }}
        >
          Jump to Next Unlabeled Sample
        </div>
      </div>
      <div className="flex gap-8 ">
        <button
          onClick={() => {
            markAsNotViolating();
            setSelectedIndex((prevIndex) => prevIndex + 1);
          }}
          disabled={
            selectedViolatingFields.length > 0 ||
            selectedIndex >= importedData.length
          }
          className="px-4 py-2 my-2 text-white bg-blue-500 rounded cursor-pointer disabled:bg-gray-300"
        >
          ← Does not violate
        </button>
        <button
          onClick={() => {
            markAsBorderline();
            setSelectedIndex((prevIndex) => prevIndex + 1);
          }}
          disabled={
            selectedViolatingFields.length === 0 ||
            selectedIndex >= importedData.length
          }
          className="px-4 py-2 my-2 text-white bg-blue-500 rounded cursor-pointer disabled:bg-gray-300"
        >
          Borderline ↑
        </button>
        <button
          onClick={() => {
            markAsViolating();
            setSelectedIndex((prevIndex) => prevIndex + 1);
          }}
          disabled={
            selectedViolatingFields.length === 0 ||
            selectedIndex >= importedData.length
          }
          className="px-4 py-2 my-2 text-white bg-blue-500 rounded cursor-pointer disabled:bg-gray-300"
        >
          Violates →
        </button>
      </div>
    </div>
  );

  const labelingStats = (
    <div className="mb-8">
      <h3 className="mb-2 text-lg font-semibold">Labeling Statistics</h3>
      <div className="p-4 bg-gray-100 rounded-md">
        {Object.entries(
          Object.values(dataToViolatesMap).reduce(
            (acc, curr) => {
              acc[curr.label] = (acc[curr.label] || 0) + 1;
              return acc;
            },
            {} as Record<string, number>,
          ),
        ).map(([label, count]) => (
          <div key={label} className="flex justify-between mb-2">
            <span>{label}:</span>
            <span>{count}</span>
          </div>
        ))}
        <div className="flex justify-between pt-2 mt-2 font-semibold border-t border-gray-300">
          <span>Total:</span>
          <span>{Object.keys(dataToViolatesMap).length}</span>
        </div>
      </div>
      <CopyTextComponent value={newCsv ?? ''} displayValue="Copy labels" />
    </div>
  );
  return (
    <div className="p-8">
      <input
        type="file"
        ref={fileInputRef}
        // Hidden because we're using the CoveSelect to trigger the file upload,
        // we just need to set the ref here so that the file input is created
        style={{ display: 'none' }}
        accept=".csv"
        onChange={handleFileUpload}
      />
      {importedData.length > 0 && (
        <div className="mt-4">
          <h3>
            {selectedIndex >= importedData.length
              ? 'All done!'
              : `Labeling Item ${selectedIndex + 1} of ${importedData.length}`}
          </h3>
          {buttonSection}
          <div className="flex flex-row gap-4">{contentSection}</div>
        </div>
      )}
      <div className="flex flex-row justify-between">
        <div>
          <h1>Internal Labeling</h1>
          {jobSelector}
        </div>
        {importedData.length > 0 && (
          <div className="flex flex-col w-1/2">
            {importedData.length > 0 && labelingStats}
          </div>
        )}
      </div>
    </div>
  );
}
