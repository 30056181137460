import { Input } from 'antd';

export default function DemoTextInput(props: {
  placeholder?: string;
  disabled?: boolean;
  onChange?: (text: string) => void;
}) {
  const { placeholder, disabled = false, onChange } = props;
  return (
    <Input
      className="px-4 py-2 text-base rounded"
      disabled={disabled}
      placeholder={placeholder}
      onChange={(e) => {
        if (onChange) {
          onChange(e.target.value);
        }
      }}
    />
  );
}
