import { m, useInView } from 'framer-motion';
import { useRef, type SVGProps } from 'react';

import { HorizontalDashes } from '../components/animations/HorizontalDashes';
import { VerticalDashes } from '../components/animations/VerticalDashes';
import { Typography } from '../components/ui/Typography';

import { cn } from '../lib/utils';

interface GridItemProps {
  title: string;
  description: string;
  icon: React.JSXElementConstructor<SVGProps<SVGSVGElement>>;
}

function GridItem({ title, description, icon: Icon }: GridItemProps) {
  const ref = useRef<HTMLDivElement>(null);
  const inView = useInView(ref);

  return (
    <div
      ref={ref}
      className={cn(
        'flex flex-col gap-2 p-4 min-h-0',
        'delay-150 [&:nth-of-type(n+4)]:delay-300 fill-mode-both duration-700 animate-in fade-in-0 slide-in-from-bottom-4',
        { paused: !inView },
      )}
    >
      <Icon width={24} height={24} className="flex-shrink-0" />
      <Typography variant="title" size="md">
        {title}
      </Typography>
      <Typography color="subtle" variant="body" size="md">
        {description}
      </Typography>
    </div>
  );
}

interface FeatureTableProps {
  title: string;
  items: [
    GridItemProps,
    GridItemProps,
    GridItemProps,
    GridItemProps,
    GridItemProps,
    GridItemProps,
  ];
}

export function FeatureTable({ title, items }: FeatureTableProps) {
  return (
    <div className="overflow-hidden">
      <div className="container py-16 mt-8 md:mt-20 gap-8 md:gap-20 grid grid-cols-1 xl:px-24 justify-items-center">
        <m.div
          transition={{ delay: 0.2, duration: 0.5 }}
          initial={{ opacity: 0, y: 22 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true, margin: '-100px' }}
          className="relative z-40 flex flex-col items-start justify-center max-w-6xl px-8 pb-8 gap-4"
        >
          <Typography
            className={cn('text-start lg:text-center [&>span]:text-primary')}
            size="lg"
            as="h2"
            variant="display"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </m.div>

        <div
          className={cn(
            '@container-[size] py-8 lg:py-0 h-[84rem] md:h-[60rem] lg:h-[28rem] container grid lg:grid-rows-2 grid-cols-1 lg:grid-cols-3 relative gap-4 items-start lg:gap-10',
          )}
        >
          <div
            className={cn(
              "bg-graph-paper w-[200vw] h-[200vh] absolute -left-[var(--cell-width)] -top-[var(--cell-height)] -translate-x-[calc(var(--cell-width)*4)] -ml-[calc(-1*theme('container.padding')+theme('gap.10')/2)]",
              '-translate-x-[calc(var(--cell-width))] sm:-translate-x-[calc(var(--cell-width)*4)]',
              "[--cell-width:calc((100cqw/4)+(theme('gap.10')/4))] sm:[--cell-width:calc((100cqw/9)+(theme('gap.10')/9))]",
              '[--cell-height:calc((100cqh+4rem)/24)] md:[--cell-height:calc(100cqh/12)] lg:[--cell-height:calc(100cqh/4)]',
              'mask-circle',
            )}
            style={{
              maskPosition: 'top left',
              maskSize: '220cqw 150cqh',
              maskRepeat: 'no-repeat',
              maskImage:
                'radial-gradient(ellipse at center, var(--kit-mask-stops))',
              backgroundSize: 'var(--cell-width) var(--cell-height)',
            }}
          />

          <HorizontalDashes
            className="absolute top-0 left-0 -translate-x-1/2 mask-to-l"
            width="50%"
          />

          <HorizontalDashes
            className="absolute top-0 right-0 translate-x-1/2 mask-to-r"
            width="50%"
          />

          <VerticalDashes
            className="absolute top-0 -translate-y-1/2 left-3 mask-to-t"
            height="50%"
          />

          <VerticalDashes
            className="absolute top-0 -translate-y-1/2 right-3 translate-x-px mask-to-t"
            height="50%"
          />

          <HorizontalDashes
            className="absolute bottom-0 left-0 -translate-x-1/2 mask-to-l"
            width="50%"
          />

          <HorizontalDashes
            className="absolute bottom-0 right-0 translate-x-1/2 mask-to-r"
            width="50%"
          />

          <VerticalDashes
            className="absolute bottom-0 translate-y-1/2 left-3 mask-to-b"
            height="50%"
          />

          <VerticalDashes
            className="absolute bottom-0 translate-y-1/2 right-3 translate-x-px mask-to-b"
            height="50%"
          />

          {items.map((item) => (
            <GridItem key={item.title} {...item} />
          ))}
        </div>
      </div>
    </div>
  );
}
