import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

export default function TermsOfUse() {
  const intro = (
    <div className="font-bold text-start">
      Effective March 25th, 2022
      <div className="font-normal">
        <br />
        Please read the following carefully. These Terms of Use (these “
        <span className="font-medium">Terms</span>”) govern your access to and
        use of the Cove Software, Inc. (“
        <span className="font-medium">Cove</span>,” “
        <span className="font-medium">we</span>,” “
        <span className="font-medium">us</span>,” “
        <span className="font-medium">our</span>”) website at getcove.com (the{' '}
        <span className="font-medium">“Site</span>”), our content moderation
        integration platform (the “<span className="font-medium">Platform</span>
        ”), and the products and services provided by Cove (collectively, with
        the Site and Platform, the “<span className="font-medium">Service</span>
        ”).
        <br />
        <br />“<span className="font-medium">You</span>” or “
        <span className="font-medium">your</span>” or similar terms refer to you
        as a user of our Service. If you or the entity you represent has entered
        into a separate agreement with us in connection with the use of any of
        Cove products or services, then that agreement will control where it
        conflicts with the terms hereof.
        <br />
        <br />
        <span className="font-medium">This is a binding agreement</span>. If you
        use the Service or click accept or agree to these Terms if presented to
        you in a user interface for the Service, we will understand this as your
        acceptance of these Terms and your agreement to all of its terms and
        conditions. By accepting these Terms or using the Service, you represent
        and warrant that you have the legal capacity to enter a contract in the
        jurisdiction where you reside. If you are entering into these Terms on
        behalf of a company or other legal entity, you represent and warrant
        that you have the authority to bind that party to these Terms, and in
        such event and unless otherwise noted, “you” and “your” will refer and
        apply to that party.{' '}
        <span className="font-medium">
          If you do not agree to all of these Terms, you are not authorized to
          use the Service and you must promptly cease using it
        </span>
        .
        <br />
        <br />
        <span className="font-medium">
          By agreeing to these Terms, you expressly agree to the arbitration of
          all Disputes as further described below
        </span>
        . Any controversy, allegation, or claim that arises out of or relates to
        the Service, these Terms, or any additional terms, whether heretofore or
        hereafter arising (collectively, a “
        <span className="font-medium">Dispute</span>”), except for any
        controversy, allegation, or claim that arises out of or relates to our
        actual or alleged intellectual property rights (an “
        <span className="font-medium">Excluded Dispute</span>”), shall be
        finally resolved by arbitration.{' '}
        <span className="font-medium">
          The parties agree to arbitrate solely on an individual basis, and that
          these Terms do not permit class arbitration, or any claims brought as
          a plaintiff or class member in any class or representative arbitration
          proceeding
        </span>
        . The arbitrator or arbitral panel may not consolidate more than one
        person's claims and may not otherwise preside over any form of a
        representative or class proceeding. In the event the prohibition on
        class arbitration is deemed invalid or unenforceable, then the remaining
        portions of the arbitration provisions will remain in force.
      </div>
    </div>
  );

  const tableOfContents = (
    <div className="p-4 pb-8">
      <div>
        1. <HashLink to="#access">Your Access to the Service</HashLink>
      </div>
      <div>
        2.{' '}
        <HashLink to="#permitted-use">Permitted Use and Restrictions</HashLink>
      </div>
      <div>
        3. <HashLink to="#privacy">Privacy</HashLink>
      </div>
      <div>
        4. <HashLink to="#accounts">Accounts</HashLink>
      </div>
      <div>
        5.{' '}
        <HashLink to="#moderation">
          Content Moderation and Submitted Content
        </HashLink>
      </div>
      <div>
        6. <HashLink to="#location">Location</HashLink>
      </div>
      <div>
        7. <HashLink to="#dmca">DMCA Notice</HashLink>
      </div>
      <div>
        8. <HashLink to="#idea">Idea Submissions</HashLink>
      </div>
      <div>
        9. <HashLink to="#ip">Intellectual Property Rights</HashLink>
      </div>
      <div>
        10.{' '}
        <HashLink to="#third-party">Third Party Sites and Services</HashLink>
      </div>
      <div>
        11.{' '}
        <HashLink to="#california">
          Notice for California Residents Pursuant to California Civil Code
          Section 1789.3
        </HashLink>
      </div>
      <div>
        12. <HashLink to="#indemnity">Indemnity</HashLink>
      </div>
      <div>
        13. <HashLink to="#warranty">Warranty Disclaimer</HashLink>
      </div>
      <div>
        14. <HashLink to="#liability">Limitation of Liability</HashLink>
      </div>
      <div>
        15. <HashLink to="#termination">Termination</HashLink>
      </div>
      <div>
        16. <HashLink to="#communication">Communication Between Us</HashLink>
      </div>
      <div>
        17. <HashLink to="#law">Governing Law</HashLink>
      </div>
      <div>
        18. <HashLink to="#dispute">Dispute Resolution</HashLink>
      </div>
      <div>
        19. <HashLink to="#other">Other Important Terms</HashLink>
      </div>
      <div>
        20. <HashLink to="#changes">Changes to These Terms</HashLink>
      </div>
      <div>
        21. <HashLink to="#contact">Contact Information</HashLink>
      </div>
    </div>
  );

  const access = (
    <div className="py-4" id="access">
      <div className="pb-2 text-lg font-bold">
        1. Your Access to the Service
      </div>
      <ol className="my-0" type="a">
        <li>
          <span className="italic">Internet Access</span>. When using the
          Service on your mobile, laptop, desktop, or other device (“
          <span className="font-medium">Device</span>”), you acknowledge and
          agree that you are responsible for (i) maintaining Internet access for
          your Device and (ii) any Internet connection and telecommunications
          fees and charges that you incur.
        </li>
        <li>
          <span className="italic">Your Device</span>. Cove is not responsible
          for the operation of your Device. You are responsible for ensuring the
          system functions of your Device are in working order when accessing
          the Service, including, but not limited to screen display operation
          features of your Device.
        </li>
        <li>
          <span className="italic">No Guarantee</span>. Access to the Service
          may be suspended temporarily and without notice (i) in the event of
          system failure, (ii) for maintenance or repair, (iii) where we
          reasonably suspect there has been a breach of these Terms, (iv) for
          reasons reasonably beyond our control, or (v) as otherwise explained
          in these Terms.
        </li>
      </ol>
    </div>
  );

  const permittedUse = (
    <div className="py-4" id="permitted-use">
      <div className="pb-2 text-lg font-bold">
        2. Permitted Use and Restrictions
      </div>
      <ol className="my-0" type="a">
        <li>
          <span className="italic">License Grant</span>. Subject to the terms
          and conditions of these Terms, Cove hereby grants you a limited,
          non-exclusive, personal, non-transferrable, non-sublicensable,
          non-assignable, license to access and use the Service (including
          updates and upgrades that replace or supplement it in any respect and
          which are not distributed with a separate license, and any
          documentation) solely in connection with content moderation services
          for your website and/or mobile application and solely in the manner
          described in these Terms and in any technical documentation contained
          in, or provided with, the Service. You acknowledge and agree that the
          Service is provided under license, and not sold, to you. You do not
          acquire any ownership interest in the Service under these Terms, or
          any other rights thereto other than to use the Service in accordance
          with the license granted, and subject to all terms, conditions, and
          restrictions of these Terms. We reserve all other rights that are not
          granted in these Terms.
        </li>
        <li>
          <span className="italic">Use Restrictions</span>. You may not access
          or use the Service in any way that is not expressly permitted by these
          Terms. You may not: (i) cause, permit or authorize the modification,
          copy, creation of derivative works, translation, reverse engineering,
          decompiling, disassembling, or hacking of the Service; (ii) sell,
          assign, rent, lease, or grant rights in the Service, including,
          without limitation, through sublicense, to any other person or entity;
          or (iii) use the Service for any unlawful, prohibited, abnormal, or
          unusual activity as determined by Cove in its sole discretion.
        </li>
        <li>
          <span className="italic">Eligibility</span>. In order to access and
          use the Service or register an account you must be an individual at
          least 18 years of age or a duly organized, validly existing business,
          organization, or other legal entity in good standing under any and all
          applicable laws, and able to enter into legally binding contracts.{' '}
          <span className="font-medium">
            Individuals under the age of 18 are not permitted to use the
            Service.
          </span>
        </li>
        <li>
          <span className="italic">Investigations</span>. We may, but are not
          obligated to, monitor, or review our Service at any time. If we become
          aware of any possible violations by you of these Terms, we reserve the
          right to investigate such violations, and we may, at our sole
          discretion, immediately terminate your license to use the Service
          pursuant to Section 15 (<span className="italic">Termination</span>)
          below.
        </li>
        <li>
          <span className="italic">Violation of these Terms</span>. You must not
          use (or permit a third-party to use) the Service: (i) in any unlawful
          manner, for any unlawful purpose, or to act fraudulently or
          maliciously, for example, by hacking into or inserting malicious code,
          including viruses, or harmful data, into the Service or any operating
          system used by the Service; (ii) in a way that could damage, disable,
          overburden, impair or compromise our systems or security, or interfere
          with other users; (iii) to collect or harvest any information or data
          from the Service or our systems or attempt to decipher any
          transmissions to or from the servers running the Service; (iv) via use
          of a robot, spider, or other automated device to monitor or copy the
          Service or any information provided by the Service; (v) to send,
          knowingly receive, upload, download, use, or re-use any material which
          does not comply with these Terms; or (vi) to transmit, or procure the
          sending of, any unsolicited or unauthorized advertising or promotional
          material or any other form of similar solicitation (spam). You
          acknowledge and agree that you are solely responsible, and Cove has no
          responsibility or liability to you or any other person or entity for,
          any breach by you of these Terms or for the consequences of any such
          breach.
        </li>
      </ol>
    </div>
  );

  const privacy = (
    <div className="py-4" id="privacy">
      <div className="pb-2 text-lg font-bold">3. Privacy</div>
      <ol className="my-0" type="a">
        <li>
          These Terms also incorporate the terms of our{' '}
          <Link to="/privacy" className="underline">
            Privacy Policy
          </Link>{' '}
          (as updated from time-to-time). Our Privacy Policy explains how your
          personal information will be collected and used as well as other
          information regarding your privacy. By agreeing to these Terms, you
          are also agreeing to the Privacy Policy and you consent to (i) the
          processing of your personal information as explained in the Privacy
          Policy and (ii) the collection of information from your Device as
          explained in the Privacy Policy.
        </li>
      </ol>
    </div>
  );

  const accounts = (
    <div className="py-4" id="accounts">
      <div className="pb-2 text-lg font-bold">4. Accounts</div>
      <ol className="my-0" type="a">
        <li>
          <span className="italic">Accounts</span>. To use certain aspects of
          the Service, you may be required to create a Cove account and provide
          your name, email address, and certain other information. You are
          solely responsible for the information associated with your account
          and anything that happens related to your account. You agree to
          provide true, accurate, current, and complete information as requested
          by any forms and maintain and update such information to keep it true,
          accurate, current, and complete. Your failure to maintain true,
          accurate, current, and complete account information may result in your
          inability to fully access or use the Service.
        </li>
        <li>
          <span className="italic">Account Security</span>. Maintaining account
          security is very important. You are solely responsible for maintaining
          the confidentiality of your account password. You agree to notify Cove
          immediately if you become aware of any unauthorized use of your
          password or your account.
        </li>
        <li>
          <span className="italic">Account Sharing or Transfers</span>. Accounts
          are registered to you personally and may not be sold, traded, gifted,
          or otherwise transferred at any time under any circumstances. You may
          not share your account with, or disclose your password to, anyone
          else.
        </li>
        <li>
          <span className="italic">Account Deletion by You</span>. To delete
          your account, please go to your{' '}
          <Link to="/dashboard/account" className="underline">
            Account Settings
          </Link>
          , or contact us at the information below in Section 21 (
          <span className="italic">Contact Information</span>
          ).
        </li>
        <li>
          <span className="italic">Account Deletion by Us</span>. Cove may
          terminate your account at any time for any reason or no reason,
          including if: (i) Cove determines that you are (A) in breach of or
          otherwise acting inconsistently with these Terms or (B) engaging in
          fraudulent or illegal activities or other conduct that may result in
          liability to Cove; (ii) Cove determines it is required by law to
          terminate your account; or (iii) Cove decides to stop providing the
          Service or critical portions of the Service. When terminating your
          account, Cove may delete your account and the information in it. You
          have no ownership rights to your account.
        </li>
      </ol>
    </div>
  );

  const moderation = (
    <div className="py-4" id="moderation">
      <div className="pb-2 text-lg font-bold">
        5. Content Moderation and Submitted Content
      </div>
      <ol className="my-0" type="a">
        <li>
          The Platform allows users to submit information, data, text, music,
          sound, photographs, graphics, videos, messages, and other materials ("
          <span className="font-medium">Submitted Content</span>") for analysis,
          assessment, and management (including to act or to refrain from acting
          with respect to such content) via application programming interfaces
          ("
          <span className="font-medium">Content Moderation APIs</span>"). You
          acknowledge and agree that you are solely responsible for any
          third-party Content Moderation APIs that you choose to integrate
          through the Platform (including, but not limited to, acquiring and
          abiding by any licenses for the Content Moderation APIs) and the rules
          you set with respect to the Platform. You further acknowledge and
          agree that you are responsible for all decisions made, advice given,
          actions taken, and failures to take action based on your use of the
          Platform and Content Moderation APIs.
        </li>
        <li>
          The Content Moderation APIs may use machine learning models that
          generate predictions based on patterns in data. Output generated by
          machine learning is probabilistic and should be evaluated for accuracy
          as appropriate for your case, including by employing human review of
          such output.
        </li>
        <li>
          You are responsible for providing legally adequate privacy notices to
          any end users of your products or services that use the Platform or
          Content Moderation APIs, and obtaining any necessary consent from such
          end users for the processing of Submitted Content and the storage,
          use, and transfer of Submitted Content, including, but not limited to,
          providing any required notices and obtaining any required verifiable
          parental consent under the Children's Online Privacy Protection Act
          (COPPA) or similar laws. You represent to us that you have provided
          all necessary privacy notices and obtained all necessary consents.
        </li>
        <li>
          Cove has the right, but not the obligation, to remove or block
          Submitted Content that it deems, in its sole discretion, poses a risk
          to Cove or other users or is otherwise objectionable including, but
          not limited to, being unlawful, offensive, threatening, libelous,
          defamatory, pornographic, obscene, or violating any party's
          intellectual property.
        </li>
        <li>
          You agree that, under no circumstances, will Cove be liable in any way
          for any Submitted Content, including, but not limited to, any errors
          or omissions in any Submitted Content, or any loss or damage of any
          kind incurred as a result of the use or distribution of any Submitted
          Content transmitted or otherwise made available via the Service.
        </li>
        <li>
          Cove does not claim ownership of the Submitted Content. With respect
          to all of your Submitted Content, you grant Cove a perpetual,
          irrevocable, non-terminable, transferable, worldwide, royalty-free,
          sublicensable, fully paid-up, non-exclusive and transferable license
          to use, reproduce, distribute, prepare derivative works of, display,
          modify, copy and perform the Submitted Content or any part of the
          Submitted Content in connection with providing the Platform and
          Content Moderation APIs to you. You hereby represent, warrant, and
          covenant that any Submitted Content you provide does not include
          anything (including, but not limited to, text, images, music, or
          video) to which you do not have the full right to grant the license
          specified in this Section 5 (
          <span className="italic">
            Content Moderation and Submitted Content
          </span>
          ).
        </li>
      </ol>
    </div>
  );

  const location = (
    <div className="py-4" id="location">
      <div className="pb-2 text-lg font-bold">6. Location</div>
      <ol className="my-0" type="a">
        <li>
          The Service is available to users in the United States. If you choose
          to access the Service from a location outside the United States, you
          do so on your own initiative and you are responsible for compliance
          with applicable local laws.
        </li>
      </ol>
    </div>
  );

  const dmca = (
    <div className="py-4" id="dmca">
      <div className="pb-2 text-lg font-bold">7. DMCA Notice</div>
      <ol className="my-0" type="a">
        <li>
          Cove has adopted a policy that provides for the suspension and/or
          termination of any user who is found to have infringed on the rights
          of Cove or of a third party, or otherwise violated any intellectual
          property laws or regulations. Cove's policy is to investigate any
          allegations of copyright infringement brought to its attention.
        </li>
        <li>
          <span className="py-4">Take-Down Notice</span>. If you have evidence,
          know, or have a good faith belief that your rights or the rights of a
          third party have been violated and you want Cove to delete, edit, or
          disable the material in question, you must provide Cove with all of
          the following information (as required by the Online Copyright
          Infringement Liability Limitation Act of the Digital Millennium
          Copyright Act (DMCA)) to our designated copyright agent set forth
          below:
          <ul>
            <li>
              A physical or electronic signature of the copyright owner or the
              person authorized to act on behalf of the owner of an exclusive
              copyright that is allegedly infringed;
            </li>
            <li>
              Identification of the copyrighted work (or works) claimed to have
              been infringed;
            </li>
            <li>
              Identification of the material that is claimed to be infringing
              and information reasonably sufficient to permit Cove to locate the
              material (providing URLs in the content of an email is the best
              way to help us locate content quickly);
            </li>
            <li>
              Information reasonably sufficient to permit Cove to contact you,
              including your address, telephone number, and e-mail address at
              which you may be contacted;
            </li>
            <li>
              A statement that you have a good faith belief that use of the
              material in the manner complained of is not authorized by you, or
              your agent, or the law;
            </li>
            <li>
              A statement made under penalty of perjury, that the above
              information in your notice is accurate and that you are the
              copyright owner or are authorized to act on the copyright owner's
              behalf.
              <br />
              <br />
              See 17 U.S.C 512(c)(3) for further information.
            </li>
          </ul>
          <br />
          For this notification to be effective, you must provide it to Cove's
          Designated Agent by email at dmca@getcove.com. You can also mail your
          DMCA request to:
          <div style={{ padding: '20px' }}>
            Attn: Michael Dworsky
            <br />
            Cove Software, Inc.
            <br />
            P.O. Box 1341
            <br />
            New York, NY 10276
          </div>
          Mailing DMCA notices may delay the processing of your request. Only
          copyright complaints should be sent to the Designated Agent. No other
          communications will be accepted or responded to.
          <br />
          <br />
          If you fail to comply with all of the requirements above, your DMCA
          notice may not be valid and not be acknowledged. Once proper notice is
          received by the Designated Agent, it is Cove's policy:
          <ul>
            <li>to remove or disable access to the infringing material;</li>
            <li>
              to notify the content provider, member, or user that it has
              removed or disabled access to the material; and
            </li>
            <li>
              that repeat offenders will have the infringing material removed
              from the system and that Cove will terminate such content
              provider's, member's, or user's access to the service.
            </li>
          </ul>
        </li>
        <li>
          <span className="italic">Counter-Notice</span>. If you believe that
          the material that was removed or to which access has been disabled is
          either not infringing, or you believe that you have the right to post
          and use such material from the copyright owner, the copyright owner's
          agent, or pursuant to the law, you must send a counter-notice
          containing the following information to the Designated Agent:
          <ul>
            <li>Your physical or electronic signature;</li>
            <li>
              Identification of the material that has been removed or to which
              access has been disabled and the location at which the material
              appeared before it was removed or access to it was disabled;
            </li>
            <li>
              A statement under penalty of perjury that you have a good faith
              belief that the material was removed or disabled as a result of
              mistake or misidentification of the material to be removed or
              disabled;
            </li>
            <li>Your name, address, and telephone number; and</li>
            <li>
              A statement that you consent to the jurisdiction of federal
              district court in the judicial district in which your address is
              located, or if your address is outside of the United States, for
              any judicial district in which you may be found and that you will
              accept service of process from the person who provided the initial
              notification of alleged infringement.
            </li>
          </ul>
          <br />
          If a counter-notice is received by the Designated Agent, Cove may send
          a copy of the counter-notice to the original complaining party
          informing that person that it may replace the removed material or
          cease disabling it in ten (10) business days. Unless the copyright
          owner files an action seeking a court order against the content
          provider, member, or user, the removed material may be replaced or
          access to it restored in ten (10) to fourteen (14) business days or
          more after receipt of the counter-notice, at Cove's discretion.
        </li>
      </ol>
    </div>
  );

  const idea = (
    <div className="py-4" id="idea">
      <div className="pb-2 text-lg font-bold">8. Idea Submissions</div>
      <ol className="my-0" type="a">
        <li>
          We welcome feedback from our users and appreciate your comments
          regarding our Service. However, our company policy does not permit us
          to accept or consider ideas, suggestions, proposals, or materials (“
          <span className="font-medium">Submissions</span>”) that we have not
          specifically requested. This policy is intended to help us, and our
          customers avoid future misunderstandings when new products or services
          developed internally by our employees might be similar or even
          identical to a customer's idea.
        </li>
        <li>
          If, despite our request that you not send us your ideas, you still
          submit them, then regardless of any conditions you may have attempted
          to place on your Submission, the following terms shall apply to your
          Submission: you acknowledge and agree that: (i) such Submissions will
          be considered non-confidential and non-proprietary; (ii) we have the
          right (subject to our Privacy Policy), without limit in time and
          without payment to you, to use, copy, distribute, adapt, and disclose
          it via the Service or otherwise to third parties for any purpose, in
          any way, and in any media worldwide now known or later discovered,
          including, without limitation, the right to create derivative works,
          make improvements, perform (including through digital performance),
          and transmit (including through digital transmissions) such
          Submissions, and the right to transfer or sublicense such rights;
          (iii) we may have something similar to the Submissions already under
          consideration or in development; and (iv) you are not entitled to any
          compensation or reimbursement of any kind from us in connection with
          the Submissions under any circumstances.
        </li>
      </ol>
    </div>
  );

  const ip = (
    <div className="py-4" id="ip">
      <div className="pb-2 text-lg font-bold">
        9. Intellectual Property Rights
      </div>
      <ol className="my-0" type="a">
        <li>
          <span className="italic">Trademarks</span>. The Cove name and logo are
          trademarks and service marks of Cove. You do not have the right to use
          any of our trademarks, service marks, or logos, and your unauthorized
          use of any of these may be a violation of federal and state trademark
          laws.
        </li>
        <li>
          <span className="italic">Ownership</span>. Except for your Submitted
          Content, you acknowledge that all intellectual property rights in the
          Service, whether registered or unregistered, including but not limited
          to rights in graphics, logos, “look and feel,” trade dress, structure,
          organization, code, Submitted Content belonging to other users, and
          all other content in the Service and compilation thereof, anywhere in
          the world, belong to us or our licensors and are valuable trade
          secrets and confidential information of Cove, and are protected by
          intellectual property laws. Except for your Submitted Content, you
          acknowledge and agree that Cove, and/or its licensors, own all right,
          title, and interest in and to the Service, including all intellectual
          property, industrial property, and proprietary rights recognized
          anywhere in the world at any time and that the Service is protected by
          U.S. and international copyright laws. Further, you acknowledge that
          the Service may contain information that Cove has designated as
          confidential, and you agree not to disclose such information without
          Cove's prior written consent. Nothing posted on the Service grants a
          license to any Cove trademarks, copyrights, or other intellectual
          property rights, whether by implication, estoppel or otherwise. You
          should assume that everything you see or read on the Service is
          proprietary information protected by copyright or trademark unless
          otherwise noted and may not be used except with the written permission
          of Cove. When accessing the Service, you agree to obey the law and to
          respect the intellectual property rights of others. Your use of the
          Service is at all times governed by and subject to laws regarding
          copyright ownership and use of intellectual property.
        </li>
      </ol>
    </div>
  );

  const thirdParty = (
    <div className="py-4" id="third-party">
      <div className="pb-2 text-lg font-bold">
        10. Third-Party Sites and Services
      </div>
      <ol className="my-0" type="a">
        <li>
          The Service may contain links to or allow you to share content
          directly with other third-party websites and application programming
          interfaces (“
          <span className="font-medium">Third-Party Sites and APIs</span>”). You
          acknowledge that we have no control over the Third-Party Sites and
          APIs and are not responsible for their contents and/or availability.
          We do not assume any liability for your use of any of the foregoing,
          which use you acknowledge and agree shall be at your own risk.
        </li>
        <li>
          These links are provided to you for convenience only and do not
          constitute an endorsement or approval by us of the organizations that
          operate such websites, the content, or other material contained in the
          Third-Party Sites and APIs, and we have no association with their
          operators. Your use of the Third-Party Sites and APIs will be governed
          by their terms and conditions and privacy policies (if any) (“
          <span className="font-medium">Third-Party Terms</span>”). It is your
          responsibility to read and comply with Third-Party Terms.
        </li>
      </ol>
    </div>
  );

  const california = (
    <div className="py-4" id="california">
      <div className="pb-2 text-lg font-bold">
        11. Notice for California Residents Pursuant to California Civil Code
        Section 1789.3
      </div>
      <ol className="my-0" type="a">
        <li>
          Under California Civil Code Section 1789.3, California users of an
          electronic commercial service receive the following consumer rights
          notice: California residents may reach the Complaint Assistance Unit
          of the Division of Consumer Services of the California Department of
          Consumer Affairs by mail at 1625 North Market Blvd. Suite N 112,
          Sacramento, CA 95834, or by telephone at 800-952-5210.
        </li>
      </ol>
    </div>
  );

  const indemnity = (
    <div className="py-4" id="indemnity">
      <div className="pb-2 text-lg font-bold">12. Indemnity</div>
      <ol className="my-0" type="a">
        <li>
          You agree to indemnify and hold us and our affiliates, and their
          respective business partners, licensees, licensors, officers,
          directors, employees, and agents (the “
          <span className="font-medium">Indemnified Parties</span>”) harmless
          from and against any and all claims, demands, losses, damages,
          liabilities, costs, and expenses (including, without limitation,
          reasonable attorneys' fees and costs), arising out of or in connection
          with: (a) your use of the Service; (b) your breach or violation of any
          of these Terms; or (c) your violation of the rights of any third
          party. We reserve the right to assume the exclusive defense and
          control of any matter subject to indemnification by you, which shall
          not excuse your indemnity obligations. In such event, you shall
          provide the Indemnified Parties with such cooperation as is reasonably
          requested by the Indemnified Parties.
        </li>
      </ol>
    </div>
  );

  const warranty = (
    <div className="py-4" id="warranty">
      <div className="pb-2 text-lg font-bold">13. Warranty Disclaimer</div>
      <ol className="my-0" type="a">
        <li>
          WE PROVIDE THE SERVICE ON AN “AS IS” AND “AS AVAILABLE” BASIS. WE MAKE
          NO GUARANTEE THAT THE SERVICE WILL BE UNINTERRUPTED, ERROR FREE, OR
          FREE FROM VIRUSES OR OTHER HARMFUL COMPONENTS. WE HAVE NO OBLIGATION
          TO CORRECT ANY BUGS, DEFECTS OR ERRORS IN THE SERVICE OR TO OTHERWISE
          SUPPORT, DEVELOP OR MAINTAIN THE SERVICE. While we take reasonable
          precautions to prevent the existence of computer viruses and/or other
          malicious programs, we accept no liability for them. We also make no
          promises or guarantees, whether express or implied, that the content
          included on the Service is accurate, complete, or up to date.
        </li>
        <li>
          TO THE MAXIMUM EXTENT PERMITTED BY LAW, WE EXCLUDE ALL CONDITIONS,
          WARRANTIES, REPRESENTATIONS AND OTHER TERMS, WHICH MAY APPLY TO THE
          SERVICE WHETHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION
          IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
          PURPOSE, TITLE, AND NON-INFRINGEMENT OF THE RIGHTS OF THIRD PARTIES
          WITH RESPECT TO THE SERVICE AND ALL INFORMATION AND CONTENT INCLUDED
          ON THE SERVICE.
        </li>
        <li>
          No information or advice obtained through the Service, or affirmation
          by us, by words or actions, shall constitute a warranty.
        </li>
        <li>
          Because some states or jurisdictions do not allow the disclaimer of
          implied warranties, the foregoing disclaimers may not apply to you.
        </li>
      </ol>
    </div>
  );

  const liability = (
    <div className="py-4" id="liability">
      <div className="pb-2 text-lg font-bold">14. Limitation of Liability</div>
      <ol className="my-0" type="a">
        <li>
          IN NO EVENT SHALL THE INDEMNIFIED PARTIES BE LIABLE TO YOU FOR ANY
          DIRECT, INDIRECT, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES
          (INCLUDING LOST PROFITS, ANY BREACH OF SECURITY OR ANY DAMAGE TO YOUR
          DEVICE, LOST DATA, PERSONAL INJURY, PROPERTY DAMAGE, OR LOSSES ARISING
          OUT OF YOUR USE OF OR RELIANCE ON THE SERVICE OR YOUR INABILITY TO
          ACCESS OR USE THE SERVICE) ARISING FROM, RELATING TO, OR IN ANY WAY
          CONNECTED WITH THE USE OR THE PERFORMANCE OF THE SERVICE OR THESE
          TERMS, ARISING AND WHETHER FRAMED IN CONTRACT OR TORT, REGARDLESS OF
          THE NEGLIGENCE (EITHER ACTIVE, AFFIRMATIVE, SOLE, OR CONCURRENT) OF
          COVE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        </li>
        <li>
          Your sole remedy for dissatisfaction with the Service including,
          without limitation, content offered on the Service, is to stop using
          the Service. Such limitation shall also apply with respect to damages
          incurred by reason of services or products received through or
          advertised in connection with the Service or any links on the Service,
          as well as by reason of any information or advice received through or
          advertised in connection with the Service or any links on the Service.
          Such limitation shall also apply with respect to damages incurred by
          reason of any content posted by a third-party or conduct of a
          third-party on the Service.
        </li>
        <li>
          In the event the foregoing exclusion of liability is determined, in
          whole or in part, to be invalid or unenforceable, then the Indemnified
          Parties' liability arising in connection with the Service or under
          these Terms whether in contract, tort (including negligence) or
          otherwise, shall not exceed, under any circumstances, the greater of:
          (i) the total amount paid for services purchased through the Service
          in the preceding 30 days, or (ii) One Hundred Dollars ($100). You
          agree that any claim or cause of action arising under these Terms or
          the performance or non-performance of the Service must be brought
          within one year after such claim or cause of action arises or be
          forever barred.
        </li>
        <li>
          THE LIMITATIONS AND DISCLAIMER IN THIS SECTION DO NOT PURPORT TO LIMIT
          LIABILITY OR ALTER YOUR RIGHTS AS A CONSUMER THAT CANNOT BE EXCLUDED
          UNDER APPLICABLE LAW. BECAUSE SOME STATES OR JURISDICTIONS DO NOT
          ALLOW THE EXCLUSION OF OR THE LIMITATION OF LIABILITY FOR
          CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN SUCH STATES OR JURISDICTIONS,
          COVE'S LIABILITY SHALL BE LIMITED TO THE EXTENT PERMITTED BY LAW. THIS
          PROVISION SHALL HAVE NO EFFECT ON COVE'S GOVERNING LAW PROVISION SET
          FORTH BELOW.
        </li>
        <li>
          IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE CALIFORNIA CIVIL CODE
          SECTION 1542, WHICH STATES: “A GENERAL RELEASE DOES NOT EXTEND TO
          CLAIMS THAT THE CREDITOR OR RELEASING PARTY DOES NOT KNOW OR SUSPECT
          TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE AND
          THAT, IF KNOWN BY HIM OR HER WOULD HAVE MATERIALLY AFFECTED HIS OR HER
          SETTLEMENT WITH THE DEBTOR OR RELEASED PARTY.”
        </li>
      </ol>
    </div>
  );

  const termination = (
    <div className="py-4" id="termination">
      <div className="pb-2 text-lg font-bold">15. Termination</div>
      <ol className="my-0" type="a">
        <li>
          We may terminate these Terms and/or terminate your permission to use
          the Service immediately, without prior notice or liability, if (i) you
          commit any breach of these Terms, (ii) we discontinue the Service, or
          (iii) we are prevented from providing the Service for any reason.
        </li>
        <li>
          Furthermore, we reserve the right to change, edit, suspend, delete,
          and/or cancel any part of the Service and/or your access to it at any
          time with or without notice to you: (i) if required by law, or (ii)
          due to an event beyond our control.
        </li>
        <li>
          On termination of these Terms for any reason: (i) all rights granted
          to you under these Terms will cease immediately, (ii) you must
          immediately cease all activities authorized by these Terms (including
          your use of the Service), and (iii) you acknowledge that we may
          restrict your access to the Service. Sections 3, 9, and 12-19 will
          survive any termination or expiration of these Terms.
        </li>
      </ol>
    </div>
  );

  const communication = (
    <div className="py-4" id="communication">
      <div className="pb-2 text-lg font-bold">16. Communication Between Us</div>
      <ol className="my-0" type="a">
        <li>
          If you wish to contact us in writing, or if any condition in these
          Terms require you to give us notice in writing, you can send this to
          us as indicated in Section 21 (
          <span className="italic">Contact Information</span>) at the bottom of
          these Terms. If we have to contact you or give you notice in writing,
          we may do so by email or using any other contact details you provide
          to us.
        </li>
      </ol>
    </div>
  );

  const law = (
    <div className="py-4" id="law">
      <div className="pb-2 text-lg font-bold">17. Governing Law</div>
      <ol className="my-0" type="a">
        <li>
          These Terms and any matter arising out of or relating to these Terms,
          and any claim, cause of action, controversy, or matter in dispute
          between you and us, whether sounding in contract, tort, statute,
          regulation, or otherwise, shall be governed by the internal laws of
          the State of New York in the United States, consistent with the
          Federal Arbitration Act, without regard to any choice or conflict of
          laws principles (whether of the State of New York or any other
          jurisdiction). You and Cove agree that, except as otherwise provided
          below, the state and federal courts located in the County and City of
          New York, New York will have exclusive jurisdiction of all disputes
          arising out of or related to these Terms or your use of the Service
          and agree to submit to the personal jurisdiction and venue of these
          courts. Notwithstanding the foregoing, Cove shall be allowed to apply
          for equitable remedies (including injunctions) in any jurisdiction.
        </li>
      </ol>
    </div>
  );

  const dispute = (
    <div className="py-4" id="dispute">
      <div className="pb-2 text-lg font-bold">18. Dispute Resolution</div>
      <ol className="my-0" type="a">
        <li>
          <span className="italic">Concerns</span>. Most user concerns can be
          resolved quickly by contacting us at the contact details in Section 21
          (<span className="italic">Contact Information</span>).
        </li>
        <li>
          <span className="italic">Arbitration Procedures</span>. In the event
          your concern cannot be resolved informally, you and Cove agree that,
          except as provided in Section 18(e) below, all Disputes, (each a “
          <span className="font-medium">Claim</span>”), shall be finally and
          exclusively resolved by binding arbitration, which may be initiated by
          either party by sending a written notice requesting arbitration to the
          other party. Any election to arbitrate by one party shall be final and
          binding on the other. The arbitration will be conducted under the
          Streamlined Arbitration Rules and Procedures of JAMS that are in
          effect at the time the arbitration is initiated (the “
          <span className="font-medium">JAMS Rules</span>”) and under the terms
          set forth in these Terms. In the event of a conflict between the terms
          set forth in this Section 18 (
          <span className="italic">Dispute Resolution</span>) and the JAMS
          Rules, the terms in this Section will control and prevail.
          <br />
          <br />
          Except as otherwise set forth in Section 18(e) below, you may seek any
          remedies available to you under federal, state, or local laws in an
          arbitration action. As part of the arbitration, both you and Cove will
          have the opportunity for discovery of non-privileged information that
          is relevant to the Claim. The arbitrator will provide a written
          statement of the arbitrator's decision regarding the Claim, the award
          given, and the arbitrator's findings and conclusions on which the
          arbitrator's decision is based. The determination of whether a Claim
          is subject to arbitration shall be governed by the Federal Arbitration
          Act and determined by a court rather than an arbitrator. Except as
          otherwise provided in these Terms, (i) you and Cove may litigate in
          court to compel arbitration, stay proceedings pending arbitration, or
          confirm, modify, vacate, or enter judgment on the award entered by the
          arbitrator; and (ii) the arbitrator's decision shall be final, binding
          on all parties and enforceable in any court that has jurisdiction,
          provided that any award may be challenged if the arbitrator fails to
          follow applicable law.
          <br />
          <br />
          IN THE CASE OF ARBITRATION AND WHERE PERMITTED BY LAW, YOU ARE
          AGREEING TO GIVE UP YOUR RIGHT TO GO TO COURT TO ASSERT OR DEFEND YOUR
          RIGHTS. YOUR RIGHTS WILL BE DETERMINED BY A NEUTRAL ARBITRATOR AND NOT
          A JUDGE OR JURY. YOU ARE ENTITLED TO A FAIR HEARING, BUT THE
          ARBITRATION PROCEDURES ARE SIMPLER AND MORE LIMITED THAN RULES
          APPLICABLE IN COURT. ARBITRATOR DECISIONS ARE ENFORCEABLE AS ANY COURT
          ORDER AND ARE SUBJECT TO VERY LIMITED REVIEW BY A COURT.
        </li>
        <li>
          <span className="italic">Location</span>. The arbitration will take
          place in the County and City of New York, New York unless the parties
          agree to video, phone, or internet connection appearances.
        </li>
        <li>
          <span className="italic">Limitations</span>. You and Cove agree that
          any arbitration shall be limited to the Claim between Cove and you
          individually. YOU AND COVE AGREE THAT (i) THERE IS NO RIGHT OR
          AUTHORITY FOR ANY DISPUTE TO BE ARBITRATED ON A CLASS-ACTION BASIS OR
          TO UTILIZE CLASS ACTION PROCEDURES; (ii) THERE IS NO RIGHT OR
          AUTHORITY FOR ANY DISPUTE TO BE BROUGHT IN A PURPORTED REPRESENTATIVE
          CAPACITY OR AS A PRIVATE ATTORNEY GENERAL; AND (iii) NO ARBITRATION
          SHALL BE JOINED WITH ANY OTHER ARBITRATION.
        </li>
        <li>
          <span className="italic">Exceptions to Arbitration</span>. You and
          Cove agree that the following Claims are not subject to the above
          provisions concerning binding arbitration: (i) any Excluded Dispute;
          (ii) any Claim related to, or arising from, allegations of theft,
          piracy, invasion of privacy, or unauthorized use; and (iii) any claim
          for equitable relief. In addition to the foregoing, either party may
          assert an individual action in small claims court for Claims that are
          within the scope of such court's jurisdiction in lieu of arbitration.
        </li>
        <li>
          <span className="italic">Arbitration Fees</span>. If you initiate
          arbitration for a Claim, you will need to pay the JAMS arbitration
          initiation fee. If we initiate arbitration for a Claim, we will pay
          the costs charged by JAMS for initiating the arbitration. All other
          fees and costs of the arbitration will be charged pursuant to the JAMS
          Rules.
        </li>
        <li>
          <span className="italic">Severability</span>. You and Cove agree that
          if any portion of this Section is found illegal or unenforceable
          (except any portion of Section 18(e)), that portion shall be severed
          and the remainder of the Section shall be given full force and effect.
          If Section 18(e) is found to be illegal or unenforceable then neither
          you nor Cove will elect to arbitrate any Claim falling within that
          portion of Section 18(e) found to be illegal or unenforceable and such
          Claim shall be exclusively decided by a court of competent
          jurisdiction within the County and City of New York, New York, and you
          and Cove agree to submit to the personal jurisdiction of that court.
        </li>
      </ol>
    </div>
  );

  const other = (
    <div className="py-4" id="other">
      <div className="pb-2 text-lg font-bold">19. Other Important Terms</div>
      <ol className="my-0" type="a">
        <li>
          <span className="italic">Assignment</span>. The rights granted to you
          under these Terms may not be assigned without Cove's prior written
          consent, and any attempted unauthorized assignment by you shall be
          null and void.
        </li>
        <li>
          <span className="italic">Severability</span>. Except as otherwise
          provided in Section 18(g), if any part of these Terms is determined to
          be invalid or unenforceable, then that portion shall be severed, and
          the remainder of the Terms shall be given full force and effect.
        </li>
        <li>
          <span className="italic">Attorneys' Fees</span>. In the event any
          litigation or arbitration is brought by either party in connection
          with these Terms, except as otherwise provided in Section 18(f), the
          prevailing party shall be entitled to recover from the other party all
          the reasonable costs, attorneys' fees and other expenses incurred by
          such prevailing party in any legal action relating to these Terms.
        </li>
        <li>
          <span className="italic">No Waiver</span>. Our failure to enforce any
          provision of these Terms shall in no way be construed to be a waiver
          of such provision, nor in any way affect our right to enforce the same
          provision at a later time. An express waiver by Cove of any provision,
          condition, or requirement of these Terms shall not be understood as a
          waiver of your obligation to comply with the same provision,
          condition, or requirement at a later time.
        </li>
        <li>
          <span className="italic">Equitable Remedies</span>. You acknowledge
          and agree that Cove would be irreparably damaged if the terms of these
          Terms were not specifically enforced, and therefore you agree that we
          shall be entitled, without bond, other security, or proof of damages,
          to appropriate equitable remedies with respect to any breach of these
          Terms, in addition to such other remedies as we may otherwise have
          available to us under applicable laws.
        </li>
        <li>
          <span className="italic">Entire Agreement</span>. These Terms,
          including the documents referenced in these Terms, constitutes the
          entire agreement between you and Cove with respect to the Service and
          supersedes any and all prior agreements between you and Cove relating
          to the Service.
        </li>
        <li>
          <span className="italic">Transfer</span>. We may transfer our rights
          and obligations under these Terms to another organization, but this
          will not affect your rights or our obligations under these Terms.
        </li>
      </ol>
    </div>
  );

  const changes = (
    <div className="py-4" id="changes">
      <div className="pb-2 text-lg font-bold">20. Changes to These Terms</div>
      <ol className="my-0" type="a">
        <li>
          We reserve the right, at our sole discretion, to amend these Terms at
          any time. As applicable, we will notify you of material changes to
          these Terms when you next access the Service (we may also email you
          about any material changes to these Terms). We reserve the right at
          any time and from time-to-time to modify or discontinue, temporarily
          or permanently, the Service (or any part of it) with or without
          notice.
        </li>
      </ol>
    </div>
  );

  const contact = (
    <div className="py-4" id="contact">
      <div className="pb-2 text-lg font-bold">21. Contact Information</div>
      <ol className="my-0" type="a">
        <li>
          If you have any questions or comments relating to the Service or these
          Terms, please contact us at{' '}
          <a href="mailto:support@getcove.com" className="underline">
            support@getcove.com
          </a>
          .
        </li>
      </ol>
    </div>
  );

  return (
    <div className="flex flex-col p-10 sm:py-16 sm:px-28 justify-self-center text-start">
      <Helmet>
        <title>Terms of Use</title>
      </Helmet>
      <div className="mt-16 mb-12 text-2xl font-bold text-center">
        Cove Software, Inc. Terms of Use
      </div>
      {intro}
      {tableOfContents}
      {access}
      {permittedUse}
      {privacy}
      {accounts}
      {moderation}
      {location}
      {dmca}
      {idea}
      {ip}
      {thirdParty}
      {california}
      {indemnity}
      {warranty}
      {liability}
      {termination}
      {communication}
      {law}
      {dispute}
      {other}
      {changes}
      {contact}
    </div>
  );
}
