import {
  GQLIntegration,
  GQLScalarType,
  GQLSignal,
  GQLSignalOutputType,
  GQLSignalType,
  GQLValueComparator,
} from '../graphql/generated';
import { assertUnreachable } from '../utils/misc';

/**
 * Legacy-ish type for the core set of keys that signal keys that much of the
 * code currently assumes will be present on fetched signals.
 * @deprecated
 */
export type CoreSignal = Pick<
  GQLSignal,
  | 'id'
  | 'type'
  | 'name'
  | 'description'
  | 'disabledInfo'
  | 'shouldPromptForMatchingValues'
  | 'outputType'
  | 'eligibleSubcategories'
  | 'eligibleInputs'
  | 'subcategory'
  | 'integration'
  | 'pricingStructure'
  | 'docsUrl'
  | 'recommendedThresholds'
  | 'supportedLanguages'
  | 'args'
>;

export function receivesRegexInput(type: GQLSignalType) {
  return (
    type === GQLSignalType.TextMatchingContainsRegex ||
    type === GQLSignalType.TextMatchingNotContainsRegex
  );
}

/**
 * This function returns the integration type for a given signal type
 * @param type Signal type to find the integration for
 * @returns a GQLIntegration enum value, or null in the case of signals that are
 * not integrations
 */
export function integrationForSignalType(type: GQLSignalType) {
  switch (type) {
    case 'AMAZON_REKOGNITION_ALCOHOL':
    case 'AMAZON_REKOGNITION_DRUGS':
    case 'AMAZON_REKOGNITION_GAMBLING':
    case 'AMAZON_REKOGNITION_HATE_SYMBOLS':
    case 'AMAZON_REKOGNITION_NUDITY':
    case 'AMAZON_REKOGNITION_RUDE_GESTURES':
    case 'AMAZON_REKOGNITION_SUGGESTIVE':
    case 'AMAZON_REKOGNITION_TOBACCO':
    case 'AMAZON_REKOGNITION_VIOLENCE':
    case 'AMAZON_REKOGNITION_VISUALLY_DISTURBING':
      return GQLIntegration.AmazonRekognition;
    case 'CHECKSTEP_AGGRESSIVE_TEXT':
    case 'CHECKSTEP_BULLYING_TEXT':
    case 'CHECKSTEP_HATE_TEXT':
    case 'CHECKSTEP_SPAM_TEXT':
      return GQLIntegration.Checkstep;
    case 'CLARIFAI_DRUG_IMAGE':
    case 'CLARIFAI_EXPLICIT_IMAGE':
    case 'CLARIFAI_GORE_IMAGE':
    case 'CLARIFAI_IDENTITY_HATE_TEXT':
    case 'CLARIFAI_INSULT_TEXT':
    case 'CLARIFAI_OBSCENE_TEXT':
    case 'CLARIFAI_SEVERE_TOXIC_TEXT':
    case 'CLARIFAI_SUGGESTIVE_IMAGE':
    case 'CLARIFAI_THREAT_TEXT':
    case 'CLARIFAI_TOXIC_TEXT':
      return GQLIntegration.Clarifai;
    case 'HIVE_BULLYING_TEXT_MODEL':
    case 'HIVE_FACE_DETECTION_MODEL':
    case 'HIVE_DRUGS_VISUAL_MODEL':
    case 'HIVE_HATE_TEXT_MODEL':
    case 'HIVE_HATE_VISUAL_MODEL':
    case 'HIVE_SEXUAL_TEXT_MODEL':
    case 'HIVE_SEXUAL_VISUAL_MODEL':
    case 'HIVE_SPAM_TEXT_MODEL':
    case 'HIVE_VIOLENCE_TEXT_MODEL':
    case 'HIVE_VIOLENCE_VISUAL_MODEL':
      return GQLIntegration.Hive;
    case 'OPEN_AI_GRAPHIC_VIOLENCE_TEXT_MODEL':
    case 'OPEN_AI_HATE_TEXT_MODEL':
    case 'OPEN_AI_HATE_THREATENING_TEXT_MODEL':
    case 'OPEN_AI_SELF_HARM_TEXT_MODEL':
    case 'OPEN_AI_SEXUAL_MINORS_TEXT_MODEL':
    case 'OPEN_AI_SEXUAL_TEXT_MODEL':
    case 'OPEN_AI_VIOLENCE_TEXT_MODEL':
    case 'OPEN_AI_WHISPER_TRANSCRIPTION':
      return GQLIntegration.OpenAi;
    case 'PERSPECTIVE_IDENTITY_ATTACK':
    case 'PERSPECTIVE_INSULT':
    case 'PERSPECTIVE_PROFANITY':
    case 'PERSPECTIVE_SEVERE_TOXICITY':
    case 'PERSPECTIVE_SEXUALLY_EXPLICIT':
    case 'PERSPECTIVE_THREAT':
    case 'PERSPECTIVE_TOXICITY':
      return GQLIntegration.Perspective;
    case 'SPECTRUM_BULLYING_TEXT_MODEL':
    case 'SPECTRUM_CSAM_GROOMING_TEXT_MODEL':
    case 'SPECTRUM_CSAM_TEXT_MODEL':
    case 'SPECTRUM_HATE_TEXT_MODEL':
    case 'SPECTRUM_INSULT_TEXT_MODEL':
    case 'SPECTRUM_PII_TEXT_MODEL':
    case 'SPECTRUM_PROFANITY_TEXT_MODEL':
    case 'SPECTRUM_RADICALIZATION_TEXT_MODEL':
    case 'SPECTRUM_SELF_HARM_TEXT_MODEL':
    case 'SPECTRUM_SEVERE_TOXICITY_TEXT_MODEL':
    case 'SPECTRUM_SEXUAL_TEXT_MODEL':
    case 'SPECTRUM_SOLICITATION_OF_DRUGS_TEXT_MODEL':
    case 'SPECTRUM_SOLICITATION_TEXT_MODEL':
    case 'SPECTRUM_SPAM_DETOUR_EMAIL_TEXT_MODEL':
    case 'SPECTRUM_SPAM_DETOUR_PHONE_TEXT_MODEL':
    case 'SPECTRUM_SPAM_DETOUR_URL_TEXT_MODEL':
    case 'SPECTRUM_SPAM_DETOUR_USERNAME_TEXT_MODEL':
    case 'SPECTRUM_SPAM_TEXT_MODEL':
    case 'SPECTRUM_THREAT_TEXT_MODEL':
    case 'SPECTRUM_UNDERAGE_13_TEXT_MODEL':
    case 'SPECTRUM_UNDERAGE_18_TEXT_MODEL':
    case 'SPECTRUM_DRUGS_NAME_MODEL':
    case 'SPECTRUM_HATE_SPEECH_NAME_MODEL':
    case 'SPECTRUM_PROFANITY_NAME_MODEL':
    case 'SPECTRUM_SEXUAL_NAME_MODEL':
      return GQLIntegration.SpectrumLabs;
    case 'THORN_GROOMING_TEXT_MODEL':
      return GQLIntegration.Thorn;
    case 'AGGREGATION':
    case 'CUSTOM':
    case 'COVE_MODEL':
    case 'GEO_CONTAINED_WITHIN':
    case 'IMAGE_EXACT_MATCH':
    case 'IMAGE_SIMILARITY_SCORE':
    case 'TEXT_MATCHING_CONTAINS_REGEX':
    case 'TEXT_MATCHING_CONTAINS_TEXT':
    case 'TEXT_MATCHING_CONTAINS_VARIANT':
    case 'TEXT_MATCHING_NOT_CONTAINS_REGEX':
    case 'TEXT_MATCHING_NOT_CONTAINS_TEXT':
    case 'TEXT_SIMILARITY_SCORE':
    case 'PERSPECTIVE_LANGUAGE_DETECTION':
    case 'USER_SCORE':
    case 'USER_STRIKE_VALUE':
    case 'BENIGN_MODEL':
    case 'CELEBRITY_RECOGNITION':
    case 'DEMO_HATE_SPEECH':
    case 'DEMO_SEXUALLY_EXPLICIT':
    case 'GPT4O_MINI':
      return null;
    default:
      assertUnreachable(type);
  }
}

export function outputTypeToComparators(outputType: GQLSignalOutputType) {
  const orderedComparators = [
    GQLValueComparator.Equals,
    GQLValueComparator.NotEqualTo,
    GQLValueComparator.GreaterThan,
    GQLValueComparator.GreaterThanOrEquals,
    GQLValueComparator.LessThan,
    GQLValueComparator.LessThanOrEquals,
    GQLValueComparator.IsUnavailable,
    GQLValueComparator.IsNotProvided,
  ];

  switch (outputType.scalarType) {
    case GQLScalarType.Number:
    case GQLScalarType.Datetime:
      return orderedComparators;
    case GQLScalarType.Id:
    case GQLScalarType.UserId:
    case GQLScalarType.Audio:
    case GQLScalarType.Image:
    case GQLScalarType.Video:
    case GQLScalarType.Geohash:
    case GQLScalarType.Boolean:
    case GQLScalarType.RelatedItem:
    case GQLScalarType.PolicyId:
      return [
        GQLValueComparator.Equals,
        GQLValueComparator.NotEqualTo,
        GQLValueComparator.IsUnavailable,
        GQLValueComparator.IsNotProvided,
      ];
    case GQLScalarType.Url:
    case GQLScalarType.String:
      return outputType.__typename === 'EnumSignalOutputType' &&
        outputType.ordered
        ? orderedComparators
        : [
            GQLValueComparator.Equals,
            GQLValueComparator.NotEqualTo,
            GQLValueComparator.IsUnavailable,
            GQLValueComparator.IsNotProvided,
          ];
    default:
      assertUnreachable(outputType.scalarType);
  }
}
