import MarkdownTextInput from '@/webpages/dashboard/policies/MarkdownTextInput';
import { LoadingOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { gql } from '@apollo/client';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import ComponentLoading from '../../../../../components/common/ComponentLoading';
import CoveModal from '../../../components/CoveModal';

import {
  useGQLGenerateDefaultPolicyDefinitionLazyQuery,
  useGQLOrgDataForModelTrainingQuery,
  useGQLStartCoveModelSignalCreationSessionMutation,
  useGQLUpdatePolicyDefinitionMutation,
} from '../../../../../graphql/generated';
import TrainingPipelineFooter from '../TrainingPipelineFooter';
import { useGoToScreen, type TrainingPipelineEnabledScreen } from './types';

gql`
  query GenerateDefaultPolicyDefinition($input: GeneratePolicyInput!) {
    generatePolicyText(input: $input) {
      ... on GeneratedPolicyTextCompletionSuccess {
        policyText
      }
      ... on GeneratedPolicyTextCompletionError {
        title
      }
    }
  }

  mutation UpdatePolicyDefinition($input: UpdatePolicyInput!) {
    updatePolicy(input: $input) {
      ... on Policy {
        id
        name
        policyText
      }
      ... on NotFoundError {
        title
      }
    }
  }

  mutation StartCoveModelSignalCreationSession(
    $input: StartCoveModelSignalCreationSessionInput!
  ) {
    startCoveModelSignalCreationSession(input: $input) {
      ... on StartCoveModelSignalCreationSessionSuccessResponse {
        sessionId
      }
    }
  }
`;

export default function TrainingPipelineInputPolicyScreen(props: {
  nextScreen: TrainingPipelineEnabledScreen;
}) {
  const { nextScreen } = props;
  const goToScreen = useGoToScreen();
  const [searchParams] = useSearchParams();
  const policyId = searchParams.get('policyId') ?? undefined;

  const { data, loading, error } = useGQLOrgDataForModelTrainingQuery();

  const [policyDefinition, setPolicyDefinition] = useState<string | undefined>(
    undefined,
  );
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );

  const [generatePolicyDefinition, { loading: generatedPolicyLoading }] =
    useGQLGenerateDefaultPolicyDefinitionLazyQuery({
      onCompleted(data) {
        switch (data.generatePolicyText.__typename) {
          case 'GeneratedPolicyTextCompletionSuccess':
            setPolicyDefinition(data.generatePolicyText.policyText);
            break;
          case 'GeneratedPolicyTextCompletionError':
            setErrorMessage(`Error generating policy text. Please try again.`);
            break;
        }
      },
      onError(e) {
        setErrorMessage('Error generating policy text. Please try again.');
      },
    });

  const [updatePolicyDefinition, { loading: updateLoading }] =
    useGQLUpdatePolicyDefinitionMutation({
      onError(e) {
        setErrorMessage('Error generating policy text. Please try again.');
      },
    });

  const [createModelCreationSession, { loading: createSessionLoading }] =
    useGQLStartCoveModelSignalCreationSessionMutation();

  useEffect(() => {
    if (data?.myOrg?.policies) {
      const policy = data.myOrg.policies.find((it) => it.id === policyId);
      if (policy?.policyText) {
        setPolicyDefinition(policy.policyText);
      }
    }
  }, [policyId, data?.myOrg?.policies]);

  if (loading) {
    return <ComponentLoading />;
  }

  if (error) {
    throw error;
  }

  const policy = data?.myOrg!.policies.find((it) => it.id === policyId);
  if (policy == null) {
    throw new Error(`Policy with ID ${policyId} not found`);
  }

  const { name: policyName } = policy;

  const errorModal = (
    <CoveModal
      title="Error"
      visible={errorMessage != null}
      onClose={() => setErrorMessage(undefined)}
      footer={[
        {
          title: 'OK',
          onClick: () => setErrorMessage(undefined),
        },
      ]}
    >
      {errorMessage}
    </CoveModal>
  );

  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <div className="flex flex-col items-start h-full max-w-4xl text-start">
        <div className="py-6 text-3xl font-bold">
          What is your <span className="italic">internal</span> definition of{' '}
          {policyName}?
        </div>
        <div className="pb-4 text-base text-slate-500">
          We're going to train your AI model the same way you might train your
          human moderators, which means your AI model needs a detailed, nuanced
          understanding of your {policyName} policy.
        </div>
        <div className="pb-12 text-base text-slate-500">
          If you already have an internal definition of {policyName} that your
          moderators use, you can paste it below. Otherwise, you can use our
          default definition, which we find works well.
        </div>
        <MarkdownTextInput
          text={
            generatedPolicyLoading
              ? 'Generating policy template...'
              : policyDefinition ?? ''
          }
          setText={setPolicyDefinition}
          title={policyName}
          disabled={generatedPolicyLoading || updateLoading}
        />
        <div
          className={`flex flex-row items-center self-end justify-center my-4 ${
            generatedPolicyLoading
              ? 'text-slate-300'
              : 'text-cove-blue cursor-pointer hover:text-cove-blue-hover'
          }`}
          onClick={() => {
            if (!generatedPolicyLoading) {
              setPolicyDefinition('Generating policy template...');
              generatePolicyDefinition({
                variables: { input: { policyName } },
              });
            }
          }}
        >
          {generatedPolicyLoading ? (
            <LoadingOutlined spin className="mr-2" />
          ) : (
            <ThunderboltOutlined className="mr-2" />
          )}
          {generatedPolicyLoading
            ? 'Generating Policy Text...'
            : 'Generate Policy Text'}
        </div>
      </div>
      <TrainingPipelineFooter
        primaryButton={{
          title: `Create ${policyName} AI model`,
          onClick: async () =>
            updatePolicyDefinition({
              variables: {
                input: {
                  ..._.omit(policy, '__typename'),
                  policyText: policyDefinition!,
                },
              },
              onCompleted: async () =>
                createModelCreationSession({
                  variables: {
                    input: {
                      textModelV1: { policyIdentifier: { id: policy.id } },
                    },
                  },
                  onCompleted(data) {
                    goToScreen(
                      nextScreen,
                      policyId!,
                      data.startCoveModelSignalCreationSession.sessionId,
                    );
                  },
                  onError(_e) {
                    setErrorMessage('Failed to create session');
                  },
                }),
            }),
          disabled:
            !policyDefinition ||
            policyDefinition.length === 0 ||
            generatedPolicyLoading,
          loading: updateLoading || createSessionLoading,
        }}
      />
      {errorModal}
    </div>
  );
}
