import {
  AreaChartOutlined,
  FlagOutlined,
  HistoryOutlined,
} from '@ant-design/icons';

import { LookbackLength } from '../../../../utils/time';
import {
  ChartType,
  TimeWindow,
} from '../../rules/dashboard/visualization/RulesDashboardInsights';
import ManualReviewDecisionsTable from '../ManualReviewDecisionsTable';
import ManualReviewDashboardInsightsCard from './ManualReviewDashboardInsightsCard';
import ManualReviewDashboardInsightsChart from './ManualReviewDashboardInsightsChart';
import TimeToActionByQueueChart from './TimeToActionChart';

export default function ManualReviewDefaultCharts(props: {
  lookback: LookbackLength;
  timeWindow: TimeWindow;
  loading: boolean;
  totalIngestedReportsInLookback: number | undefined;
  totalIngestedReportsInPreviousLookback: number | undefined;
  averageTimeToReviewInLookback: number | undefined;
  averageTimeToReviewInPreviousLookback: number | undefined;
  currentlyOpenJobs: number;
}) {
  const {
    lookback,
    timeWindow,
    loading,
    totalIngestedReportsInLookback,
    totalIngestedReportsInPreviousLookback,
    averageTimeToReviewInLookback,
    averageTimeToReviewInPreviousLookback,
    currentlyOpenJobs,
  } = props;
  const getPercentChange = (oldValue: number, newValue: number) => {
    if (oldValue === 0) {
      return newValue === 0 ? 0 : Infinity;
    }

    return ((newValue - oldValue) / Math.abs(oldValue)) * 100;
  };

  return (
    <div className="flex flex-col pb-4 gap-4">
      <div className="flex gap-4">
        <ManualReviewDashboardInsightsCard
          title="Jobs Created"
          value={totalIngestedReportsInLookback}
          change={
            totalIngestedReportsInPreviousLookback != null &&
            totalIngestedReportsInLookback != null
              ? getPercentChange(
                  totalIngestedReportsInPreviousLookback,
                  totalIngestedReportsInLookback,
                )
              : undefined
          }
          lookback={lookback}
          timeWindow={timeWindow}
          icon={
            <AreaChartOutlined className="flex p-2 text-lg rounded-lg bg-cove-lightpurple text-cove-purple" />
          }
          loading={loading}
        />
        <ManualReviewDashboardInsightsCard
          title="Currently Open Jobs"
          value={currentlyOpenJobs}
          lookback={lookback}
          timeWindow={timeWindow}
          icon={
            <FlagOutlined className="flex p-2 text-lg rounded-lg bg-cove-lightred text-cove-red" />
          }
          loading={loading}
          link={'/dashboard/manual_review/queues'}
          linkTitle={'Go to Queues'}
        />
        <ManualReviewDashboardInsightsCard
          title="Average Hours Until Review"
          value={
            averageTimeToReviewInLookback
              ? Number((averageTimeToReviewInLookback / 60 / 60).toFixed(2))
              : 0
          }
          change={
            averageTimeToReviewInPreviousLookback != null &&
            averageTimeToReviewInLookback != null
              ? getPercentChange(
                  averageTimeToReviewInPreviousLookback,
                  averageTimeToReviewInLookback,
                )
              : undefined
          }
          lookback={lookback}
          timeWindow={timeWindow}
          icon={
            <HistoryOutlined className="flex p-2 text-lg rounded-lg bg-cove-lightorange text-cove-orange" />
          }
          loading={loading}
        />
      </div>
      <ManualReviewDashboardInsightsChart
        lookback={lookback}
        timeWindow={timeWindow}
        initialChartType={ChartType.LINE}
        metric="DECISIONS"
        initialGroupBy={['TYPE']}
        title="All Decisions"
      />
      <div className="py-4 grid grid-cols-1 gap-4 xl:grid-cols-2">
        <ManualReviewDashboardInsightsChart
          lookback={lookback}
          timeWindow={timeWindow}
          initialChartType={ChartType.LINE}
          metric="DECISIONS"
          initialGroupBy={['POLICY_ID']}
          title="Decisions by Policy"
          hideGroupBy
          hideFilterBy
          hideTotal
          narrowMode
          infoText="This chart shows all moderator decisions per policy."
        />
        <ManualReviewDashboardInsightsChart
          lookback={lookback}
          timeWindow={timeWindow}
          initialChartType={ChartType.LINE}
          metric="JOBS"
          initialGroupBy={['QUEUE_ID']}
          title="Jobs by Queue"
          hideGroupBy
          hideFilterBy
          hideTotal
          narrowMode
          infoText="This chart shows the number of manual review jobs that ended up in each queue."
        />
        <ManualReviewDashboardInsightsChart
          lookback={lookback}
          timeWindow={timeWindow}
          initialChartType={ChartType.LINE}
          metric="DECISIONS"
          initialGroupBy={['REVIEWER_ID']}
          title="Decisions by Moderator"
          hideGroupBy
          hideTotal
          narrowMode
          infoText="This chart shows all moderator decisions per moderator."
        />
        <ManualReviewDashboardInsightsChart
          lookback={lookback}
          timeWindow={timeWindow}
          initialChartType={ChartType.LINE}
          metric="JOBS"
          initialGroupBy={['SOURCE']}
          title="Jobs by Source"
          hideGroupBy
          hideFilterBy
          hideTotal
          narrowMode
          infoText={`This chart shows the number of manual review jobs that
           Cove received, split by the source of those jobs. The possible sources
           are (1) "User Report", which indicates that a user on your platform reported
           the content in the job, (2) "Rule", which indicates that one of your Cove
           Rules automatically created the job, and (3) "Moderator", which indicates that
           a moderator added this job to a queue while reviewing a different job.`}
        />
        <ManualReviewDashboardInsightsChart
          lookback={lookback}
          timeWindow={timeWindow}
          initialChartType={ChartType.LINE}
          metric="REVIEWED_JOBS"
          initialGroupBy={['REVIEWER_ID']}
          title="Reviewed Jobs By Moderator"
          hideGroupBy
          hideTotal
          narrowMode
          infoText="This chart shows all reviewed jobs per moderator."
        />
        <ManualReviewDashboardInsightsChart
          lookback={lookback}
          timeWindow={timeWindow}
          initialChartType={ChartType.LINE}
          metric="REVIEWED_JOBS"
          initialGroupBy={['QUEUE_ID']}
          title="Reviewed Jobs By Queue"
          hideGroupBy
          hideTotal
          narrowMode
          infoText="This chart shows all reviewed jobs per queue."
        />
        <ManualReviewDashboardInsightsChart
          lookback={lookback}
          timeWindow={timeWindow}
          initialChartType={ChartType.LINE}
          metric="SKIPPED_JOBS"
          initialGroupBy={['REVIEWER_ID']}
          title="Skipped Jobs By Queue"
          hideGroupBy
          hideTotal
          narrowMode
          infoText="This chart shows all jobs skipped by reviewers per queue."
        />
        <TimeToActionByQueueChart
          lookback={lookback}
          timeWindow={timeWindow}
          title="Average Time To Review By Queue"
          hideGroupBy
          hideTotal
          narrowMode
          infoText="This chart shows all reviewed jobs per queue."
        />
      </div>
      <ManualReviewDecisionsTable timeWindow={timeWindow} />
    </div>
  );
}
