import { cn } from '../../../lib/utils';
import { HorizontalDashes } from '../../animations/HorizontalDashes';
import { VerticalDashes } from '../../animations/VerticalDashes';
import { Typography } from '../../ui/Typography';

interface FounderProps {
  image: string;
  title: string;
  previous: string;
  degree: string;
  logos: [string, number][];
}

function Founder({ image, title, previous, degree, logos }: FounderProps) {
  return (
    <div className="flex flex-col items-center justify-start gap-4 lg:flex-row">
      <img
        alt={`Founder and ${title}`}
        src={`/graphics/${image}.png`}
        className="w-44 h-44"
      />
      <div>
        <Typography
          className="mb-3.5 text-center lg:text-left"
          variant="headline"
        >
          {title}
        </Typography>
        <Typography
          variant="body"
          className="mb-4 text-center lg:text-left"
          color="subtle"
        >
          Previously: {previous}
        </Typography>
        <Typography
          variant="body"
          className="text-center lg:text-left"
          color="subtle"
        >
          {degree}
        </Typography>
        <div className="flex flex-row items-center justify-center mt-4 lg:justify-start gap-5">
          {logos.map(([logo, height]) => (
            <img
              alt={`Logo: ${logo}`}
              className="flex-shrink-0"
              key={logo}
              style={{ height }}
              src={`/brands/${logo}.png`}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export function Hero() {
  return (
    <section
      className={cn(
        'overflow-hidden relative',
        'mx-auto grid max-w-[90rem] grid-cols-[repeat(16,minmax(0,1fr))] [&>:not(.dash)]:col-[2/-2]',
      )}
    >
      <VerticalDashes className="absolute top-0 -mr-px col-start-2 mask-to-b mask-from-opacity-0 mask-via-opacity-100 dash" />
      <VerticalDashes className="col-start-[16] absolute top-0 mr-px dash mask-to-b mask-from-opacity-0 mask-via-opacity-100" />
      <HorizontalDashes className="row-start-2 col-span-full dash mask-[linear-gradient(90deg,transparent_0%,#000_10%,#000_90%,transparent_100%)]" />
      <HorizontalDashes className="row-start-4 col-span-full dash mask-[linear-gradient(90deg,transparent_0%,#000_10%,#000_90%,transparent_100%)]" />
      <div className="relative z-10 flex flex-col items-center px-8 pt-48 pb-10 gap-6">
        <Typography variant="display" size="lg" align="center">
          Committed to building
          <br />
          <span className="bg-gradient-to-r from-primary to-ternary to-pink text-clear bg-clip-text">
            a safer internet for everyone
          </span>
        </Typography>
        <Typography
          align="center"
          size="lg"
          color="subtle"
          className="px-4 mb-16 lg:px-32"
        >
          At Cove, our mission is to build a safer, healthier Internet. We know
          that's it's achievable, and we believe we have the tools to get there.
          If we succeed, we'll have an Internet that fulfills its promise of
          enabling the best in humanity, without giving voice to the worst of
          it.
        </Typography>
      </div>
      <div className="flex flex-col @container-[size] h-[64rem] lg:h-96 items-center gap-6 relative">
        <div className="z-20 w-full h-full px-8 pt-16 lg:pt-0 grid grid-cols-1 lg:grid-cols-2 gap-16">
          <Founder
            title="Michael Dworsky, CEO"
            previous="Trust & Safety at Meta"
            degree="B.S. in Mathematical & Computational Science, Stanford"
            image="michael"
            logos={[['meta', 47]]}
          />
          <Founder
            title="Mason Silber, CTO"
            previous="Trust & Safety at Meta; Engineering at Twitter"
            degree="B.A. in Computer Science & Physics, Columbia"
            image="mason"
            logos={[
              ['meta', 47],
              ['twitter', 23],
            ]}
          />
        </div>

        <div
          className={cn(
            'bg-graph-paper w-[calc(300vw)] h-[calc(250vh)] absolute -top-[calc(6*var(--cell-height))] -left-[calc(3*var(--cell-width))]',
            '[--cell-width:calc(100cqw/6)] sm:[--cell-width:calc(100cqw/12)]',
            '[--cell-height:calc(100cqh/6)] sm:[--cell-height:calc(100cqh/4)]',
            'mask-from-20% sm:mask-from-60% mask-ellipse reach-closest-corner',
          )}
          style={{ backgroundSize: 'var(--cell-width) var(--cell-height)' }}
        />
      </div>
      <div className="px-16 py-20">
        <Typography variant="body" size="lg" color="subtle" className="mb-8">
          At Meta, our founding team worked on safeguarding elections around the
          world, combatting vaccine misinformation, stemming hate, preventing
          harassment, and thwarting violence and incitement.
        </Typography>
        <Typography variant="body" size="lg" color="subtle">
          Cove is the solution we wish we had at Meta. We've built a suite of
          products to empower companies large and small to enforce their
          platforms' policies and keep their users safe, all without writing
          code.
        </Typography>
      </div>
    </section>
  );
}
