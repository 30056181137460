import ThresholdSelection from '../../../../../../../src/images/ThresholdSelection.png';
import DemoInputDailyContentTotal from './DemoInputDailyContentTotal';

export default function DemoSetThresholdsExplanationComponent(props: {
  setTotalContent: (total: number) => void;
  totalContent?: number;
}) {
  const { totalContent, setTotalContent } = props;
  return (
    <div className="flex flex-row items-center p-6 mt-8 bg-gray-100 border border-gray-200 border-solid space-x-4 rounded-md">
      <div className="flex flex-col basis-3/4 gap-2">
        <div>We've provided thresholds for the following two actions:</div>
        <div className="flex flex-row items-center space-x-2">
          <div className="w-2 h-2 bg-red-500 rounded-full" />
          <div className="text-slate-500">Automatically Delete</div>
        </div>
        <div className="flex flex-row items-center space-x-2">
          <div className="w-2 h-2 bg-yellow-500 rounded-full" />
          <div className="text-slate-500">Enqueue for Manual Review</div>
        </div>
        <div>
          You can drag these thresholds up or down to find their optimal spots.
          You may find some mistakes -- AI models are never perfect -- so you
          can choose what types of mistakes you're willing to tolerate.
        </div>
        <DemoInputDailyContentTotal
          totalContent={totalContent}
          setTotalContent={setTotalContent}
        />
      </div>
      <img
        alt="thresholds"
        className="pl-6 basis-1/4"
        src={ThresholdSelection}
        width={200}
      />
    </div>
  );
}
