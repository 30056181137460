import {
  ChevronRight,
  FlowChartAltFilled,
  FriendsFilled,
  LawBookFilled,
  SparklesFilled,
} from '@/icons';
import { m } from 'framer-motion';
import { type SVGProps } from 'react';

import { Button } from '../../ui/Button';
import { Typography } from '../../ui/Typography';

function MobileProductCard(props: {
  title: string;
  icon: React.JSXElementConstructor<SVGProps<SVGSVGElement>>;
  caption: string;
  cta: {
    title: string;
  } & ({ url: string } | { onClick: () => void });
  imageSrc: string;
  imageClassName?: string;
}) {
  const { title, icon: Icon, caption, cta, imageSrc, imageClassName } = props;
  return (
    <m.div
      initial={{ y: 10, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      viewport={{ amount: 'some', once: true }}
      transition={{ duration: 0.5, delay: 0.4 }}
      className="flex flex-col border border-solid border-[#D9D9D9] bg-[#F5F5F5] gap-4 items-start overflow-hidden p-6 rounded-3xl"
    >
      <div className="flex items-center justify-start text-2xl font-bold gap-2">
        <Icon width={22} height={22} />
        {title}
      </div>
      <div className="text-lg font-extralight">{caption}</div>
      {'url' in cta ? (
        <Button
          as="a"
          href={cta.url}
          target="_blank"
          className="px-0 py-2 text-start"
          variant="link"
        >
          {cta.title}
          <ChevronRight width={14} className="fill-primary mt-0.5" />
        </Button>
      ) : (
        <Button
          as="button"
          onClick={cta.onClick}
          className="px-0 py-2 text-start"
          variant="link"
        >
          {cta.title}
          <ChevronRight width={14} className="fill-primary mt-0.5" />
        </Button>
      )}
      <img className={imageClassName ?? ''} alt="Product" src={imageSrc} />
    </m.div>
  );
}

export default function MobileProducts() {
  return (
    <div className="flex flex-col px-8 pt-24 pb-8 gap-4">
      <Typography
        className="mb-2"
        size="lg"
        as="h2"
        variant="display"
        dangerouslySetInnerHTML={{ __html: 'Our products' }}
      />
      <MobileProductCard
        title="Custom AI Models"
        icon={SparklesFilled}
        caption="Create custom, LLM based models that enforce policies like you would."
        cta={{
          url: '/ai',
          title: 'See how Custom AI Models work',
        }}
        imageSrc="/graphics/ai-models-product.svg"
        imageClassName="-mx-8"
      />
      <MobileProductCard
        title="Automated Enforcement"
        icon={FlowChartAltFilled}
        caption="Build automated rules to enforce policies - without writing code."
        cta={{
          url: '/enforcement',
          title: 'Get started',
        }}
        imageSrc="/graphics/rule.svg"
        imageClassName="-mb-8 w-[110%]"
      />
      <MobileProductCard
        title="Moderator Console"
        icon={FriendsFilled}
        caption="Empower moderators to make fast, context-rich decisions."
        cta={{
          url: '/console',
          title: 'Explore the Moderator Console',
        }}
        imageSrc="/graphics/moderator-console-product.svg"
        imageClassName="-mx-8 -mb-8"
      />
      <MobileProductCard
        title="Compliance Toolkit"
        icon={LawBookFilled}
        caption="Comply automatically with emerging global regulations."
        cta={{
          url: '/compliance',
          title: 'Learn more',
        }}
        imageSrc="/graphics/compliance.svg"
        imageClassName="w-[110%] self-center"
      />
    </div>
  );
}
